import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ArticleAdmin } from 'api/AdminApi';
import Config from 'configuration';
const initialArticleAdminList = () => {
    if (sessionStorage.getItem(Config.storageKey.articleAdmin)) {
        return {
            ...JSON.parse(sessionStorage.getItem(Config.storageKey.articleAdmin)),
        };
    }
    return {
        articleAdminList: [],
    };
};

export const getListArticleAdmin = createAsyncThunk(
    'articleAdmin/listArticleAdmin',
    async (params) => {
        const res = await ArticleAdmin.getListArticle(params);
        return res;
    },
);

export const insertArticleAdmin = createAsyncThunk(
    'articleAdmin/insertArticleAdmin',
    async (params) => {
        const res = await ArticleAdmin.insertArticle(params);
        return res;
    },
);
export const updateArticleAdmin = createAsyncThunk(
    'articleAdmin/updateArticleAdmin',
    async (params) => {
        const res = await ArticleAdmin.updateArticle(params);
        return res;
    },
);
export const deleteArticleAdmin = createAsyncThunk(
    'articleAdmin/deleteArticleAdmin',
    async (params) => {
        const res = await ArticleAdmin.deleteArticle(params);
        return res;
    },
);

const articleAdmin = createSlice({
    name: 'articleAdmin',
    initialState: initialArticleAdminList(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListArticleAdmin.fulfilled, (state, action) => {
                state.articleAdminList = action.payload.responseData;
            })
            .addCase(getListArticleAdmin.rejected, (state, action) => {});
    },
});

const { reducer } = articleAdmin;
export default reducer;
