import AdminPage from 'pages/Admin/Component/HeaderSidebar';
import Table from 'pages/Admin/Component/Table';
import GinsengModal from './subViews/GinsengModal';
import GinsengTopBar from './subViews/GinsengTopBar';

function GinsengMainView(props) {
    const {
        intl,
        handleEditClick,
        handleDeleteClick,
        keyword,
        handleSearch,
        openDialog,
        handleCloseDialog,
        isEdit,
        handleAddClick,
        handleSubmit,
        initialValues,
        ginsengData,
        filesImage,
        handleRemoveImage,
        handleOnchangeImage,
        onChangeFileCertificate,
        pageSize,
        setPageSize,
        tableData,
        setCurrentPage,
        currentPage,
        handleImage,
        onClickTopBarStatus,
        onHandleToggleSignalModal,
        onHandleToggleDescriptionModal
    } = props;
    return (
        <AdminPage
            header={intl.messages.tableTitle}
            subHeader={`${tableData.count} ${intl.messages.unit}`}
            userName='Admin'
            handleAddClick={handleAddClick}
            onChangeSearch={(e) => handleSearch(e)}
            topBar={<GinsengTopBar intl={intl} onClickTopBarStatus={onClickTopBarStatus} />}
            showAddNewButton={false}
        >

            <Table
                columns={props.columns}
                data={tableData.data}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                keyword={keyword}
                pageSize={pageSize}
                currentPage={currentPage}
                handleChangePageSize={(e) => setPageSize(e)}
                totalPage={tableData.total}
                handleChangeCurrentPage={(e) => setCurrentPage(e)}
                ginMenu={true}
                onHandleToggleSignalModal={onHandleToggleSignalModal}
                onHandleToggleDescriptionModal={onHandleToggleDescriptionModal}
            />
            <GinsengModal
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                intl={intl}
                isEdit={isEdit}
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                data={ginsengData}
                filesImage={filesImage}
                handleRemoveImage={handleRemoveImage}
                handleOnchangeImage={handleOnchangeImage}
                onChangeFileCertificate={onChangeFileCertificate}
                handleImage={handleImage}
            />
           
        </AdminPage>
    );
}

export default GinsengMainView;
