import AdminPage from 'pages/Admin/Component/HeaderSidebar';
import Table from 'pages/Admin/Component/Table';
import LocationModal from './subViews/LocationModal';

function LocationMainView(props) {
    const {
        intl,
        rowCount,
        handleEditClick,
        handleDeleteClick,
        handleAddClick,
        handleCloseDialog,
        handleSubmit,
        addressData,
        initialValues,
        openDialog,
        keyword,
        setKeyword,
        isAdd,
        validationSchema,
        pageSize,
        setPageSize,
        tableData,
        setCurrentPage,
        currentPage,
    } = props;

    return (
        <AdminPage
            header={intl.messages.tableTitle}
            subHeader={`${tableData.count} ${intl.messages.unit}`}
            userName='Admin'
            handleAddClick={handleAddClick}
            onChangeSearch={(e) => setKeyword(e)}
        >
            <Table
                columns={props.columns}
                data={tableData.data}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                keyword={keyword}
                pageSize={pageSize}
                currentPage={currentPage}
                handleChangePageSize={(e) => setPageSize(e)}
                totalPage={tableData.total}
                handleChangeCurrentPage={(e) => setCurrentPage(e)}
            />
            <LocationModal
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                intl={intl}
                isAdd={isAdd}
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                data={addressData}
                validationSchema={validationSchema}
            />
        </AdminPage>
    );
}

export default LocationMainView;
