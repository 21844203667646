export default function propsProvider(props) {
  /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
  const {
    intl,
    onChangeValueForm,
    handleOnchangeImage,
    handleRemoveImage,
    handleImage,
    filesImage,
    ginsengForm,
    onSubmitForm,
    resultAddGinseng,
    onPressBack,
  } = props;
  return {
    intl,
    onChangeValueForm,
    handleOnchangeImage,
    handleRemoveImage,
    handleImage,
    filesImage,
    ginsengForm,
    onSubmitForm,
    resultAddGinseng,
    onPressBack,
  };
}
