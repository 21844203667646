import React from "react";
import Footer from "components/Footer";
export default function ScanProductComponent(props) {
  const { intl, productScan, onPressCorrectBtn, onPressInCorrectBtn } = props;
  return (
    <Footer headerTitle={intl.messages.YOUR_PRODUCT}>
      <div className="product-context">
        <hr className="product-line" />
        <span className="product-name-p">
          {productScan?.name}
          <br />
          <span className="product-unit">{productScan?.unit_value}{productScan?.unit?.unit}</span>
        </span>
        <hr className="product-line" />
      </div>
      <div className="product-btn-div">
        <button
          title={intl.messages.Wrong}
          className="product-btn wrong-btn"
          onClick={onPressInCorrectBtn}
        >
          {intl.messages.Wrong}
        </button>
        <button
          title={intl.messages.Correct}
          className="product-btn correct-btn"
          onClick={onPressCorrectBtn}
        >
          {intl.messages.Correct}
        </button>
      </div>
    </Footer>
  );
}
