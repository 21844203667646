import OTPInput from 'react-otp-input';
import SignInButton from '../../Component/SignInButton/index';
import SignInForm from '../../Component/SignInForm/index';

export default function VerifiedCodeMainView(props) {
    const { intl, code, setCode, numInputs, onSubmitClick, errorMessage } = props;
    return (
        <SignInForm formTitle={intl.messages.VerifiedTitle}>
            <div className='form-body'>
                <p className='place-center'>{intl.messages.Message}</p>
                <OTPInput
                    value={code}
                    numInputs={numInputs}
                    inputType='number'
                    onChange={setCode}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus='true'
                    inputStyle='verified-code-input'
                    containerStyle={{
                        alignSelf: 'stretch',
                        flexGrow: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItem: 'center',
                        padding: 0,
                    }}
                />
                {errorMessage && (
                    <p className='place-center error-message'>{intl.messages.InvalidCode}</p>
                )}

                <SignInButton
                    disabled={code !== undefined ? code.length < numInputs : code == null}
                    label={intl.messages.SubmitLabel}
                    onClick={onSubmitClick}
                />
            </div>
        </SignInForm>
    );
}
