import { useEffect, useRef, useState } from "react";
import { hideLoading, showLoading } from "components/Loading/LoadingSlice";
import Config from "configuration";
import moment from "moment";
import {
  ProductColumns,
  QrCodeColumns,
} from "pages/Admin/Component/Table/TableColumnsConfig";

import { useIntl } from "react-intl";
import Utils from "utils";
import useTableProperties from "../Component/Table/useTableProperties";
import propsProvider from "./QrCodePropsProvider";

import QrCodeMainView from "./template/QrCodeMainView";
import {
  addNewQRCode,
  deleteQRCode,
  getListQrCode,
  updatePrint,
} from "./QrCodeSlice";

import { useReactToPrint } from "react-to-print";
function QrCodeContainer(props) {
  const { dispatch, lstQrCode, params } = props;

 
  const [keyword, setKeyword] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [openDialog, setOpenDialog] = useState(false);
  const [filesImage, setFilesImage] = useState([]);

  const [qrCodeEdit, setQRCodeEdit] = useState({});

  const [openAddMoreQrCode, setOpenDialogQR] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);

  const [qrCodeBase64, setQrCodeBase64] = useState("");
  const [unit, setUnit] = useState([]);

  const [isAdd, setIsAdd] = useState(true);

  const [qrCodeNotEnough, setQrCodeNotEnough] = useState(0);

  const printRef = useRef();
  const updatePrintLstRef = useRef();
  const intl = useIntl();
  const columns = QrCodeColumns(intl);
  const tableData = useTableProperties(lstQrCode);

  // const tablePrintedData = useTableProperties({
  //   count: lstQrCode?.rows?.filter((el) => el.is_printed).length,
  //   rows: lstQrCode?.rows?.filter((el) => el.is_printed),
  //   totalPages: lstQrCode?.rows?.filter((el) => el.is_printed).length % 10
  // })
  // const tableNotYetPrintedData = useTableProperties({
  //   count: lstQrCode?.rows?.filter((el) => !el.is_printed).length,
  //   rows: lstQrCode?.rows?.filter((el) => !el.is_printed),
  //   totalPages: lstQrCode?.rows?.filter((el) => !el.is_printed).length % 10
  // })


  const payload = {
    currentPage: currentPage,
    pageSize: pageSize,
    sortField: "created_at",
    sortOrder: "desc",
    keyword: keyword,
    is_printed: Utils.convertToBoolean(params)
  };

  const onAddQrCode = (quantityQRCode) => {
    dispatch(addNewQRCode({ quantity: quantityQRCode })).then((res) => {
      handleCloseDialog();
      handleLoadQRCode();
      Utils.handlePopup(intl.messages.createNewQRCodeSuccess, "success");
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseAddMoreDialogQR = () => {
    setOpenDialogQR(false);
  };

  const initialValues = {
    quantityQRCode: 0,
  };

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      height: "30px",
      minHeight: "30px",
      "&:hover": {
        border: "0 !important",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0",
    }),
    input: (base) => ({
      ...base,
      margin: "0",
    }),
  };

  const handleOnchangeImage = (e) => {
    if (e.target.files[0]) {
      setFilesImage((prev) => [...prev, e.target.files[0]]);
    }
  };

  const setStateHandle = (isAdd = false, qrCodeObj = {}) => {
    setIsAdd(isAdd);
    setQRCodeEdit(qrCodeObj);
    setOpenDialog(true);
  };

  //function handle button action
  const handleAddClick = () => {
    setIsAdd(true);
    setOpenDialog(true);
  };
  const handleDeleteClick = async (e) => {
    const result = await Utils.handleConfirmDelete(intl);
    if (result.isConfirmed) {
      dispatch(showLoading());
      await dispatch(deleteQRCode({ id: e.data.id })).then((result) => {
        const status = Utils.getValues(result, "payload.status", null);
        if (status === Config.SUCCESS) {
          handleLoadQRCode();
          Utils.handlePopup(intl.messages.deleteSuccess, "success");
        } else {
          Utils.handlePopup(intl.messages.deleteFail, "error");
        }
      });
      dispatch(hideLoading());
    }
  };

  const handleEditClick = (e) => {
    setStateHandle(false, e);
  };

  const handlePrintClick = () => {
    setStateHandle(false);
  };
  const handleLoadQRCode = () => {
    dispatch(getListQrCode(payload));
  };

  
  const onPrintQRCode = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => handleOnAfterPrint(),

    onPrintError: () => { console.log('onPrintError') }
  });

  const handleSubmit = async (e) => {
    const { quantityQRCode } = e;
    if (!Number(quantityQRCode)) return;
    if (isAdd) {
      onAddQrCode(quantityQRCode);
    } else {
      const payload_not_printed = {
        currentPage: 1,
        pageSize: quantityQRCode,
        is_printed: false,
      };
      const res = await dispatch(getListQrCode(payload_not_printed));
      const qr_list = res.payload.responseData.rows;


      const printContainer = document.getElementById("print-container");
      const updatePrintArr = [];
      
      if (qr_list.length < quantityQRCode) {
        setOpenDialogQR(true);
        setQrCodeNotEnough(quantityQRCode - qr_list.length);
      } else {
        // qrCodePrintNotYet.forEach((element) => {
        //   const qrCodeInfo = document.createElement("img");
        //   qrCodeInfo.src = `https://api.qrserver.com/v1/create-qr-code/?data=${element.value}&amp;size=200x200`;
        //   qrCodeInfo.alt = "barcode";
        //   qrCodeInfo.width = "200";
        //   qrCodeInfo.height = "200";
        //   qrCodeInfo.style.paddingTop = "50px";
        //   printContainer.appendChild(qrCodeInfo);
        // });
        qr_list.some((element, index) => {
          if (index <= (quantityQRCode - 1)) {
            const qrCodeInfo = document.createElement("img");
            qrCodeInfo.src = `https://api.qrserver.com/v1/create-qr-code/?data=${element.value}&amp;size=200x200`;
            qrCodeInfo.alt = "barcode";
            qrCodeInfo.width = "200";
            qrCodeInfo.height = "200";
            qrCodeInfo.style.paddingTop = "50px";
            printContainer.appendChild(qrCodeInfo);
            updatePrintArr.push(element);

          } else {
            return;
          }
        });
        updatePrintLstRef.current = updatePrintArr
        onPrintQRCode();
      }

    }
  };

  const handleOnAfterPrint = async () => {

    const clone = JSON.parse(JSON.stringify([...updatePrintLstRef.current]))

    clone.length > 0 &&
      await clone.forEach((element) =>
        dispatch(updatePrint({ id: element.id }))
      );
    handleCloseDialog();
    handleCloseAddMoreDialogQR();
    handleLoadQRCode();
  };

  const onSubmitAddMoreQRCode = async (e) => {
    dispatch(addNewQRCode({ quantity: qrCodeNotEnough }))
      .then((res) => {
        const status = Utils.getValues(res, "payload.status", null);
        if (status === Config.SUCCESS) {
          handleCloseDialog();
          handleCloseAddMoreDialogQR();
          handleLoadQRCode();
          Utils.handlePopup(intl.messages.createNewQRCodeSuccess, "success");
        } else {
          Utils.handlePopup(intl.messages.createNewQRCodeFailure, "error");
        }
      })
  };


  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize, keyword]);

  useEffect(() => {
    dispatch(showLoading());
    const delayDebounceFn = setTimeout(() => {
      handleLoadQRCode();
      dispatch(hideLoading());
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [pageSize, keyword, currentPage, dispatch, params]);

  const productContainerProps = {
    ...props,
    intl,
    keyword,
    setKeyword,
    openDialog,
    handleCloseDialog,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    initialValues,
    columns,
    filesImage,
    handleOnchangeImage,
    selectStyle,
    selectOptions,
    qrCodeEdit,
    openAddMoreQrCode,
    handleCloseAddMoreDialogQR,
    qrCodeBase64,
    unit,
    handleAddClick,
    handleDeleteClick,
    handleEditClick,
    isAdd,
    params,
    onAddQrCode,
    handlePrintClick,
    handleSubmit,
    printRef,
    onSubmitAddMoreQRCode,
  };
  return <QrCodeMainView {...propsProvider(productContainerProps)} />;
}

export default QrCodeContainer;
