import React from "react";
import Footer from "components/Footer";
import { ImageDiscovery } from "assets/images";
export default function AccuracyComponent(props) {
  const { intl, onPressDiscoveryBtn } = props;
  return (
    <Footer headerTitle={intl.messages.AuthenticationSuccessful} isBold>
      <p>{intl.messages.GenuineProduct}</p>
      <img
        alt="ImageDiscovery"
        src={ImageDiscovery}
        style={{ width: "40%", height: "40%", marginBottom: 10 }}
      />
      <button className="btn-discover" onClick={onPressDiscoveryBtn}>
        {intl.messages.Discover}
      </button>
    </Footer>
  );
}
