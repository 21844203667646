import DecorateContainer from 'components/DecorateContainer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './ForgetPw.i18n';
import ForgetPwContainer from './ForgetPwContainer';

export default function ForgetPw(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    // const infoSignin = useSelector(signInSelector);
    const forgetPwProps = {
        ...props,
        i18nLabels,
        history,
        dispatch,
    };
    return (
        <DecorateContainer
            {...forgetPwProps}
            childComponent={ForgetPwContainer}
        />
    );
}
