import React, { useEffect, useRef, useState } from "react";
import propsProvider from "./ProductPropsProvider";
import ProductMainView from "./template/ProductMainView";
import { useIntl } from "react-intl";
import {
  getProductInfo,
  getProductScan,
  guestScanQR,
  userScanQR,
} from "../Scan/ScanSlice";
import { getGinsengByCode } from "pages/Admin/Ginseng/GinsengSlice";
import ProductCodeComponent from "./template/subViews/ProductCodeComponent";
import ScanProductComponent from "./template/subViews/ScanProductComponent";
import AccuracyComponent from "./template/subViews/AccuracyComponent";
import ProductInfoComponent from "./template/subViews/ProductInfoComponent";
import { getArticleOfProductByProductId } from "pages/News/NewSlice";
import { getListAddressByProductId } from "pages/Address/AddressSlice";
import Utils from "utils";
import { getListCertificateByProductId } from "pages/Certificates/CertificatesSlice";
import InCorrectComponent from "./template/subViews/InCorrectComponent";
import { useFormik } from "formik";
import Config from "configuration";
export default function ProductContainer(props) {
  const { dispatch, productScan, productInfo, history, ginsengInfoByCode } = props;

  const [otp, setOtp] = useState();
  const intl = useIntl();

  const [content, setContent] = useState(intl.messages.anotherInfo);
  const [progressPercent, setProgressPercent] = useState(0);
  const [filesImage, setFilesImage] = useState([]);
  const anotherInfo = intl.messages.AnotherInfo;
  const windowHeight = useRef(window.innerHeight);

  const switchTab = (select) => {
    const tabs = {
      [intl.messages.ProtectScreen]: (
        <ProductCodeComponent {...productContainerProps} />
      ),
      [intl.messages.ProductInfo]: (
        <ScanProductComponent {...productInfoProps} />
      ),
      [intl.messages.Discover]: (
        <AccuracyComponent {...productContainerProps} />
      ),
      [intl.messages.AnotherInfo]: (
        <ProductInfoComponent {...productContainerProps} />
      ),
      [intl.messages.InCorrect]: (
        <InCorrectComponent {...productContainerProps} />
      ),
    };

    return tabs[select];
  };

  const onSlideChange = (from, to) => {
    const eachPercent = 100 / ginsengInfoByCode.images.length;
    let count = to + 1;
    if (from === ginsengInfoByCode.images.length - 1 && to === 0) {
      count = 1;
    }
    setProgressPercent(eachPercent * count);
  };

  const onPressCorrectBtn = () => {
    setContent(intl.messages.ProtectScreen);
  };
  const onPressInCorrectBtn = () => {
    setContent(intl.messages.InCorrect);
  };
  const onPressDiscoveryBtn = () => {
    setContent(intl.messages.AnotherInfo);
  };
  const onLoadUrl = async () => {
    const data = history.location.code;

    if (data) {
      onBackNavigateDiscovery();
    }
    const queryPrams = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    const qrCode = queryPrams.code;
    if (qrCode) {
      await dispatch(userScanQR({ code: qrCode })).then((result) => {
        const { responseData } = Utils.getValues(result, "payload", null)

        if (responseData) {
          const { code } = Utils.getValues(
            result,
            "payload.responseData.status",
            "NONE"
          );
          const { id } = Utils.getValues(
            result,
            "payload.responseData",
            null
          );

          code === Config.ginsengStatus.NONE
            ? history.push(`/ginseng/form?qr_id=${id}`)
            : dispatch(getGinsengByCode(result.payload.responseData.code)).then(res => onPressDiscoveryBtn());
        }
        else {
          return history.push("/empty");
        }
      });
    } else {
      return history.push("/empty");
    }
  };

  const onPressBack = () => {
    const navigate = {
      [intl.messages.ProtectScreen]: intl.messages.ProductInfo,
      [intl.messages.ProductInfo]: "",
      [intl.messages.InCorrect]: intl.messages.ProductInfo,
      [intl.messages.Discover]: intl.messages.ProtectScreen,
      [intl.messages.AnotherInfo]: intl.messages.Discover,
    };
    setContent(navigate[content]);
    history.push("/scan")
  };
  const onBackNavigateDiscovery = () => {
    setContent(intl.messages.AnotherInfo);
  };

  const handleOnchangeIncorrect = (e) => {
    setFilesImage((prev) => [...prev, URL.createObjectURL(e.target.files[0])]);
  };
  const handleRemoveImage = (e) => {
    const newFiles = filesImage.filter((item) => item !== e);
    setFilesImage(newFiles);
  };
  const formik = useFormik({
    initialValues: {
      place: "",
      address: "",
      email: "",
      note: "",
    },
    onSubmit: (values, { resetForm }) => {
      history.push("/scan");
      resetForm({ values: "" });
    },
  });

  useEffect(() => {
    onLoadUrl();
  }, []);

  useEffect(() => {
    if (ginsengInfoByCode && ginsengInfoByCode?.images)
      setProgressPercent(100 / ginsengInfoByCode?.images?.length);
  }, [ginsengInfoByCode]);

  //handle back button in browser
  const [locationKeys, setLocationKeys] = useState([])

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          //handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          //handle back event
          history.push("/scan")
        }
      }
    })
  }, [locationKeys])

  const productInfoProps = {
    productScan,
    intl,
    onPressCorrectBtn,
    onPressInCorrectBtn,
  };

  const onPressDispatch = (path) => {

    const payload = {
      id: ginsengInfoByCode.id,
      qrCode: ginsengInfoByCode.qr_code_id,
      securityCode: 1,
    };
    switch (path) {
      case "moreInfo":
        history.push("/moreInfo");
        break;
      case "certificates":
        dispatch(getListCertificateByProductId(payload));
        history.push("/certificates");
        break;
      case "news":
        dispatch(getArticleOfProductByProductId(payload));
        history.push("/news");
        break;
      case "address":
        dispatch(getListAddressByProductId(payload));
        history.push("/address");
        break;
      default:
        break;
    }
  };

  const productContainerProps = {
    ...props,
    intl,
    otp,
    content,
    productInfo,
    setOtp,
    onPressBack,
    switchTab,
    onPressCorrectBtn,
    onPressDiscoveryBtn,
    onPressDispatch,
    onSlideChange,
    progressPercent,
    formik,
    handleRemoveImage,
    handleOnchangeIncorrect,
    filesImage,
    anotherInfo,
    windowHeight,
  };
  return <ProductMainView {...propsProvider(productContainerProps)} />;
}
