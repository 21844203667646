const labels = {
    vi: {
        address: 'Địa chỉ',
        desc: 'Mô tả',
        dateCreated: 'Ngày tạo',
        tableTitle: 'Danh sách địa chỉ',
        unit: 'địa chỉ',
        add: 'Thêm',
        update: 'Cập nhật',
        cancel: 'Hủy',
        createAddress: 'Tạo địa chỉ mới',
        updateAddress: 'Chỉnh sửa địa chỉ',
        placeholderDesc: 'Nhập mô tả tại đây',
        addSuccess: 'Thêm địa chỉ thành công!',
        addFail: 'Thêm địa chỉ thất bại!',
        updateSuccess: 'Chỉnh sửa thành công!',
        updateFail: 'Chỉnh sửa thất bại!',
        deleteSuccess: 'Xoá địa chỉ thành công!',
        deleteFail: 'Xoá địa chỉ thất bại!',
        ConfirmDeleteTitle: "Bạn có muốn xoá dữ liệu",
        ConfirmDeleteText: "Vui lòng xác nhận",
        ConfirmDeleteYes: "Xoá",
        ConfirmDeleteNo: "Huỷ",
        requiredAddress: 'Địa chỉ không được để trống',
        enterAddress: 'Nhập địa chỉ tại đây',
    },
    en: {
        address: 'Address',
        desc: 'Description',
        dateCreated: 'Date created',
        tableTitle: 'Location list',
        unit: 'locations',
        add: 'Add',
        update: 'Update',
        cancel: 'Cancel',
        createAddress: 'Create new address',
        updateAddress: 'Edit address',
        placeholderDesc: 'Enter description here',
        addSuccess: 'Add location success!',
        addFail: 'Add location fail!',
        updateSuccess: 'Update location success!',
        updateFail: 'Update location fail!',
        deleteSuccess: 'Delete location success!',
        deleteFail: 'Delete location fail!',
        ConfirmDeleteTitle: "You want delete data",
        ConfirmDeleteText: "Please confirm",
        ConfirmDeleteYes: "Delete",
        ConfirmDeleteNo: "Cancel",
        requiredAddress: 'Address is required',
        enterAddress: 'Enter address',

    },
};

export default labels;
