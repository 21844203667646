import { certificateSelector, ginsengAdminSelector, productSelector } from "app/selectors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CertificatesContainer from "./CertificatesContainer";
import i18nLabels from "./Certificates.i18n";
import DecorateContainer from "components/DecorateContainer";
export default function MoreInfo(props) {
  const dispatch = useDispatch();
  const certificate = useSelector(certificateSelector);
  const product = useSelector(productSelector);
  const infoGinseng = useSelector(ginsengAdminSelector)

  const { ginsengInfoByCode = {} } = infoGinseng
  const { certificatesList = {} } = certificate;
  const { productInfo = {} } = product;
  const CertificatesProps = {
    ...props,
    dispatch,
    i18nLabels,
    certificatesList,
    productInfo,
    ginsengInfoByCode
  };
  return (
    <DecorateContainer
      {...CertificatesProps}
      childComponent={CertificatesContainer}
    />
  );
}
