const labels = {
    vi: {
        name: 'Tên chứng nhận',
        fileName: 'File',
        desc: 'Mô tả',
        dateCreated: 'Ngày tạo',
        tableTitle: 'Danh sách chứng nhận',
        unit: 'chứng nhận',
        add: 'Thêm',
        update: 'Cập nhật',
        cancel: 'Hủy',
        certificate: 'Giấy chứng nhận',
        enterCertificate: 'Nhập giấy chứng nhận',
        createCertificate: 'Tạo mới chứng nhận',
        updateCertificate: 'Chỉnh sửa chứng nhận',
        fileCertificate: 'File giấy chứng nhận',
        fileCertificateFail: 'File giấy chứng nhận phải là dạng PDF',
        placeholderDesc: 'Nhập mô tả',
        chooseFile: 'Chọn tệp',
        generalInformation: 'Thông tin chung',
        avatar: 'Hình đại diện',
        requiredAvatar: 'Avatar không được để trống',
        requiredName: 'Tên không được bỏ trống',
        requiredFile: 'File không được để trống',
        addSuccess: 'Thêm chứng nhận thành công',
        addFail: 'Thêm chứng nhận thất bại',
        updateSuccess: 'Chỉnh sửa thành công',
        updateFail: 'Chỉnh sửa thất bại',
        uploadAvatarFail: 'Upload hình ảnh thất bại',
        deleteSuccess: 'Xoá thành công!',
        deleteFail: 'Xoá thất bại!',
        ConfirmDeleteTitle: "Bạn có muốn xoá dữ liệu",
        ConfirmDeleteText: "Vui lòng xác nhận",
        ConfirmDeleteYes: "Xoá",
        ConfirmDeleteNo: "Huỷ",
    },
    en: {
        name: 'Certificate name',
        fileName: 'File',
        dateCreated: 'Date created',
        desc: 'Description',
        tableTitle: 'Certificate list',
        unit: 'certificates',
        add: 'Add',
        update: 'Update',
        cancel: 'Cancel',
        certificate: 'Certificate',
        enterCertificate: 'Enter certificate',
        createCertificate: 'Tạo mới chứng nhận',
        updateCertificate: 'Chỉnh sửa chứng nhận',
        fileCertificate: 'File certificate',
        fileCertificateFail: 'File certificate must be the PDF type',
        placeholderDesc: 'Enter description',
        chooseFile: 'Choose file',
        generalInformation: 'General Information',
        avatar: 'Avatar',
        requiredAvatar: 'Avatar is required',
        requiredName: 'Name is required',
        requiredFile: 'File is required',
        addSuccess: 'Add certificate success',
        addFail: 'Add certificate fail',
        updateSuccess: 'Update certificate success',
        updateFail: 'Update certificate fail',
        uploadAvatarFail: 'Upload avatar fail',
        deleteSuccess: 'Delete success!',
        deleteFail: 'Delete Fail!',
        ConfirmDeleteTitle: "You want delete data",
        ConfirmDeleteText: "Please confirm",
        ConfirmDeleteYes: "Delete",
        ConfirmDeleteNo: "Cancel",
    },
};

export default labels;
