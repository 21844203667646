import { flexRender } from "@tanstack/react-table";
import { ChevronLeft, ChevronRight, Delete, Edit } from "assets/icon";
import ReactPaginate from "react-paginate";
import MenuOption from "../../Menu";

export default function TableMainView(props) {
  const {
    intl,
    table,
    handleEditClick,
    handleDeleteClick,
    pageSizeConfig,
    handleChangePageSize,
    handleChangeCurrentPage,
    totalPage,
    currentPage,
    ginMenu,
    onHandleToggleSignalModal,
    onHandleToggleDescriptionModal,
    showActionColumn = true,
    showEditButton = true,
  } = props;
  return (
    <div className="table-all">
      <table className="table-atom">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              <td
                className="table-header"
                key={`custom-${headerGroup.id}-1`}
                align="center"
              >
                {intl.messages.no}
              </td>
              {headerGroup.headers.map((header) => (
                <td
                  className="table-header"
                  key={header.id}
                  align={header.column.columnDef.meta?.align}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </td>
              ))}
              {/* {isQrCode && (
                <td
                  className="table-header"
                  key={`custom-${headerGroup.id}-2`}
                  align="center"
                >
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                  />
                </td>
              )} */}
              {showActionColumn && <td
                className="table-header"
                key={`custom-${headerGroup.id}-2`}
                align="center"
              >
                {intl.messages.action}
              </td>}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              <td
                key={`custom-${row.id}-1`}
                align="center"
                className="fit-content"
              >
                {row.index + 1}
              </td>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  align={cell.column.columnDef.meta?.align}
                  className={cell.column.columnDef.meta?.class}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
              {/* {isQrCode && (
                <td
                  key={`custom-${row.id}-2`}
                  align="center"
                  className="fit-content"
                >
                  <div className="button-group">
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    ></input>
                  </div>
                </td>
              )} */}
              {showActionColumn && <td
                key={`custom-${row.id}-2`}
                align="center"
                className="fit-content"
              >
                {ginMenu ? (
                  <div className="button-group">
                    <MenuOption
                      intl={intl}
                      onHandleToggleSignalModal={onHandleToggleSignalModal}
                      handleEditClick={handleEditClick}
                      item={row.getAllCells()[0].row.original}
                      onHandleToggleDescriptionModal={onHandleToggleDescriptionModal}
                    />
                  </div>
                ) : (
                  <div className="button-group">
                    {showEditButton && <button
                      onClick={() =>
                        handleEditClick(row.getAllCells()[0].row.original)
                      }
                    >
                      <Edit />
                    </button>}
                    <button
                      onClick={() =>
                        handleDeleteClick({
                          data: row.getAllCells()[0].row.original,
                          index: row.index,
                        })
                      }
                    >
                      <Delete />
                    </button>
                  </div>
                )}
              </td>}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-footer flex items-center gap-2">
        <div className="page-item-control">
          <span className="rowcount-mess">{intl.messages.rowCount}</span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => (
              table.setPageSize(Number(e.target.value)),
              handleChangePageSize(Number(e.target.value))
            )}
          >
            {pageSizeConfig.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="table-pagination">
          <ReactPaginate
            forcePage={currentPage - 1}
            previousLabel={<ChevronLeft />}
            nextLabel={<ChevronRight />}
            pageCount={totalPage}
            marginPagesDisplayed={"1"}
            pageRangeDisplayed={"3"}
            onPageChange={(e) => handleChangeCurrentPage(e.selected + 1)}
            containerClassName="pagination"
            pageClassName="page-items"
            pageLinkClassName="page-links"
          />
        </div>
      </div>
    </div >
  );
}
