import { GrayQRCode } from "assets/icon";
import DialogModal from "components/DialogModal";
import { Field } from "formik";
import Select from "react-select";
function AddMoreQRCodeModal({
    openDialog,
    handleCloseDialog,
    intl,
    handleSubmit,
    initialValues = {},
}) {
    return (
        <DialogModal
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            handleSubmit={handleSubmit}
            headerTitle={intl.messages.createNewQRCode}
            submitName={intl.messages.add}
            cancelName={intl.messages.cancel}
            initialValues={initialValues}
        >
            <div className="sub-header">{intl.messages.notYetPrintedWantAddMoreQRCode}</div>

        </DialogModal>
    );
}

export default AddMoreQRCodeModal;
