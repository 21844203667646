const labels = {
    vi: {
        VerifiedTitle: 'Mã xác thực',
        Message: 'Vui lòng nhập mã xác thực để thiết lập lại mật khẩu',
        SubmitLabel: 'Xong',
        InvalidCode: 'Mã xác thực không chính xác',
    },
    en: {
        VerifiedTitle: 'Verification code',
        Message: 'Please enter the verification code to reset the password',
        SubmitLabel: 'Complete',
        InvalidCode: 'Verification code is incorrect',
    },
};

export default labels;
