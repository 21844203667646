import ImageAsset from './asset.png';
import ImageBackgroundSignIn from './background_signin.png';
import ImageDiscovery from './image_discovery.png';
import ImageGinsengBG from './image_ginseng_background.png';
import ImageWine from './image_wine.png';
import IconInfo from './icon_info.png';
export {
    ImageAsset,
    ImageBackgroundSignIn,
    ImageDiscovery,
    ImageGinsengBG,
    ImageWine,
    IconInfo
};
