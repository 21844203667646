export const commonSelector = (state) => state.common;
export const productSelector = (state) => state.product;
export const articleSelector = (state) => state.article;
export const addressSelector = (state) => state.address;
export const certificateSelector = (state) => state.certificate;
export const certificateAdminSelector = (state) => state.certificateAdmin;
export const articleAdminSelector = (state) => state.articleAdmin;
export const ginsengAdminSelector = (state) => state.ginsengAdmin;
export const productAdminSelector = (state) => state.productAdmin;
export const loadingSelector = (state) => state.loading;
export const locationAdminSelector = (state) => state.locationAdmin;
export const signInAdminSelector = (state) => state.signInAdmin;
export const forgetPwSelector = (state) => state.forgetPw;
export const verifiedCodeSelector = (state) => state.verifiedCode;
export const resetPwSelectoer = (state) => state.resetPw;
export const qrCodeSelector = (state) => state.qrCode;