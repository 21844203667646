const labels = {
  vi: {
    ginId: "Mã sâm",
    ginName: "Tên sâm",
    linkedProduct: "Sản phẩm liên kết",
    dateCreated: "Ngày tạo",
    tableTitle: "Danh sách sâm",
    source: "Nguồn gốc",
    unit: "loại sâm",
    add: "Thêm",
    cancel: "Hủy",
    update: "Cập nhật",
    createGinseng: "Tạo sâm mới",
    certificateOfGinseng: "Giấy chứng nhận",
    requiredCode: "Mã không được để trống",
    requiredName: "Tên không được để trống",
    requiredDate: "Ngày không được để trống",
    requiredSource: "Nguồn gốc không được để trống",
    requiredUseOf: "Tác dụng không được để trống",
    requiredAvatar: "Hình ảnh không được để trống",
    requiredFile: "Yêu cầu có giấy chứng nhận sâm",
    updateGinseng: "Chỉnh sửa Sâm",
    chooseFile: "Tải giấy chứng nhận lên",
    useOfGinseng: "Tác dụng của Sâm",
    AnotherInformation: "Thông tin khác (nếu có)",
    inputCode: "Nhập mã sâm",
    inputName: "Nhập tên sâm",
    inputSource: "Nhập nguồn gốc sâm",
    inputEffect: "Nhập các tác dụng khác của sâm tại đây",
    inputAnotherInformation: "Nhập các thông tin khác của sâm tại đây",
    avatarGinseng: "Hình ảnh sâm (tối đa 5 ảnh)",
    addSuccess: "Thêm sâm thành công",
    addFail: "Thêm sâm thất bại",
    updateSuccess: "Chỉnh sửa thành công",
    updateFail: "Chỉnh sửa thất bại",
    deleteSuccess: "Xoá sâm thành công!",
    signSuccess: "Ký duyệt thành công!",
    signFail: "Ký duyệt thất bại",
    deleteFail: "Xoá sâm thất bại!",
    addImageFail: "Thêm ảnh thất bại",
    requiredImageType: 'File phải là dạng hình ảnh',
    updateImageFail: "Cập nhật ảnh thất bại",
    generalInformation: "Thông tin chung",
    generalDetail: "Thông tin chi tiết",
    ConfirmDeleteTitle: "Bạn có muốn xoá dữ liệu",
    ConfirmDeleteText: "Vui lòng xác nhận",
    ConfirmDeleteYes: "Xoá",
    ConfirmDeleteNo: "Huỷ",
    qrCode: "Mã QR ",
    categoryGin: "Loại sâm",
    enterersName: "Tên người nhập",
    statusGin: "Trạng thái",
    pending: "Chờ duyệt",
    accepted: "Đã duyệt",
    sold: "Đã bán",
    refuse: "Từ chối",
    edit: "Chỉnh sửa",
    describeGinseng: "Chi tiết sâm",
    size: "Kích thước",
    yearOfGinseng: "Năm tuổi",
    weight: "Cân nặng",
    harvestDate: "Ngày thu hoạch",
    approve: "Duyệt",
    signApprove: "Ký duyệt"
  },
  en: {
    ginId: "Gin code",
    ginName: "Gin name",
    dateCreated: "Date created",
    linkedProduct: "Liked product",
    tableTitle: "Ginseng List",
    source: "Source",
    unit: "types of ginseng",
    add: "Add",
    cancel: "Cancel",
    update: "Update",
    createGinseng: "Create new Ginseng",
    requiredCode: "Code is required",
    requiredName: "Name is required",
    requiredDate: "Date is required",
    requiredSource: "Source is required",
    requiredUseOf: "Use of Ginseng is required",
    requiredAvatar: "Avatar is required",
    requiredFile: "File certificate is required",
    certificateOfGinseng: "Certificate of Ginseng",
    updateGinseng: "Update Ginseng",
    chooseFile: "Choose file certificate of ginseng",
    useOfGinseng: "Use of ginseng",
    AnotherInformation: "Another information (if any)",
    inputCode: "Enter ginseng code",
    inputName: "Enter ginseng name",
    inputSource: "Enter ginseng source",
    inputEffect: "Enter other effects of ginseng here",
    inputAnotherInformation: "Enter other information of ginseng here",
    avatarGinseng: "Ginseng image (limited 5 pictures)",
    addSuccess: "Add ginseng success",
    addFail: "Add ginseng fail",
    updateSuccess: "Update ginseng success",
    updateFail: "Update ginseng fail",
    deleteSuccess: "Delete ginseng success!",
    deleteFail: "Delete ginseng fail!",
    addImageFail: "Add image fail!",
    requiredImageType: 'The file must be an image file',
    updateImageFail: "Update image fail!",
    generalInformation: "General information",
    generalDetail: "General detail",
    ConfirmDeleteTitle: "You want delete data",
    ConfirmDeleteText: "Please confirm",
    ConfirmDeleteYes: "Delete",
    ConfirmDeleteNo: "Cancel",
    qrCode: "QR Code",
    categoryGin: "Category",
    enterersName: "Enterer's name",
    statusGin: "Status",
    pending: "Pending",
    accepted: "Accepted",
    sold: "Sold",
    refuse: "Refuse",
    edit: "Edit",
    describeGinseng: "Describe ginseng",
    size: "Size",
    yearOfGinseng: "Year of ginseng",
    weight: "Weight",
    harvestDate: "Harvest date",
    approve: "Approve",
    signApprove: "Sign Approve",
  },
};

export default labels;
