import { locationAdminSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './Location.i18n';
import LocationContainer from './LocationContainer';

export default function Locations(props) {
    const history = useHistory();
    const location = useSelector(locationAdminSelector);
    const { locationAdminList = [] } = location;
    const dispatch = useDispatch();
    const certificateProps = {
        ...props,
        dispatch,
        i18nLabels,
        history,
        locationAdminList,
    };
    return <DecorateContainer {...certificateProps} childComponent={LocationContainer} />;
}
