import DialogModal from "components/DialogModal";
import { Field } from "formik";
import Utils from "utils";
function LocationModal({
  openDialog,
  handleCloseDialog,
  intl,
  handleSubmit,
  initialValues,
  data,
  isAdd,
}) {
  return (
    <DialogModal
      openDialog={openDialog}
      handleCloseDialog={handleCloseDialog}
      handleSubmit={handleSubmit}
      initialValues={isAdd ? initialValues : {
        id: data.id,
        address: data.address,
        description: data.description,
        created_at: Utils.formatDateYDM(data.created_at)
      }}
      headerTitle={
        isAdd ? intl.messages.createAddress : intl.messages.updateAddress
      }
      submitName={isAdd ? intl.messages.add : intl.messages.update}
      cancelName={intl.messages.cancel}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="input-container" style={{ marginRight: "16px" }}>
          <Field
            id="address"
            name="address"
            type="text"
            className="input-container__input"
            placeholder={intl.messages.enterAddress}
          />
          <label htmlFor="address" className="input-container__title required">
            {intl.messages.address}
          </label>
        </div>
        <div className="input-container" style={{ width: "30%" }}>
          <label className="link-label">
            <Field name="created_at" htmlFor="avatar" >
              {({ field }) => (
                <>
                  <span className="date-icon" />
                  <span className="silver-text">{field.value}</span>
                  <input className="input-container__input"
                    id="created_at"
                    type="date"
                    style={{ display: "none" }} />
                </>
              )}
            </Field>
          </label>
          <label className="input-container__title">
            {intl.messages.dateCreated}
          </label>
        </div>
      </div>
      <div
        className="input-container"
        style={{ minHeight: "100px", justifyContent: "flex-end" }}
      >
        <Field
          className="input-container__textarea"
          id="description"
          name="description"
          type="text"
          placeholder={intl.messages.placeholderDesc}
        />
        <label
          htmlFor="description"
          className="input-container__title"
          style={{ marginBottom: "4px" }}
        >
          {intl.messages.desc}
        </label>
      </div>
    </DialogModal>
  );
}

export default LocationModal;
