const labels = {
    vi: {
        ScanQR: "Quét mã QR",
        CameraFrame: "Hướng khung camera để quét mã",
    },
    en: {
        ScanQR: "Scan QR Code",
        CameraFrame: "Point camera frame to scan code",
    },
};

export default labels;
