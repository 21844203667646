import { hideLoading, showLoading } from 'components/Loading/LoadingSlice';
import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Utils, { ForgetPwProcess } from 'utils';
import { changeNextProcess, setVerifiedCode } from '../ForgetPw/forgetPwSlice';
import propsProvider from './VerifiedCodePropsProvider';
import VerifiedCodeMainView from './template/VerifiedCodeMainView';
import { submitVerfificationCode } from './verifiedCodeSlice';

export default function VerifiedCoreContainer(props) {
    const [code, setCode] = useState('');
    const { dispatch, history, onChange } = props;
    const [errorMessage, setErrorMessage] = useState(false);
    const intl = useIntl();
    const numInputs = 4;

    const onSubmitClick = () => {
        const delayDebounceFn = setTimeout(async () => {
            dispatch(showLoading());
            dispatch(submitVerfificationCode({ code: code })).then((result) => {
                const status = Utils.getValues(result, 'payload.status', null);
                if (status === Config.FAIL) {
                    setErrorMessage(true);
                    dispatch(hideLoading());
                    return;
                }

                if (status === Config.SUCCESS) {
                    let code_temp = code;
                    dispatch(setVerifiedCode(String(code_temp)));
                    dispatch(changeNextProcess(ForgetPwProcess.CreateNewPw));
                    dispatch(hideLoading());
                    history.push(pagePath.resetPwUrl);
                }
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    };

    const verifiedPropsProvider = {
        ...props,
        intl,
        code,
        onChange,
        numInputs,
        errorMessage,
        setCode,
        onSubmitClick,
    };
    return <VerifiedCodeMainView {...propsProvider(verifiedPropsProvider)} />;
}
