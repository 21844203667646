import { homeSelector } from "app/selectors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreInfoContainer from "./MoreInfoContainer";
import i18nLabels from "./MoreInfo.i18n"
import DecorateContainer from "components/DecorateContainer";
import { productSelector, ginsengAdminSelector } from "app/selectors";
export default function MoreInfo(props) {
  const dispatch = useDispatch();
  const infoProduct = useSelector(productSelector)
  const infoGinseng = useSelector(ginsengAdminSelector)

  const { ginsengInfoByCode = {} } = infoGinseng
  const { productInfo, productScan } = infoProduct
  const moreInfoProps = {
    ...props,
    dispatch,
    i18nLabels,
    productInfo,
    productScan,
    ginsengInfoByCode
  };
  return <DecorateContainer {...moreInfoProps} childComponent={MoreInfoContainer} />
}
