import environmentConfig from './enviromentConfig';
import { storageKey } from './storageKey';

const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const envConfig = isDevEnv ? environmentConfig.development : environmentConfig.production;
const domain = 'https://gateway.dev.meu-solutions.com/ngoclinhscanging/api/v1.0/';
const endPointAdmin = 'https://gateway.dev.meu-solutions.com/ngoclinhscanging-admin/api/v1.0/';
const SUCCESS = 'success';
const FAIL = 'fail';
const INVALIDPW = 902;
const INVALIDEMAIL = 901;
const Duplicate = 'trùng mã sản phẩm';
const Image_Url = 'https://gateway.dev.meu-solutions.com/ngoclinhscanging-admin'
const ginsengStatus = {
    NONE: 'NONE',
    INAPPROVED: 'INAPPROVED',
    APPROVED: 'APPROVED',
    SOLD: 'SOLD'
}
const Config = {
    isDevEnv,
    storageKey,
    apiConfig: {
        ...envConfig,
    },
    domain,
    SUCCESS,
    FAIL,
    endPointAdmin,
    INVALIDPW,
    INVALIDEMAIL,
    Duplicate,
    ginsengStatus,
    Image_Url
};

export default Config;
