export default function propsProvider(props) {
  /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
  const { onScanOtherCode, intl } = props;
  return {
    intl,
    onScanOtherCode,
  };
}
