const labels = {
  vi: {
    productId: "Mã sản phẩm",
    productName: "Tên sản phẩm",
    qrCode: "Mã QR",
    dateCreated: "Ngày tạo",
    tableTitle: "Danh sách sản phẩm",
    units: "sản phẩm",
    add: "Thêm mới",
    update: "Cập nhật",
    createProduct: "Tạo sản phẩm mới",
    updateProduct: "Chỉnh sửa sản phẩm",
    cancel: "Hủy",
    createDate: "Ngày tạo",
    unit: "Đơn vị",
    ginsengLink: "Liên kết sâm (nếu có)",
    searchGinseng: "Chọn hoặc tìm kiếm theo mã sâm",
    generalInformation: "Thông tin chung",
    detailInfo: "Thông tin chi tiết",
    ginsengEffect: "Tác dụng của sâm",
    otherEffect: "Nhập các tác dụng khác của sâm tại đây",
    otherInfo: "Thông tin khác (nếu có)",
    otherInfoPH: "Nhập các thông tin khác của sâm tại đây",
    ginsengImage: "Hình ảnh sâm (tối đa 5 ảnh)",
    viewQr: "Xem mã QR",
    productIdPH: "Nhập mã sản phẩm",
    productNamePH: "Nhập tên sản phẩm",
    productUnitPH: "Nhập dung tích",
    ConfirmDeleteTitle: "Bạn có muốn xoá dữ liệu",
    ConfirmDeleteText: "Vui lòng xác nhận",
    ConfirmDeleteYes: "Xoá",
    ConfirmDeleteNo: "Huỷ",
    addSuccess: "Thêm sản phẩm thành công",
    addFail: "Thêm sản phẩm thất bại",
    updateSuccess: "Chỉnh sửa thành công",
    updateFail: "Chỉnh sửa thất bại",
    deleteSuccess: "Xoá sản phẩm thành công!",
    deleteFail: "Xoá sản phẩm thất bại!",
    updateImageFail: "Cập nhật ảnh thất bại",
    requiredCode: "Mã không được để trống",
    requiredName: "Tên không được để trống",
    requiredUnitValue: "Dung tích không được để trống",
    requiredDescription: "Tác dụng không được để trống",
    requiredImage: "Hình ảnh không được để trống",
    requiredManufactureDate: "Ngày sản xuất không được để trống",
    requiredGinseng: "Liên kết Sâm không được để trống",
    duplicateCode: "Mã code bị trùng vui lòng nhập mã khác",
    enterCodeGetQR: "Vui lòng điền mã sản phẩm để lấy mã QR",
    securityCode: "Mã xác thực",
    activeStatus: "Trạng thái",
    activated: "Đã kích hoạt",
    notActivated: "Chưa kích hoạt",
    manufactureDate: "Ngày sản xuất",
    id: "ID",
    quantityQRCode: "Số lượng mã QR",
    listQRPrinted: "Danh sách mã QR đã in",
    listQRNotYetPrinted: "Danh sách mã QR chưa in",
    createNewQRCode: "Tạo mới QR Code",
    printQRCode: "In mã QR",
    print: "In",
    notYetPrintedWantAddMoreQRCode: "QR code không đủ, bạn có muốn in thêm ?",
    createNewQRCodeSuccess: "Tạo mới QR Code thành công",
    createNewQRCodeFailure: "Tạo mới QR Code thất bại"
  },
  en: {
    productId: "Product code",
    productName: "Product name",
    dateCreated: "Date created",
    qrCode: "QR code",
    tableTitle: "Product list",
    units: "products",
    add: "Add",
    update: "Update",
    generalInformation: "General information",
    createProduct: "Create product",
    updateProduct: "Update product",
    cancel: "Cancel",
    productCode: "Product code",
    createDate: "Create date",
    unit: "Unit",
    ginsengLink: "Ginseng link (if any)",
    searchGinseng: "Select or search by ginseng code",
    detailInfo: "Detail Info",
    ginsengEffect: "Ginseng effect",
    otherEffect: "Enter other effects of ginseng here",
    otherInfo: "Other information (If any)",
    otherInfoPH: "Enter other information of ginseng here",
    ginsengImage: "Ginseng images (up to 5 photos)",
    viewQr: "View QR code",
    productIdPH: "Enter product code",
    productNamePH: "Enter product name",
    productUnitPH: "Enter product unit",
    ConfirmDeleteTitle: "You want delete data",
    ConfirmDeleteText: "Please confirm",
    ConfirmDeleteYes: "Delete",
    ConfirmDeleteNo: "Cancel",
    addSuccess: "Add product success",
    addFail: "Add product fail",
    updateSuccess: "Update product success",
    updateFail: "Update product fail",
    deleteSuccess: "Delete product success!",
    deleteFail: "Delete product fail!",
    updateImageFail: "Update image fail!",
    requiredCode: "Code is required",
    requiredName: "Name is required",
    requiredUnitValue: "Unit value is required",
    requiredDescription: "Description is required",
    requiredImage: "Image is required",
    requiredManufactureDate: "Manufacture date is required",
    duplicateCode: "Code is used! Please enter another code",
    enterCodeGetQR: "Require enter product code to get QR code",
    securityCode: "Securitu code",
    activeStatus: "Status",
    activated: "Activated",
    notActivated: "Not activated",
    manufactureDate: "Manufacture Date",
    id: "ID",
    quantityQRCode: "Quantity QR Code",
    listQRPrinted: "List Qr code printed",
    listQRNotYetPrinted: "List Qr Code not yet printed",
    createNewQRCode: "Create new QR Code",
    printQRCode: "Print QR Code",
    print: "Print",
    notYetPrintedWantAddMoreQRCode: "Not yet QR code, you want add more?",
    createNewQRCodeSuccess: "Create new QR Code success",
    createNewQRCodeFailure: "Create new QR Code failure",
  },
};

export default labels;
