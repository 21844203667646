export default function propsProvider(props) {
    /*
          - To only list utilized props
          - Wrapping utilized props on each view
      */
    const { intl, countdown, onComplete } = props;
    return {
        intl,
        countdown,
        onComplete,
    };
}
