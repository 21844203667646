import { pagePath } from 'configuration/routeConfig';
import { useIntl } from 'react-intl';
import propsProvider from './SuccessPropsProvider';
import SuccessMainView from './template/SuccessMainView';

export default function SuccessContainer(props) {
    const { dispatch, history } = props;
    const intl = useIntl();
    const countdown = 5;

    const onComplete = () => {
        history.push(pagePath.signInUrl);
    };

    const successPropsProvider = {
        ...props,
        intl,
        countdown,
        onComplete,
    };
    return <SuccessMainView {...propsProvider(successPropsProvider)} />;
}
