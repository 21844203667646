import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ForgetPw } from 'api/AdminApi';

export const submitEmail = createAsyncThunk('forgetPw/forgetPwToken', async (params) => {
    const res = await ForgetPw.submitMail(params);
    return res;
});

const forgetPw = createSlice({
    name: 'forgetPw',
    initialState: {
        nextProcess: null,
        email: null,
        verifiedCode: null,
    },
    reducers: {
        changeNextProcess: (state, action) => {
            state.nextProcess = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setVerifiedCode: (state, action) => {
            state.verifiedCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitEmail.fulfilled, (state, action) => {
                state.signInAdmin = action.payload.responseData;
            })
            .addCase(submitEmail.rejected, (state, action) => {});
    },
});

export const { changeNextProcess, setEmail, setVerifiedCode } = forgetPw.actions;
const { reducer } = forgetPw;
export default reducer;
