import DecorateContainer from "components/DecorateContainer";
import i18nLabels from "./Table.i18n";
import TableContainer from "./TableContainer";

export default function Table(props) {
  const productTableProps = {
    ...props,
    i18nLabels,
  };

  return (
    <DecorateContainer {...productTableProps} childComponent={TableContainer} />
  );
}
