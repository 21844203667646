const labels = {
    vi: {
        FormTitle: 'Đăng nhập',
        EmailLabel: 'Email',
        EmailPlaceholder: 'Nhập email của bạn tại đây',
        PwLabel: 'Mật khẩu',
        PwPlaceholder: 'Nhập mật khẩu của bạn tại đây',
        RemeberSignIn: 'Ghi nhớ đăng nhập',
        ForgetPw: 'Quên mật khẩu?',
        SubmitLabel: 'Đăng nhập',
        EmailNotBlank: 'Email không được bỏ trống',
        PwNotBlank: 'Mật khẩu không được bỏ trống',
        InvalidEmail: 'Tài khoản không tồn tại',
        InvalidPw: 'Mật khẩu không chính xác',
        PwMinReq: 'Mật khẩu ít nhất 6 ký tự',
    },
    en: {
        SignInLabel: 'Sign in',
        EmailLabel: 'Email',
        EmailPlaceholder: 'Enter your email here',
        PwLabel: 'Password',
        PwPlaceholder: 'Enter your password here',
        RemeberSignIn: 'Remeber sign in',
        ForgetPw: 'Forget password',
        SubmitLabel: 'Sign in',
        EmailNotBlank: 'Email cannot be blank',
        PwNotBlank: 'Password cannot be blank',
        InvalidEmail: 'Account does not exist',
        InvalidPw: 'Password is incorrect',
        PwMinReq: 'Password must be at least 6 characters',
    },
};

export default labels;
