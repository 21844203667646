import { ProductInput } from "../ProductInput";
export default function CreateProductComp({intl}) {
  return (
    <div className="create-product">
      <div className="create-product-body">
        <h2 className="create-product-txtGeneralInfo">{intl.messages.createProduct}</h2>
        <div className="create-product-1stInput">
          <ProductInput
            titleClass={"ProductCode"}
            type="email"
            label={intl.messages.productId}
            placeholder={intl.messages.productIdPH}
          />
          <ProductInput
            titleClass={"ProductName"}
            type="email"
            label={intl.messages.productName}
            placeholder={intl.messages.productNamePH}
          />
        </div>
        <div className="create-product-2ndInput">
          <ProductInput
            titleClass={"ProductDate"}
            type="date"
            label={intl.messages.createProduct}
            placeholder={intl.messages.datePH}
          />
          <ProductInput
            titleClass={"ProductSource"}
            type="email"
            label={intl.messages.unit}
            intl={intl}
          />
          <ProductInput
            titleClass={"ProductQR"}
            label={intl.messages.qrCode}
            intl={intl}
          />
        </div>
        <span className="change-units">{intl.messages.changeUnits}</span>
        <ProductInput
          titleClass={"ProductLink"}
          type="email"
          label={intl.messages.ginsengLink}
          placeholder={intl.messages.searchGinseng}
        />
        <h2 className="txt-detail-info">{intl.messages.detailInfo}</h2>
        <div className="detail-info">
          <ProductInput
            titleClass={"ProductEffect"}
            type="email"
            label={intl.messages.ginsengEffect}
            placeholder={intl.messages.otherEffect}
          />
          <ProductInput
            titleClass={"DetailInfo"}
            type="email"
            label={intl.messages.otherInfo}
            placeholder={intl.messages.otherInfoPH}
          />
        </div>
        <ProductInput
          titleClass={"img"}
          type="email"
          label={intl.messages.ginsengImage}
        />
      </div>
    </div>
  );
}
