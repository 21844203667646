export default function propsProvider(props) {
    /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
    const {
        intl,
        data,
        content,
        handleShowQr,
        openDialogQR,
        showQrImage,
        handleCloseDialogQR,
        qrCodeBase64,
    } = props;
    return {
        intl,
        data,
        content,
        handleShowQr,
        openDialogQR,
        showQrImage,
        handleCloseDialogQR,
        qrCodeBase64,
    };
}
