import { certificateAdminSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './Certificate.i18n';
import CertificateContainer from './CertificateContainer';

export default function Certificates(props) {
    const history = useHistory();
    const certificate = useSelector(certificateAdminSelector);
    const { certificateAdminList = {} } = certificate;
    const dispatch = useDispatch();
    const certificateProps = {
        ...props,
        dispatch,
        i18nLabels,
        history,
        certificateAdminList,
    };
    return <DecorateContainer {...certificateProps} childComponent={CertificateContainer} />;
}
