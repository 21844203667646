const labels = {
  vi: {
    productInfo: "Thông tin sản phẩm",
    notFound: "Không tìm được thông tin",
    tryAgain: "Vui lòng quét mã khác!",
    scanOther: "Quét mã khác",
    addSuccess: "Thêm sâm thành công",
    addFail: "Thêm sâm thất bại",
    requiredImageType: 'File phải là dạng hình ảnh',
  },
  en: {
    productInfo: "Product Information",
    notFound: "Not found information",
    tryAgain: "Try to scan other code!",
    scanOther: "Scan Other QR",
    addSuccess: "Add new success",
    addFail: "Add new fail",
    requiredImageType: 'The file must be an image file',
  },
};

export default labels;
