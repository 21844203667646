import React from "react";
import i18nLabels from "./News.i18n";
import { useHistory } from "react-router-dom";
import DecorateContainer from "components/DecorateContainer";
import NewsContainer from "./NewsContainer";
import { useDispatch, useSelector } from "react-redux";
import { articleSelector, productSelector, ginsengAdminSelector } from "app/selectors";

export default function News(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const article = useSelector(articleSelector);
  const product = useSelector(productSelector);
  const infoGinseng = useSelector(ginsengAdminSelector)

  const { ginsengInfoByCode = {} } = infoGinseng
  const { articleList = {} } = article;
  const { productInfo = {} } = product;
  const newsProps = {
    ...props,
    dispatch,
    i18nLabels,
    articleList,
    history,
    productInfo,
    ginsengInfoByCode
  };
  return <DecorateContainer {...newsProps} childComponent={NewsContainer} />;
}
