import { Field } from "formik";

function GinsengTopBar({
    intl,
    onClickTopBarStatus
}) {
    return (
        <div className="topbar-container">
            <button className="topbar-option active" onClick={() => onClickTopBarStatus(intl.messages.pending)}>{intl.messages.pending}</button>
            <button className="topbar-option" onClick={() => onClickTopBarStatus(intl.messages.accepted)}>{intl.messages.accepted}</button>
            <button className="topbar-option" onClick={() => onClickTopBarStatus(intl.messages.sold)}>{intl.messages.sold}</button>
            <button className="topbar-option" onClick={() => onClickTopBarStatus(intl.messages.refuse)}> {intl.messages.refuse}</button>
        </div>
    );
}

export default GinsengTopBar;
