import { hideLoading, showLoading } from "components/Loading/LoadingSlice";
import Config from "configuration";
import moment from "moment";
import { CertificateColumns } from "pages/Admin/Component/Table/TableColumnsConfig";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Utils from "utils";
import useTableProperties from "../Component/Table/useTableProperties";
import { deleteGinsengFile, uploadGinsengFile } from "../Ginseng/GinsengSlice";
import propsProvider from "./CertificatePropsProvider";
import {
  deleteCertificateAdmin,
  getListCertificateAdmin,
  insertCertificateAdmin,
  updateCertificateAdmin,
} from "./CertificateSlice";
import CertificateMainView from "./template/CertificateMainView";

function CertificateContainer(props) {
  const { dispatch, certificateAdminList } = props;

  const [keyword, setKeyword] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const intl = useIntl();
  const columns = CertificateColumns(intl);
  const [updateData, setUpdateData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [certificateData, setCertificateData] = useState({});
  const [isAdd, setIsAdd] = useState(true);
  const [filesImage, setFilesImage] = useState(null);
  const [filesCertificate, setFilesCertificate] = useState(null);
  const [imageRemove, setImageRemove] = useState(null);

  const payload = {
    currentPage: currentPage,
    pageSize: pageSize,
    sortField: "created_at",
    sortOrder: "desc",
    keyword: keyword,
  };

  const tableData = useTableProperties(certificateAdminList);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize, keyword]);

  useEffect(() => {
    dispatch(showLoading());
    const delayDebounceFn = setTimeout(() => {
      dispatch(getListCertificateAdmin(payload));
      dispatch(hideLoading());
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [pageSize, keyword, currentPage, dispatch, updateData]);

  const handleAddClick = () => {
    setStateHandle(true, {});
  };

  const handleEditClick = (e) => {
    setStateHandle(false, e);
    setImageRemove(null);
    setFilesImage([e.images]);
  };
  const handleDeleteClick = async (e) => {
    const result = await Utils.handleConfirmDelete(intl);
    if (result.isConfirmed) {
      dispatch(showLoading());
      dispatch(deleteCertificateAdmin(e.data.id)).then((result) => {
        const status = Utils.getValues(result, "payload.status", null);
        if (status === Config.SUCCESS) {
          setUpdateData(!updateData);
          Utils.handlePopup(intl.messages.deleteSuccess, "success");
        } else {
          Utils.handlePopup(intl.messages.deleteFail, "error");
        }
      });
      dispatch(hideLoading());
    }
  };

  const setStateHandle = (isAdd = false, dataCer = {}) => {
    setFilesCertificate(null);
    setFilesImage(null);
    setIsAdd(isAdd);
    setCertificateData(dataCer);
    setOpenDialog(true);
  };

  const handleValidation = (data, isAdd) => {
    if (!data.name.trim()) {
      Utils.handlePopup(intl.messages.requiredName, "error");
      return true;
    }
    if (isAdd) {
      if (!filesCertificate) {
        Utils.handlePopup(intl.messages.requiredFile, "error");
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async (data) => {
    if (handleValidation(data, isAdd)) return;
    dispatch(showLoading());
    let status = Config.FAIL;
    const formData = new FormData();
    const certificates = {
      name: data.name,
      description: data.description,
    };  
    if (isAdd) {
      formData.append("file", filesCertificate);
      const payload = {
        file: formData,
        certifates: JSON.stringify(certificates),
      };
      const result = await dispatch(insertCertificateAdmin(payload));

      status = Utils.getValues(result, "payload.status", Config.FAIL);
    } else {
      formData.append("file", filesCertificate);
      const updateCertificates = {
        id: data.id,
        file: formData,
        certifates: JSON.stringify(certificates),
      };

      const result = await dispatch(updateCertificateAdmin(updateCertificates));
      status = Utils.getValues(result, "payload.status", Config.FAIL);
    }
    if (status === Config.SUCCESS) {
      setUpdateData(!updateData);
      Utils.handlePopup(
        isAdd ? intl.messages.addSuccess : intl.messages.updateSuccess,
        "success"
      );
    } else {
      Utils.handlePopup(
        isAdd ? intl.messages.addFail : intl.messages.updateFail,
        "error"
      );
    }
    dispatch(hideLoading());
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const initialValues = {
    created_at: Utils.formatDateYDM(moment()),
    description: "",
    file_path: intl.messages.chooseFile,
    avatar: intl.messages.chooseFile,
    name: "",
  };

  const onChangeAvatar = (e) => {
    const text = document.getElementById("avatar-text");
    if (e.target.files.length > 0) {
      text.innerHTML = e.target.files[0].name;
      text.style.color = "#0288d1";
      setImageRemove(filesImage);
      setFilesImage(e.target.files[0]);
    } else {
      text.innerHTML = intl.messages.chooseFile;
      text.style.color = "#9ea4aa";
    }
  };

  const onChangeFileCer = (e) => {
    const text = document.getElementById("file-text");
    if (e.target.files.length > 0) {
      if (e.target.files[0].type.split('/')[1] === 'pdf') {
        text.innerHTML = e.target.files[0].name;
        text.style.color = "#0288d1";
        setFilesCertificate(e.target.files[0]);
      } else {
        Utils.handlePopup(
          intl.messages.fileCertificateFail,
          'error',
        )
      }
      
    } else {
      text.innerHTML = intl.messages.chooseFile;
      text.style.color = "#9ea4aa";
    }
  };

  const certificateContainerProps = {
    ...props,
    intl,
    keyword,
    setKeyword,
    handleEditClick,
    handleDeleteClick,
    openDialog,
    certificateData,
    isAdd,
    handleAddClick,
    handleSubmit,
    initialValues,
    onChangeAvatar,
    onChangeFileCer,
    handleCloseDialog,
    certificateAdminList,
    columns,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  };
  return <CertificateMainView {...propsProvider(certificateContainerProps)} />;
}

export default CertificateContainer;
