import { AddIcon, ClearIcon } from "assets/icon";
import DialogModal from "components/DialogModal";
import { Field } from "formik";
import Utils from "utils";

function GinsengModal({
    openDialog,
    handleCloseDialog,
    intl,
    handleSubmit,
    initialValues,
    data,
    isEdit,
    validationSchema,
    filesImage,
    handleRemoveImage,
    handleOnchangeImage,
    handleImage,
}) {
    return (
        <DialogModal
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            handleSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={data ?? initialValues}
            headerTitle={
                isEdit === undefined ? intl.messages.signApprove : isEdit ? intl.messages.updateGinseng : intl.messages.describeGinseng
            }
            submitName={isEdit ? intl.messages.update : intl.messages.approve}
            cancelName={intl.messages.cancel}
            isShowButton={isEdit}
        >
            <div className="sub-header">{intl.messages.generalInformation}</div>
            <div style={{ display: "flex" }}>
                <div
                    className="input-container"
                    style={{ marginRight: "16px" }}
                >
                    <Field
                        className="input-container__input"
                        id="code"
                        name="code"
                        type="text"
                        disabled={!isEdit ? true : false}
                        placeholder={intl.messages.inputCode}
                    />
                    <label htmlFor="code" className="input-container__title required">
                        {intl.messages.ginId}
                    </label>
                </div>
                <div className="input-container" style={{ marginRight: "16px" }}>
                    <Field
                        className="input-container__input"
                        id="name"
                        name="name"
                        type="text"
                        disabled={!isEdit ? true : false}
                        placeholder={intl.messages.inputName}
                    />
                    <label htmlFor="name" className="input-container__title required">
                        {intl.messages.ginName}
                    </label>
                </div>
                <div className="input-container">
                    <Field
                        className="input-container__input"
                        id="ginseng_type"
                        name="ginseng_type"
                        type="text"
                        disabled={!isEdit ? true : false}
                        placeholder={intl.messages.categoryGin}
                    />
                    <label htmlFor="ginseng_type" className="input-container__title required">
                        {intl.messages.categoryGin}
                    </label>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="input-container" style={{ marginRight: '15px' }}>
                    <label className="link-label">
                        <Field name="created_at" htmlFor="created_at" >
                            {({ field }) => (
                                <>
                                    <span className="date-icon" />
                                    <span className="silver-text">{Utils.formatDateYDM(field.value)}</span>
                                    <input className="input-container__input"
                                        id="created_at"
                                        type="date"
                                        disabled={!isEdit ? true : false}
                                        style={{ display: "none" }} />
                                </>
                            )}
                        </Field>
                    </label>
                    <label
                        htmlFor="created_at"
                        className="input-container__title required"
                    >
                        {intl.messages.dateCreated}
                    </label>
                </div>
                <div className="input-container" style={{ marginRight: '15px' }}>
                    <Field
                        className="input-container__input"
                        id="years_of_ginseng"
                        name="years_of_ginseng"
                        type="text"
                        disabled={!isEdit ? true : false}
                        placeholder={intl.messages.yearOfGinseng}
                    />
                    <label htmlFor="name" className="input-container__title required">
                        {intl.messages.yearOfGinseng}
                    </label>
                </div>
                <div className="input-container">
                    <label className="link-label">
                        <Field name="harvest_date" htmlFor="harvest_date" >
                            {({ field }) => (
                                <>
                                    <span className="date-icon" />
                                    <span className="silver-text">{Utils.formatDateYDM(field.value)}</span>
                                    <input className="input-container__input"
                                        id="harvest_date"
                                        type="date"
                                        style={{ display: "none" }} />
                                </>
                            )}
                        </Field>
                    </label>
                    <label
                        htmlFor="harvest_date"
                        className="input-container__title required"
                    >
                        {intl.messages.harvestDate}
                    </label>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    className="input-container">
                    <Field
                        id="source"
                        name="source"
                        type="text"
                        className="input-container__input"
                        disabled={!isEdit ? true : false}
                        placeholder={intl.messages.inputSource}
                    />
                    <label htmlFor="source" className="input-container__title required">
                        {intl.messages.source}
                    </label>
                </div>
                <div
                    className="input-container"
                    style={{ marginLeft: '15px', marginRight: '15px' }}
                >
                    <Field
                        id="weight"
                        name="weight"
                        type="text"
                        className="input-container__input"
                        disabled={!isEdit ? true : false}
                        placeholder={intl.messages.inputSource}
                    />
                    <label htmlFor="source" className="input-container__title required">
                        {intl.messages.weight}
                    </label>
                </div>
                <div
                    className="input-container"
                >
                    <Field
                        id="size"
                        name="size"
                        type="text"
                        disabled={!isEdit ? true : false}
                        className="input-container__input"
                        placeholder={intl.messages.inputSource}
                    />
                    <label htmlFor="size" className="input-container__title required">
                        {intl.messages.size}
                    </label>
                </div>
            </div>
            <div className="sub-header">{intl.messages.generalDetail}</div>
            <div style={{ display: "flex" }}>
                <div className="input-container" style={{ marginRight: "16px" }}>
                    <Field
                        className="input-container__input"
                        id="use_of_ginseng"
                        name="use_of_ginseng"
                        type="text"
                        disabled={!isEdit ? true : false}
                        placeholder={intl.messages.inputEffect}
                    />
                    <label
                        htmlFor="use_of_ginseng"
                        className="input-container__title required"
                    >
                        {intl.messages.useOfGinseng}
                    </label>
                </div>
                <div className="input-container">
                    <Field
                        className="input-container__input"
                        id="other_information"
                        name="other_information"
                        type="text"
                        disabled={!isEdit ? true : false}
                        placeholder={intl.messages.inputAnotherInformation}
                    />
                    <label htmlFor="other_information" className="input-container__title">
                        {intl.messages.AnotherInformation}
                    </label>
                </div>
            </div>
            <div className="card-container resize-height" style={{ width: '100%' }}>
                <div style={{ display: "flex" }}>
                    {filesImage.map((item, index) => {
                        return (
                            <div style={{ position: "relative" }} key={`image-${index}`}>
                                {isEdit &&
                                    <div
                                        className="remove-image-btn"
                                        onClick={() => handleRemoveImage(item)}
                                    >
                                        <ClearIcon />
                                    </div>
                                }
                                <img
                                    className="card-container__image-preview"
                                    src={handleImage(item)}
                                    alt={'error'}
                                />
                            </div>
                        );
                    })}
                    {filesImage.length < 5 && 
                        isEdit && (
                        <div
                            className="upload-box"
                            onClick={() => document.getElementById("getFiles").click()}
                            disabled={!isEdit ? true : false}
                        >
                            <AddIcon />
                        </div>
                    )}
                </div>
                <input
                    accept="image/jpeg, image/png, image/jpg"
                    id="getFiles"
                    type="file"
                    style={{ display: "none", marginTop: '10px' }}
                    onChange={handleOnchangeImage}
                />
                <label
                    className={`card-container__title ${filesImage.length < 1 && `required`}`}
                    style={{ marginBottom: "0px", color: "#3b927d" }}
                >
                    {intl.messages.avatarGinseng}
                </label>
            </div>
        </DialogModal>
    );
}

export default GinsengModal;
