import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Edit,
  MoreVert,
  IconDraw,
  VisibilityOnBlack,
  IconHistory,
} from "assets/icon";
import { ListItemIcon } from "@mui/material";

const ITEM_HEIGHT = 48;

export default function MenuOption({ intl, onHandleToggleSignalModal, handleEditClick, item, onHandleToggleDescriptionModal }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const signOption = {
      name: intl.messages.signForApproval,
      onClick: () => {
        onHandleToggleSignalModal(item);
        handleClose();
      },
      icon: <IconDraw />,
  }
  const options = [
    {
      name: intl.messages.edit,
      onClick: (e) => { handleEditClick(item); handleClose(); },
      icon: <Edit />,
    },
    {
      name: intl.messages.showDescription,
      onClick: () => { onHandleToggleDescriptionModal(item); handleClose(); },
      icon: <VisibilityOnBlack />,
    },
    {
      name: intl.messages.showHistory,
      onClick: () => { },
      icon: <IconHistory />,
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        { item.qr_code &&
            item.qr_code.status.code === 'INAPPROVED' &&
              <MenuItem
                selected={signOption === "Pyxis"}
                onClick={signOption.onClick}
              >
                <ListItemIcon>{signOption.icon}</ListItemIcon>
                {signOption.name}
              </MenuItem>
        }
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={option === "Pyxis"}
            onClick={option.onClick}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
