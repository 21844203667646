import DecorateContainer from 'components/DecorateContainer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './AdminPage.i18n';
import AdminPageContainer from './AdminPageContainer';

export default function AdminPage(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const adminPageProps = {
        ...props,
        dispatch,
        i18nLabels,
        history,
    };
    return (
        <DecorateContainer
            {...adminPageProps}
            childComponent={AdminPageContainer}
        />
    );
}
