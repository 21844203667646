import axios from "axios";
import queryString from "query-string";
import Config from "configuration";

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
  baseURL: Config.apiConfig.endPoint,
  headers: {
    "content-type": "application/json",
    //   Authorization:
    //     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNTAxOTM1LTk1NDMtNGFjNS04YWJmLWViOGM2M2YxNjg3YSIsImlhdCI6MTcxMzc3OTg5MCwiZXhwIjoxNzEzODY5ODkwfQ.r_6-s8ZSDCMd05PvZlfJ5NwRzBMmLvUFZUQtgcScOfQ",
    // },
  },
  timeout: 10000,
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    // Handle errors
    throw error;
  }
);

export default axiosClient;
