import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateNewPw } from 'api/AdminApi';

export const submitVerfificationCode = createAsyncThunk(
    'verifitionCode/verifitionCodeToken',
    async (params) => {
        const res = await CreateNewPw.submitVerifyCode(params);
        return res;
    },
);

const verifiedCode = createSlice({
    name: 'verifiedCode',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitVerfificationCode.fulfilled, (state, action) => {
                state.signInAdmin = action.payload.responseData;
            })
            .addCase(submitVerfificationCode.rejected, (state, action) => {});
    },
});

const { reducer } = verifiedCode;
export default reducer;
