import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import Utils from 'utils';
import propsProvider from './SignInPropsProvider';
import { getAccessToken } from './signInSlice';
import SignInMainView from './template/SignInMainView';

export default function DefaultContainer(props) {
    const { dispatch, history } = props;
    const [errorMessage, setErrorMessage] = useState({ email: '', password: '' });
    const intl = useIntl();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
    });

    const onForgetPwClick = () => {
        history.push(pagePath.forgetPwUrl);
    };

    const onSubmitClick = () => {
        if (!formik.values.email && !formik.values.password) {
            setErrorMessage({
                email: intl.messages.EmailNotBlank,
                password: intl.messages.PwNotBlank,
            });
            return;
        }
        if (!formik.values.email) {
            setErrorMessage({ email: intl.messages.EmailNotBlank });
            return;
        }
        if (!/\S+@\S+\.\S+/.test(formik.values.email)) {
            setErrorMessage({ email: intl.messages.InvalidEmail });
            return;
        }

        if (formik.values.password.length < 6) {
            setErrorMessage({ password: intl.messages.PwMinReq });
            return;
        }
        if (!formik.values.password) {
            setErrorMessage({ password: intl.messages.PwNotBlank });
            return;
        }

        dispatch(getAccessToken(formik.values)).then((result) => {
            if (result.payload === Config.INVALIDPW) {
                setErrorMessage({ password: intl.messages.InvalidPw });
                return;
            }
            if (result.payload === Config.INVALIDEMAIL) {
                setErrorMessage({ email: intl.messages.InvalidEmail });
                return;
            }

            const status = Utils.getValues(result, 'payload.status', null);
            if (status === Config.SUCCESS) {
                const cookies = new Cookies();
                cookies.set(
                    Config.storageKey.signInAdmin,
                    result.payload.responseData.accessToken,
                    {
                        maxAge: result.payload.responseData.expiresIn,
                        secure: true,
                    },
                );
                history.push(pagePath.ginsengAdminUrl);
            }
        });
    };

    const signinContainerProps = {
        ...props,
        intl,
        formik,
        errorMessage,
        onForgetPwClick,
        onSubmitClick,
    };
    return <SignInMainView {...propsProvider(signinContainerProps)} />;
}
