import React from "react";
import Header from "components/Header";
import PaperView from "./subViews/PaperView";
import { useHistory } from "react-router-dom";
import Utils from "utils";

export default function CertificatesMainView(props) {
  const history = useHistory();
  const { intl, certificateList, productInfo, ginsengInfoByCode } = props;
  const navigateTo = (id) => {
    history.push(`/certificates/${id}`);
  };
  return (
    <div className="certificates-container">
      <Header
        headerTitle={intl.messages.Certificates}
        isWhite={false}
        onPress={() => Utils.onBackProductDiscovery(history, ginsengInfoByCode?.qr_code?.value)}
      />
      <div className="certificates-content">
        {certificateList.map((el, index) => (
          <div>
          <PaperView
            navigate={navigateTo}
            id={el.id}
            name={el.name}
            year={el.certificate_year}
            img={el.images[0].file_path}
            key={index}
          />
          </div>
        ))}
      </div>
    </div>
  );
}
