import { ForwardIcon, LocationIcon } from "assets/icon";
import Utils from "utils";

function CardViewAddress({ address, showAddressInMap }) {
  return (
    <>
      {(address.value || []).map((subAddress, index) => (
        <div className="address-card" key={`subAddress-${index}`}>
          <div className="address-item">
            <div className="address-item__left-icon">
              <LocationIcon />
            </div>
            <div className="address-item__content">
              <span className="address-location">
                {Utils.getLastSplit(subAddress.address, ',')}: ({subAddress.description})
              </span>
              <span className="address-street">
                {subAddress.address}
              </span>
            </div>
            <div className="address-item__right-icon">
              <ForwardIcon
                onClick={() =>
                  showAddressInMap(subAddress.address)
                }
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default CardViewAddress;
