import { ginsengAdminSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './Ginseng.i18n';
import GinsengContainer from './GinsengContainer';
export default function Ginseng(props) {
    const history = useHistory();
    const ginseng = useSelector(ginsengAdminSelector);
    const { ginsengAdminList = [] } = ginseng;
    const dispatch = useDispatch();

    const ginsengProps = {
        ...props,
        dispatch,
        i18nLabels,
        history,
        ginsengAdminList,
    };
    return <DecorateContainer {...ginsengProps} childComponent={GinsengContainer} />;
}
