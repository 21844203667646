import Header from "components/Header";
import React from "react";
import CardViewAddress from "./subViews/CardViewAddress";
import Utils from "utils";
function AddressMainView(props) {
  const { intl, addressList, showAddressInMap, history, productInfo, ginsengInfoByCode } = props;
  return (
    <div className="address-container">
      <Header
        headerTitle={intl.messages.titleAddress}
        isWhite={false}
        onPress={() => Utils.onBackProductDiscovery(history, ginsengInfoByCode?.qr_code?.value)}
      />
      <div className="address-content">
        {(addressList || []).map((address, index) => (
          <CardViewAddress
            key={`cardAddress-${index}`}
            address={address}
            showAddressInMap={showAddressInMap}
          />
        ))}
      </div>
    </div>
  );
}

export default AddressMainView;
