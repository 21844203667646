const labels = {
  vi: {
    Certificates: "Giấy chứng nhận liên quan",
    Download: "Tải về",
    Downloading:"Đang tải..."
  },
  en: {
    Certificates: "Certificates",
    Download: "Download",
    Downloading:"Downloading..."

  },
};

export default labels;
