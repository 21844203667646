export default function propsProvider(props) {
  /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
  const { intl, handleScan, handScanError } = props;
  return {
    intl,
    handleScan,
    handScanError,
  };
}
