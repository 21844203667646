const labels = {
  vi: {
    Effect: "Tác dụng",

  },
  en: {
    Effect: "The Effect"
  },
};

export default labels;
