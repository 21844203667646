export default function propsProvider(props) {
    /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
    const {
        intl,
        keyword,
        setKeyword,
        handleEditClick,
        handleDeleteClick,
        openDialog,
        handleAddClick,
        handleCloseDialog,
        isAdd,
        tableData,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        handleSubmit,
        initialValues,
        columns,
        filesImage,
        selectStyle, 
        selectOptions, 
        handleSelectUnit, 
        productData,
        openDialogQR,
        showQrImage,
        handleSelectGinseng,
        qrCodeBase64,
        handleCloseDialogQR,
        unit,
        filesExcel,
        handleOnChangeExcel,
        handleExcel,
        handleRemoveExcel,
    } = props;
    return {
        intl,
        keyword,
        setKeyword,
        handleEditClick,
        handleDeleteClick,
        openDialog,
        handleAddClick,
        handleCloseDialog,
        isAdd,
        tableData,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        handleSubmit,
        initialValues,
        columns,
        filesImage,
        selectStyle, 
        selectOptions, 
        handleSelectUnit, 
        productData,
        showQrImage,
        openDialogQR,
        qrCodeBase64,
        handleCloseDialogQR,
        handleSelectGinseng,
        unit,
        filesExcel,
        handleOnChangeExcel,
        handleExcel,
        handleRemoveExcel
    };
}
