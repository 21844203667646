import React from "react";
import Footer from "components/Footer";
import { AddIcon, ClearIcon } from "assets/icon";
export default function InCorrectComponent(props) {
  const {
    intl,
    productScan,
    history,
    formik,
    handleRemoveImage,
    handleOnchangeIncorrect,
    filesImage,
  } = props;

  return (
    <Footer headerTitle={intl.messages.MoreInformation} isBold>
      <div className="product-incorrect-container">
        <span className="product-incorrect-container__subtitle">
          {intl.messages.ProductNotMath}
        </span>
        <hr className="product-line" />
        <span className="product-name">{productScan?.name}</span>
        <br />
        <span className="product-name product-unit">
          {productScan?.unit_value}
          {productScan?.unit?.unit}
        </span>
        <hr className="product-line" />
        <form onSubmit={formik.handleSubmit}>
          <div className={"card-container"}>
            <input
              id="place"
              value={formik.values.place}
              onChange={formik.handleChange}
              className="card-container__input"
              required
              placeholder={intl.messages.InputPlaceBuy}
              type="text"
            />
            <label className={"card-container__title required"}>
              {intl.messages.PlaceBuy}
            </label>
          </div>
          <div className={"card-container"}>
            <input
              id="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className="card-container__input"
              required
              placeholder={intl.messages.InputAddressBuy}
              type="text"
            />
            <label className={"card-container__title required"}>
              {intl.messages.AddressBuy}
            </label>
          </div>
          <div className="card-container resize-height">
            <div style={{ display: "flex" }}>
              {filesImage.map((item, index) => {
                return (
                  <div style={{ position: "relative" }} key={`image-${index}`}>
                    <div
                      className="remove-image-btn"
                      onClick={() => handleRemoveImage(item)}
                    >
                      <ClearIcon />
                    </div>
                    <img
                      className="card-container__image-preview"
                      src={item}
                      alt={item}
                    />
                  </div>
                );
              })}
              {filesImage.length < 5 && (
                <div
                  className="upload-box"
                  onClick={() => document.getElementById("getFiles").click()}
                >
                  <AddIcon />
                </div>
              )}
            </div>
            <input
              accept="image/jpeg, image/png, image/jpg"
              id="getFiles"
              type="file"
              style={{ display: "none" }}
              onChange={handleOnchangeIncorrect}
            />
            <label
              className="card-container__title required"
              style={{ marginBottom: "0px" }}
            >
              {intl.messages.Image}
            </label>
          </div>
          <div className={"card-container"}>
            <input
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="card-container__input"
              required
              placeholder={intl.messages.InputYourEmail}
              type="email"
            />
            <label className={"card-container__title required"}>
              {intl.messages.YourEmail}
            </label>
          </div>
          <div className={"card-container"}>
            <input
              id="note"
              value={formik.values.note}
              onChange={formik.handleChange}
              className="card-container__input"
              placeholder={intl.messages.InputNoteIfHave}
              type="text"
            />
            <label className={"card-container__title"}>
              {intl.messages.NoteIfHave}
            </label>
          </div>
          <div
            className="product-btn-div"
            style={{
              position: "relative",
              top: "10px",
              marginBottom: "15px",
            }}
          >
            <button
              title={intl.messages.Wrong}
              className="product-btn wrong-btn"
              onClick={() => history.push("/scan")}
            >
              {intl.messages.Back}
            </button>
            <button
              title={intl.messages.Correct}
              className="product-btn correct-btn"
              type="submit"
            >
              {intl.messages.SendReport}
            </button>
          </div>
        </form>
      </div>
    </Footer>
  );
}
