import { ImageAsset } from 'assets/images';
import Countdown, { zeroPad } from 'react-countdown';
import SignInForm from '../../Component/SignInForm/index';

const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return (
            <span>
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    } else {
        // Render a countdown
        return (
            <span>
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    }
};

export default function SuccessMainView(props) {
    const { intl, countdown, onComplete } = props;
    return (
        <SignInForm formTitle={intl.messages.SuccessTitle} smallPadding>
            <div className='inner-frame'>
                <img src={ImageAsset} alt='' />
                <p>
                    {intl.messages.Message}
                    <Countdown
                        date={Date.now() + countdown * 1000}
                        renderer={renderer}
                        onComplete={onComplete}
                    />
                </p>
            </div>
        </SignInForm>
    );
}
