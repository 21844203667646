const labels = {
  vi: {
    rowCount: "Số dòng trên trang: ",
    no: "STT",
    action: "Thao tác",
    edit: "Chỉnh sửa",
    signForApproval: "Ký duyệt",
    showDescription: "Xem chi tiết",
    showHistory: "Xem lịch sử",
  },
  en: {
    rowCount: "Line number on page: ",
    no: "No",
    action: "Actions",
    edit: "Edit",
    signForApproval: "Sign for approval",
    showDescription: "Show description",
    showHistory: "Show history",
  },
};

export default labels;
