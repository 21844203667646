import axios from "axios";
import history from "components/Redirect/useRedirect";
import Config from "configuration";
import { pagePath } from "configuration/routeConfig";
import queryString from "query-string";
import Utils from "utils";

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
  baseURL: Config.endPointAdmin,
  headers: {
    "content-type": "application/json",  
  },
  timeout: 10000,
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  const jwt = Utils.getAccessToken();
  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  } else {
    config.headers.Authorization =
      "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNTAxOTM1LTk1NDMtNGFjNS04YWJmLWViOGM2M2YxNjg3YSIsImlhdCI6MTcxMzg2NTQxNywiZXhwIjoxNzEzOTU1NDE3fQ.TLEjHxlZ9cK4eboMXL9zFPC5XFUH9h61OeZeZgeeDxE";
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // history.push(pagePath.signInUrl);
    }
    throw error;
  }
);

export default axiosClient;
