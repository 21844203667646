const labels = {
    vi: {
        SuccessTitle: 'Thiết lập lại mật khẩu thành công!',
        Message: 'Hệ thống sẽ tự động chuyển sang màn hình đăng nhập sau ',
    },
    en: {
        SuccessTitle: 'Reset password was successful',
        Message:
            'The system will automatically transition to the login screen after ',
    },
};

export default labels;
