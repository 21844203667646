import AdminPage from 'pages/Admin/Component/HeaderSidebar';
import Table from 'pages/Admin/Component/Table';
import CertificateModal from './subViews/CertificateModal';

function CertificateMainView(props) {
    const {
        intl,
        handleEditClick,
        handleDeleteClick,
        keyword,
        setKeyword,
        openDialog,
        certificateData,
        isAdd,
        handleAddClick,
        handleSubmit,
        initialValues,
        onChangeAvatar,
        onChangeFileCer,
        handleCloseDialog,
        columns,
        pageSize,
        setPageSize,
        tableData,
        setCurrentPage,
        currentPage,
    } = props;
    return (
        <AdminPage
            header={intl.messages.tableTitle}
            subHeader={`${tableData.count} ${intl.messages.unit}`}
            userName='Admin'
            handleAddClick={handleAddClick}
            onChangeSearch={(e) => setKeyword(e)}
        >
            <Table
                columns={columns}
                data={tableData.data}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                keyword={keyword}
                pageSize={pageSize}
                currentPage={currentPage}
                handleChangePageSize={(e) => setPageSize(e)}
                totalPage={tableData.total}
                handleChangeCurrentPage={(e) => setCurrentPage(e)}
            />
            <CertificateModal
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                intl={intl}
                isAdd={isAdd}
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                data={certificateData}
                onChangeAvatar={onChangeAvatar}
                onChangeFileCer={onChangeFileCer}
            />
        </AdminPage>
    );
}

export default CertificateMainView;
