import React from "react";
import { BackWhiteIcon, BackGreenIcon } from "assets/icon";
export default function Header(props) {
  const { headerTitle, isWhite = true, onPress } = props;
  return (
    <div className="header-container">
      <div style={{ position: "absolute" }} onClick={onPress}>
        {isWhite ? <BackWhiteIcon /> : <BackGreenIcon />}
      </div>
      {headerTitle && (
        <p
          className={
            isWhite
              ? "header-container__title"
              : "header-container__title header-textGreen"
          }
        >
          {headerTitle}
          <span
            className={
              isWhite
                ? "header-container__underline"
                : "header-container__underline header-underLineGreen"
            }
          />
        </p>
      )}
    </div>
  );
}
