import { useIntl } from "react-intl";
import propsProvider from "./AddressPropsProvider";
import AddressMainView from "./template/AddressMainView";
function AddressContainer(props) {
  const { addressList, history, productInfo } = props;
  const intl = useIntl();

  const showAddressInMap = (address) => {
    window.open(`https://www.google.de/maps/search/${address}`);
  };

  const addressContainerProps = {
    ...props,
    intl,
    addressList,
    showAddressInMap,
    history,
    productInfo,
  };
  return <AddressMainView {...propsProvider(addressContainerProps)} />;
}

export default AddressContainer;
