import { hideLoading, showLoading } from 'components/Loading/LoadingSlice';
import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Utils, { ForgetPwProcess } from 'utils';
import propsProvider from './ForgetPwPropsProvider';
import { changeNextProcess, setEmail, submitEmail } from './forgetPwSlice';
import ForgetPwMainView from './template/ForgetPwMainView';

export default function ForgetPwContainer(props) {
    const { dispatch, history } = props;
    const [errorMessage, setErrorMessage] = useState({ email: '' });
    const intl = useIntl();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
    });

    const onSubmitClick = () => {
        if (!formik.values.email && !formik.values.password) {
            setErrorMessage({
                email: intl.messages.EmailNotBlank,
            });
            return;
        }
        if (!formik.values.email) {
            setErrorMessage({ email: intl.messages.EmailNotBlank });
            return;
        }
        if (!/\S+@\S+\.\S+/.test(formik.values.email)) {
            setErrorMessage({ email: intl.messages.InvalidEmail });
            return;
        }

        const delayDebounceFn = setTimeout(async () => {
            dispatch(showLoading());
            dispatch(submitEmail(formik.values)).then((result) => {
                const status = Utils.getValues(result, 'payload.status', null);
                if (status === Config.FAIL) {
                    setErrorMessage({ email: intl.messages.InvalidEmail });
                    dispatch(hideLoading());
                    return;
                }

                if (status === Config.SUCCESS) {
                    dispatch(setEmail(formik.values.email));
                    dispatch(changeNextProcess(ForgetPwProcess.VerifiedCode));
                    dispatch(hideLoading());
                    history.push(pagePath.veriviedCodePwUrl);
                }
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    };

    const forgetPwPropsProvider = {
        ...props,
        intl,
        formik,
        errorMessage,
        onSubmitClick,
    };
    return <ForgetPwMainView {...propsProvider(forgetPwPropsProvider)} />;
}
