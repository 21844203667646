import { EmptyScanIcon } from "assets/icon";
import Header from "components/Header";

function EmptyMainView(props) {
  const { onScanOtherCode, intl } = props;
  return (
    <div className="empty-container">
      <Header headerTitle={intl.messages.productInfo} onPress={onScanOtherCode} />
      <div className="empty-content">
        <span>{intl.messages.notFound}</span>
        <span>{intl.messages.tryAgain}</span>
        <div className="empty-icon">
          <EmptyScanIcon />
        </div>
        <div className="empty-content__shape" />
      </div>
      <div className="empty-footer">
        <button
          type="button"
          className="empty-footer__btn"
          onClick={onScanOtherCode}
        >
          {intl.messages.scanOther}
        </button>
      </div>
    </div>
  );
}

export default EmptyMainView;
