import { hideLoading, showLoading } from 'components/Loading/LoadingSlice';
import { getQrCode } from 'pages/Admin/Product/ProductSlice';
import { useState } from 'react';
import Utils from 'utils';
import QrShowBoxMainView from './QrShowBoxMainView';
import propsProvider from './QrShowBoxPropsProvider';

export default function QrShowBoxContainer(props) {
    const { dispatch } = props;
    const [qrCodeBase64, setQrCodeBase64] = useState('');
    const [openDialogQR, setOpenDialogQR] = useState(false);

    const showQrImage = async (code) => {
        let base64;
        dispatch(showLoading());
        const delayDebounceFn = setTimeout(async () => {
            const result = await dispatch(getQrCode(code));
            base64 = Utils.getValues(result, 'payload.responseData', '');
            setQrCodeBase64(base64);
            setOpenDialogQR(true);
            dispatch(hideLoading());
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    };
    const handleCloseDialogQR = () => {
        setOpenDialogQR(false);
    };

    const handleShowQr = (e) => {
        showQrImage(e);
    };

    const containerProps = {
        ...props,
        openDialogQR,
        qrCodeBase64,
        handleShowQr,
        showQrImage,
        handleCloseDialogQR,
    };

    return <QrShowBoxMainView {...propsProvider(containerProps)} />;
}
