export default function propsProvider(props) {
  /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
  const {
    handleButtonClick,
    productI18n,
    intl,
    otp,
    setOtp,
    content,
    switchTab,
    productData,
    productInfo,
    onPressDispatch,
    onSlideChange,
    progressPercent,
    onPressBack,
    formik,
    handleRemoveImage,
    handleOnchangeIncorrect,
    filesImage,
    anotherInfo,
    windowHeight,
    ginsengInfoByCode
  } = props;
  return {
    handleButtonClick,
    productI18n,
    intl,
    otp,
    setOtp,
    content,
    switchTab,
    productData,
    productInfo,
    onPressDispatch,
    onSlideChange,
    progressPercent,
    onPressBack,
    formik,
    handleRemoveImage,
    handleOnchangeIncorrect,
    filesImage,
    anotherInfo,
    windowHeight,
    ginsengInfoByCode
  };
}
