import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productCertificates } from "api/productApi";
import Config from "configuration";
const initialCertificateList = () => {
  if (sessionStorage.getItem(Config.storageKey.certificate)) {
    return {
      ...JSON.parse(sessionStorage.getItem(Config.storageKey.certificate)),
    };
  }
  return {
    certificateList: [],
  };
};

export const getListCertificateByProductId = createAsyncThunk(
  "product/listCertificate",
  async (params) => {
    const res = await productCertificates.getCertificates(params);
    return res;
  }
);

const certificate = createSlice({
  name: "certificate",
  initialState: initialCertificateList(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListCertificateByProductId.fulfilled, (state, action) => {
        state.certificateList = action.payload.responseData;
      })
      .addCase(getListCertificateByProductId.rejected, (state, action) => {});
  },
});

const { reducer } = certificate;
export default reducer;
