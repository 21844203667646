export default function propsProvider(props) {
    /*
          - To only list utilized props
          - Wrapping utilized props on each view
      */
    const { intl, onSubmitClick, formik, errorMessage } = props;
    return {
        intl,
        onSubmitClick,
        formik,
        errorMessage,
    };
}
