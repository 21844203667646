import React from "react";
import Header from "components/Header";
import CardViewNews from "./subViews/CardViewNews";
import Utils from "utils";
function NewsMainView(props) {
  const { intl, articleList, openInNewTab, history, productInfo, ginsengInfoByCode } = props;
  return (
    <div className="new-container">
      <Header
        headerTitle={intl.messages.news}
        isWhite={false}
        onPress={() => Utils.onBackProductDiscovery(history, ginsengInfoByCode?.qr_code?.value)}
      />
      <div className="news-content">
        {(articleList || []).map((product, index) => (
          <CardViewNews
            key={`cardNews-${index}`}
            newImage={product.images_path}
            newOfficeName={product.newspaper_office_name}
            newTitle={product.title}
            onClick={() => openInNewTab(product.newspaper_office_name)}
          />
        ))}
      </div>
    </div>
  );
}

export default NewsMainView;
