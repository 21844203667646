import React from "react";

export default function Footer(props) {
  const { headerTitle, children, isBold, style } = props;
  return (
    <div className="footer-container">
      <div className="footer-container__background" style={style}>
        {headerTitle && (
          <span
            className={
              isBold ? "footer-container__titleBold" : "footer-container__title"
            }
          >
            {headerTitle}
          </span>
        )}
        {children}
      </div>
    </div>
  );
}
