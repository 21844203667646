import { configureStore } from '@reduxjs/toolkit';
import addressReducer from 'pages/Address/AddressSlice';
import articleAdminReducer from 'pages/Admin/Article/ArticleSlice';
import certificateAdminReducer from 'pages/Admin/Certificate/CertificateSlice';
import forgetPwReducer from 'pages/Admin/ForgetPw/forgetPwSlice';
import ginsengAdminReducer from 'pages/Admin/Ginseng/GinsengSlice';
import locationAdminReducer from 'pages/Admin/Location/LocationSlice';
import productAdminReducer from 'pages/Admin/Product/ProductSlice';
import resetPwReducer from 'pages/Admin/ResetPw/resetPwSlice';
import signInAdminReducer from 'pages/Admin/SignIn/signInSlice';
import verifiedCodeReducer from 'pages/Admin/VerifiedCode/verifiedCodeSlice';
import certificateReducer from 'pages/Certificates/CertificatesSlice';
import articleReducer from 'pages/News/NewSlice';
import productReducer from 'pages/Scan/ScanSlice';
import loadingReducer from '../components/Loading/LoadingSlice';
import commonReducer from './commonSlice';
import qrCodeReducer from '../pages/Admin/QrCode/QrCodeSlice'

const rootReducer = {
    loading: loadingReducer,
    common: commonReducer,
    product: productReducer,
    article: articleReducer,
    address: addressReducer,
    certificate: certificateReducer,
    certificateAdmin: certificateAdminReducer,
    articleAdmin: articleAdminReducer,
    ginsengAdmin: ginsengAdminReducer,
    productAdmin: productAdminReducer,
    locationAdmin: locationAdminReducer,
    signInAdmin: signInAdminReducer,
    forgetPw: forgetPwReducer,
    verifiedCode: verifiedCodeReducer,
    resetPw: resetPwReducer,
    qrCode: qrCodeReducer
};
const store = configureStore({ reducer: rootReducer });
export default store;
