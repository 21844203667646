import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FileUpload, GinsengAdmin } from 'api/AdminApi';
import { qrCodeApi } from 'api/qrCodeApi';
import Config from 'configuration';
import Utils from 'utils';


export const getListQrCode = createAsyncThunk(
    'qrcode/getListQrCode',
    async (params) => {
        const res = await qrCodeApi.getAllQrCode(params);
        return res;
    },
);

export const addNewQRCode = createAsyncThunk(
    'ginsengAdmin/insertGinsengAdmin',
    async (params) => {
        const res = await qrCodeApi.addNewQRCode(params);
        return res;
    },
);
export const deleteQRCode = createAsyncThunk(
    'ginsengAdmin/deleteQRCode',
    async (params) => {
        const res = await qrCodeApi.delete(params);
        return res;
    },
);
export const updatePrint = createAsyncThunk(
    'ginsengAdmin/updatePrintStatus',
    async (params) => {
        const res = await qrCodeApi.updatePrintStatus(params);
        return res;
    },
);


const ginsengAdmin = createSlice({
    name: 'ginsengAdmin',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListQrCode.fulfilled, (state, action) => {
                const { status } = Utils.getValues(action, 'payload', 'failed');
                if (status === 'success') {
                    const { responseData } = Utils.getValues(action, 'payload', []);
                    state.lstQrCode = responseData
                }
            })

    },
});

const { reducer } = ginsengAdmin;
export default reducer;
