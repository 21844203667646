import { Dialog } from '@mui/material';

export default function QrShowBoxMainView(props) {
    const { data, content, openDialogQR, qrCodeBase64, handleCloseDialogQR, handleShowQr } = props;
    if (data) {
        return (
            <>
                <button onClick={() => handleShowQr(data)}>{content}</button>
                <Dialog
                    open={openDialogQR}
                    PaperProps={{ sx: { borderRadius: '12px' } }}
                    onClose={handleCloseDialogQR}
                >
                    <img height={'400px'} src={qrCodeBase64} alt='qr' />
                </Dialog>
            </>
        );
    }
}
