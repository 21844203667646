export default function propsProvider(props) {
    /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
    const {
        intl,
        handleEditClick,
        handleDeleteClick,
        columns,
        keyword,
        setKeyword,
        openDialog,
        certificateData,
        isAdd,
        handleAddClick,
        handleSubmit,
        initialValues,
        onChangeAvatar,
        onChangeFileCer,
        handleCloseDialog,
        certificateAdminList,
        pageSize,
        setPageSize,
        tableData,
        currentPage,
        setCurrentPage,
        handleChangeCurrentPage,
    } = props;
    return {
        intl,
        handleEditClick,
        handleDeleteClick,
        columns,
        keyword,
        setKeyword,
        openDialog,
        certificateData,
        isAdd,
        handleAddClick,
        handleSubmit,
        initialValues,
        onChangeAvatar,
        onChangeFileCer,
        handleCloseDialog,
        certificateAdminList,
        pageSize,
        setPageSize,
        tableData,
        currentPage,
        setCurrentPage,
        handleChangeCurrentPage,
    };
}
