export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
    const {handleDownload, isDownloading, intl, history, certificateDetail} = props;
    return {
        handleDownload,
        isDownloading,
        intl,
        history,
        certificateDetail
    };
}
