import React from "react";
import Config from "configuration";
export default function PaperView(props) {
  const { navigate, id, name, year, img } = props;

  return (
    <div className="certificates-item" onClick={() => navigate(id)}>
      <div style={{ textAlign: " center", padding: "16px 0" }}>
        <img
          className="certificates-item__image"
          src={Config.Image_Url + img}
          alt={img}
        />
        <hr style={{ border: "1px solid rgba(34, 73, 59, 1)" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0 8px",
          }}
        >
          <span className="certificates-item__name">{name}</span>
          <span className="certificates-item__year">{year}</span>
        </div>
      </div>
    </div>
  );
}
{
  /* <img className="certificates-img" src={Config.Image_Url + img} alt={img} /> */
}
