import propsProvider from "./CertificatesDetailPropsProvider";
import CertificatesDetailMainView from "./template/CertificatesDetailMainView";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import Config from "configuration";
import { useEffect, useState } from "react";

export default function CertificatesDetailContainer(props) {
  const { dispatch, home, certificateList } = props;
  let { id } = useParams();
  const certificateDetail = certificateList.find((el) => el.id === id);
  const [isDownloading, setIsDownloading] = useState(false);
  useEffect(() => {
    if (isDownloading) {
      handleButtonClick()
      downloadFile().then(() => {
        setIsDownloading(false);
      });
    }
  }, [isDownloading]);
  const downloadFile = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };
  const handleButtonClick = async () => {

    try {
      const response = await fetch(
        Config.Image_Url + certificateDetail.file_path
      );
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "cer_gcn1.pdf";
      downloadLink.click();
      window.URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const handleDownload = () => {
    setIsDownloading(true);
  };
  const intl = useIntl();
  const certificatesDetailContainerProps = {
    ...props,
    handleDownload,
    intl,
    certificateDetail,
    isDownloading
  };
  return (
    <CertificatesDetailMainView
      {...propsProvider(certificatesDetailContainerProps)}
    />
  );
}
