import { VisibilityOff, VisibilityOn } from 'assets/icon';
import { useId, useState } from 'react';

export function SignInInput(props) {
    const { placeholder, label, type, message } = props;

    const [values, setValues] = useState({
        password: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    return (
        <div className='validation-input'>
            <div className='input-atom'>
                <div className='placeholder-group'>
                    <input
                        {...props}
                        type={
                            values.showPassword ? 'text' : type !== 'password' ? type : 'password'
                        }
                        placeholder={placeholder}
                    />
                    <label>{label}</label>
                </div>
                {type === 'password' ? (
                    <button type='button' onClick={() => handleClickShowPassword()}>
                        {values.showPassword ? <VisibilityOn /> : <VisibilityOff />}
                    </button>
                ) : (
                    ''
                )}
            </div>
            {message && <span className='error-message'>{message}</span>}
        </div>
    );
}

export function SignInCheckBox(props) {
    const id = useId();
    const { label } = props;
    return (
        <div className='checkbox-group'>
            <label htmlFor={id}>{label}</label>
            <input id={id} type='checkbox' />
        </div>
    );
}
