import { loadingSelector } from "app/selectors";
import { useSelector } from "react-redux";

function Loading() {
    const isLoading = useSelector(loadingSelector).isLoading;
    if (isLoading) {
        return (<div className="bgLoading">
            <span className="loader" />
        </div>);
    }
}

export default Loading;