import React from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import { ImageWine } from "assets/images";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ClearIcon, AddIcon } from "assets/icon";
import { GifSuccess } from "assets/gif";
import Success from "components/Success";
export default function GinsengFormMainView(props) {
    const { intl, onChangeValueForm, filesImage = [], handleImage, handleOnchangeImage,
        handleRemoveImage, ginsengForm, onSubmitForm, resultAddGinseng, onPressBack } = props;

    return (
        <div className="product-container background">
            {resultAddGinseng !== undefined && <Success isSuccess={resultAddGinseng} intl={intl} />}
            <Header
                headerTitle={intl.messages.productInfo
                }
                onPress={onPressBack}
            />
            <div className="product-container__image">
                <img src={ImageWine} className="image" alt={ImageWine} />
            </div>
            <Footer>
                <div className="ginsengForm-footer" >
                    <div className="ginsengForm-footer__header-title">
                        <span>Sâm Hàn Quốc</span>
                    </div>
                    <div className="ginsengForm-footer__image-container">
                        <div style={{ display: 'flex' }}>
                            <span>
                                Hình ảnh sâm (tối đa 5 ảnh)
                            </span>
                            <span style={{ color: '#d32f2f' }}>
                                *
                            </span>
                        </div>
                        <div className="ginsengForm-footer__image-container__addNew">
                            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                {filesImage.map((item, index) => {
                                    return (
                                        <div style={{ position: "relative" }} key={`image-${index}`}>
                                            <div
                                                className="ginsengForm-remove-image-btn"

                                                onClick={() => handleRemoveImage(item)}
                                            >
                                                <ClearIcon />
                                            </div>
                                            <img
                                                className="card-container__image-preview"
                                                src={handleImage(item)}
                                                alt={'error'}
                                            />
                                        </div>
                                    );
                                })}
                                {filesImage.length < 5 && (
                                    <div
                                        className="upload-box"
                                        onClick={() => document.getElementById("getFiles").click()}
                                    >
                                        <AddIcon />
                                    </div>
                                )}
                            </div>
                            <input
                                accept="image/jpeg, image/png, image/jpg"
                                id="getFiles"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleOnchangeImage}
                            />

                        </div>
                    </div>

                    {ginsengForm && ginsengForm.map((item, index) =>
                        <div
                            style={{
                                marginTop: '20px',
                                border: item.isError && item.value.length === 0 ? 'solid 1px #ff0000' : 'solid 1px #e8ebed',
                            }}
                        >
                            <div className="input-field__placeholder">
                                <span >{item.name}</span>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <input className="input-field" type={item.type} name={item.name} placeholder={item.name} onChange={(e) => onChangeValueForm(index, e.target.value)} />
                        </div>
                    )}

                    <div style={{ display: 'flex', marginTop: 16 }}>
                        <input type="submit" value="Hủy" className="ginsengForm-footer__cancel btn" />
                        <input type="submit" value="Lưu" className="ginsengForm-footer__save btn" onClick={onSubmitForm} />
                    </div>
                    <br />
                </div>
            </Footer >

        </div >

    );
}
