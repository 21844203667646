import SignInButton from '../../Component/SignInButton/index';
import SignInForm from '../../Component/SignInForm/index';
import { SignInCheckBox, SignInInput } from '../../Component/SignInput/index';

export default function SignInMainView(props) {
    const { intl, onForgetPwClick, onSubmitClick, formik, errorMessage } = props;

    return (
        <SignInForm formTitle={intl.messages.FormTitle}>
            <form className='form-body'>
                <SignInInput
                    type='email'
                    name='email'
                    label={intl.messages.EmailLabel}
                    placeholder={intl.messages.EmailPlaceholder}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    message={errorMessage.email}
                />
                <SignInInput
                    type='password'
                    name='password'
                    label={intl.messages.PwLabel}
                    placeholder={intl.messages.PwPlaceholder}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    message={errorMessage.password}
                />
                <div className='frame'>
                    <SignInCheckBox label={intl.messages.RemeberSignIn} />
                    <SignInButton
                        variable='text'
                        label={intl.messages.ForgetPw}
                        onClick={onForgetPwClick}
                    />
                </div>
                <SignInButton
                    variable='button'
                    label={intl.messages.SubmitLabel}
                    onClick={onSubmitClick}
                />
            </form>
        </SignInForm>
    );
}
