export default function propsProvider(props) {
  /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
  const {
    intl,
    rowCount,
    handleEditClick,
    handleDeleteClick,
    columns,
    data,
    dataSorted,
    keyword,
    setKeyword,
    globalFilter,
    setGlobalFilter,
    table,
    pageSizeConfig,
    pageSize,
    setPageSize,
    handleChangePageSize,
    totalPage,
    setCurrentPage,
    handleChangeCurrentPage,
    currentPage,
    ginMenu,
    onHandleToggleSignalModal,
    onHandleToggleDescriptionModal,
    showActionColumn,
    showEditButton
  } = props;
  return {
    intl,
    handleEditClick,
    handleDeleteClick,
    columns,
    data,
    dataSorted,
    rowCount,
    keyword,
    setKeyword,
    globalFilter,
    setGlobalFilter,
    table,
    pageSizeConfig,
    pageSize,
    setPageSize,
    handleChangePageSize,
    totalPage,
    setCurrentPage,
    handleChangeCurrentPage,
    currentPage,
    ginMenu,
    onHandleToggleSignalModal,
    onHandleToggleDescriptionModal,
    showActionColumn,
    showEditButton
  };
}
