import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CommercialProductAdmin } from 'api/AdminApi';
import Config from 'configuration';

const initialProductAdminList = () => {
    if (sessionStorage.getItem(Config.storageKey.productAdmin)) {
        return {
            ...JSON.parse(
                sessionStorage.getItem(Config.storageKey.productAdmin),
            ),
        };
    }
    return {
        productAdminList: [],
    };
};

export const getListProductAdmin = createAsyncThunk(
    'commercialproduct/getAll',
    async (params) => {
        const res = await CommercialProductAdmin.getListProduct(params);
        return res;
    },
);

export const getProductAdminById = createAsyncThunk(
    'commercialproduct/getByID',
    async (id) => {
        const res = await CommercialProductAdmin.getProductById(id);
        return res;
    },
);

export const insertProductAdmin = createAsyncThunk(
    'commercialproduct/post',
    async (params) => {
        const res = await CommercialProductAdmin.insertProduct(params);
        return res;
    },
);

export const insertProductAdminByFile = createAsyncThunk(
    'commercialproduct/uploadBarcode',
    async (params) => {
        const res = await CommercialProductAdmin.uploadBarCode(params);
        return res;
    }
)

export const updateProductAdmin = createAsyncThunk(
    'commercialproduct/put',
    async (params) => {
        const res = await CommercialProductAdmin.updateProduct(params);
        return res;
    },
);

export const deleteProductAdmin = createAsyncThunk(
    'commercialproduct/delete',
    async (params) => {
        const res = await CommercialProductAdmin.deleteProduct(params);
        return res;
    },
);

export const getUnit = createAsyncThunk(
    'productAdmin/Unit',
    async () => {
        const res = await CommercialProductAdmin.getUnit();
        return res;
    },
);

export const getQrCode = createAsyncThunk(
    'productAdmin/QrCode',
    async (code) => {
        const res = await CommercialProductAdmin.getQr(code);
        return res;
    },
);

const productAdmin = createSlice({
    name: 'productAdmin',
    initialState: initialProductAdminList(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListProductAdmin.fulfilled, (state, action) => {
                state.productAdminList = action.payload.responseData;
            })
            .addCase(getListProductAdmin.rejected, (state, action) => { });
    },
});

const { reducer } = productAdmin;
export default reducer;
