export default function propsProvider(props) {
  /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
  const {
    intl,
    keyword,
    setKeyword,
    openDialog,
    handleAddClick,
    handleCloseDialog,
    isAdd,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    initialValues,
    columns,
    filesImage,
    handleRemoveImage,
    handleImage,
    handleOnchangeImage,
    selectStyle,
    selectOptions,
    handleSelectGinseng,
    qrCodeEdit,
    openDialogQR,
    handleCloseDialogQR,
    qrCodeBase64,
    unit,
    handleEditClick,
    params,
    onAddQrCode,
    handlePrintClick,
    printRef,
    openAddMoreQrCode,
    handleCloseAddMoreDialogQR,
    onSubmitAddMoreQRCode,
  } = props;
  return {
    ...props,
    intl,
    keyword,
    setKeyword,
    openDialog,
    handleAddClick,
    handleCloseDialog,
    isAdd,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    initialValues,
    columns,
    filesImage,
    handleRemoveImage,
    handleImage,
    handleOnchangeImage,
    selectStyle,
    selectOptions,
    handleSelectGinseng,
    qrCodeEdit,
    openDialogQR,
    handleCloseDialogQR,
    qrCodeBase64,
    unit,
    handleEditClick,
    params,
    onAddQrCode,
    handlePrintClick,
    printRef,
    openAddMoreQrCode,
    handleCloseAddMoreDialogQR,
    onSubmitAddMoreQRCode,
  };
}
