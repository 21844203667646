import Header from "components/Header";
import React from "react";
import { ImageWine } from "assets/images";
export default function ProductMainView(props) {
  const { intl, switchTab, content, onPressBack, anotherInfo } = props;
  return (
    <div className="product-container background">
      <Header
        headerTitle={intl.messages.ProductInfo}
        onPress={onPressBack}
      />
      {content !== anotherInfo && (
        <div className="product-container__image">
          <img src={ImageWine} className="image" alt={ImageWine} />
        </div>
      )}
      {switchTab(content)}
    </div>
  );
}
