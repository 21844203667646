import DialogModal from "components/DialogModal";
import { Field } from "formik";
import Utils from "utils";

function ArticleModal({
  openDialog,
  handleCloseDialog,
  intl,
  handleSubmit,
  initialValues,
  data,
  isAdd,
  onChangeImage,
}) {
  return (
    <DialogModal
      openDialog={openDialog}
      handleCloseDialog={handleCloseDialog}
      handleSubmit={handleSubmit}
      initialValues={
        isAdd
          ? initialValues
          : {
            id: data.id,
            title: data.title,
            created_at: Utils.formatDateYDM(data.created_at),
            avatar: Utils.getLastSplit(data.images.file_path, '/'),
            link: data.newspaper_office_name,
            description: data.description,
            display: `${data.display}`,
          }
      }
      headerTitle={
        isAdd ? intl.messages.createArticle : intl.messages.updateArticle
      }
      submitName={isAdd ? intl.messages.add : intl.messages.update}
      cancelName={intl.messages.cancel}
    >
      <div style={{ display: "flex" }}>
        <div className="input-container" style={{ marginRight: "16px" }}>
          <Field
            className="input-container__input"
            id="title"
            name="title"
            type="text"
            placeholder={intl.messages.inputNameArticle}
          />
          <label htmlFor="title" className="input-container__title required">
            {intl.messages.nameArticle}
          </label>
        </div>
        <div className="input-container">
          <Field
            className="input-container__input"
            name="link"
            id="newspaper_office_name"
            type="url"
            placeholder={intl.messages.inputLink}
          />
          <label
            htmlFor="newspaper_office_name"
            className="input-container__title required"
          >
            {intl.messages.link}
          </label>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="input-container" style={{ marginRight: "16px" }}>
          <Field
            className="input-container__input input-container__title"
            as="select"
            name="display"
          >
            <option value="true">{intl.messages.showing}</option>
            <option value="false">{intl.messages.hidden}</option>
          </Field>
          <label className="input-container__title__green">
            {intl.messages.status}
          </label>
        </div>
        <div className="input-container">
          <label className="link-label">
            <Field name="created_at" htmlFor="avatar" >
              {({ field }) => (
                <>
                  <span className="date-icon" />
                  <span className="silver-text">{field.value}</span>
                  <input className="input-container__input"
                    id="created_at"
                    type="date"
                    style={{ display: "none" }} />
                </>
              )}
            </Field>
          </label>
          <label className="input-container__title">
            {intl.messages.dateCreated}
          </label>
        </div>
      </div>
      <div className="input-container" style={{ width: "49%" }}>
        <label className="link-label" style={{ cursor: 'pointer' }}>
          <Field name="avatar" htmlFor="avatar" >
            {({ field }) => (
              <>
                <span className="link-icon" />
                <span id="avatar-text" className={`${isAdd ? "silver-text" : "blue-text"}`}>{field.value}</span>
                <input className="input-container__input"
                  id="avatar"
                  type="file"
                  accept="image/*"
                  onChange={onChangeImage}
                  style={{ display: "none" }} />
              </>
            )}
          </Field>
        </label>
        <label htmlFor="avatar" className={`input-container__title ${isAdd && `required`}`}>
          {intl.messages.avatar}
        </label>
      </div>
      <div
        className="input-container"
        style={{ minHeight: "100px", justifyContent: "flex-end" }}
      >
        <Field
          className="input-container__textarea"
          name="description"
          type="text"
          placeholder={intl.messages.inputDesc}
        />
        <label
          className="input-container__title"
          style={{ marginBottom: "4px" }}
        >
          {intl.messages.desc}
        </label>
      </div>
    </DialogModal>
  );
}

export default ArticleModal;
