import store from "app/store";
import history from "components/Redirect/useRedirect";
import Config from "configuration";
import { pagePath } from "configuration/routeConfig";
import { MD5 } from "crypto-js";
import moment from "moment/moment";
import { uploadGinsengFile } from "pages/Admin/Ginseng/GinsengSlice";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Buffer } from "buffer";
/**
 * Nested Object or Array data value retriever
 * @param source
 * @param variables {string}
 * @param fallbackValue {any}
 * @param allowNull
 * @returns {any|boolean|string}
 */
const getValues = (
  source,
  variables = "",
  fallbackValue = false,
  allowNull = false
) => {
  const targetValueHierarchy = (variables || "")
    .toString()
    .replace(/[[\]]/g, ".")
    .split(".")
    .filter((key) => key !== "");

  if (source === null && allowNull && targetValueHierarchy.length === 0) {
    return null;
  }

  // Check for string type because string is subtype of Array
  // Don't worry, if the data type not an object or array will fail after that.
  if (!source || ["string, boolean"].includes(typeof source)) {
    return fallbackValue;
  }

  // Retain data type cause data type is dynamic
  let result = Object.assign(source);

  for (let i = 0; i < targetValueHierarchy.length; i++) {
    result = result[targetValueHierarchy[i]];

    if (result === undefined) {
      break;
    }

    if (result === null && i !== targetValueHierarchy.length - 1) {
      result = undefined;
      break;
    }
  }

  if (result === null) {
    return allowNull ? result : fallbackValue;
  }

  return result !== undefined ? result : fallbackValue;
};
const truncateString = (str, num) => {
  if (!str) {
    return str;
  }
  if (str.length > num) {
    return str.slice(0, num) + "...";
  }
  return str;
};

const onBackProductDiscovery = (history, code) => {
  console.log(`onBackProductDiscovery`, code)
  history.push(`/product?code=${code}`, { name: "discovery" });
};

const signatureEnCode = (id, QrCode, SecurityCode) => {
  try {
    const base64Encoded = Buffer.from(
      `${id}|${QrCode}|${SecurityCode}`
    ).toString("base64");
    const mb5encode = MD5(base64Encoded).toString();
    return mb5encode;
  } catch (e) {
    console.log("e", e);
  }
};

const getFirstValueSplit = (string, splitCharacters) => {
  return string ? string.split(splitCharacters)[0] : "";
};

const formatDateYDM = (date) => {
  const dateformat = date || moment();
  return moment(dateformat).format("DD-MM-YYYY");
};

const formatFullName = (name) => {
  let last_name = name?.last_name || "";
  let middle_name = name?.middle_name || "";
  let first_name = name?.first_name || "";

  return `${last_name} ${middle_name} ${first_name}`;
}

const getLastSplit = (string, split, result = "") => {
  if (string) {
    const stringSplit = string.split(split);
    return stringSplit[stringSplit.length - 1];
  }
  return result;
};

const getAccessToken = () => {
  const cookies = new Cookies();
  const jwt = cookies.get(Config.storageKey.signInAdmin);
  if (jwt) {
    return jwt;
  }
  return false;
};

const checkCurrentSignIn = (history) => {
  if (
    getAccessToken() &&
    history.location.pathname.includes(pagePath.signInUrl)
  ) {
    history.push(pagePath.ginsengAdminUrl);
  }
};

const handlePopup = (str, type = "success") => {
  return Swal.fire({
    icon: type,
    text: str,
  });
};

const handleConfirmDelete = (intl) => {
  const result = Swal.fire({
    title: `${intl.messages.ConfirmDeleteTitle}?`,
    text: intl.messages.ConfirmDeleteText,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#22493b",
    cancelButtonColor: "#ff0000",
    confirmButtonText: intl.messages.ConfirmDeleteYes,
    cancelButtonText: intl.messages.ConfirmDeleteNo,
  });
  return result;
};

const processFile = async (dispatch, file) => {
  const formDataImage = new FormData();
  formDataImage.append("file", file);
  const result = await dispatch(uploadGinsengFile(formDataImage));
  const responseData = Utils.getValues(result, "payload.responseData", null);
  return { id: responseData.id, file_path: responseData.file_path };
};

const protectLine = (target) => {
  const state = store.getState();
  if (target === state.forgetPw.nextProcess) {
    return true;
  } else {
    history.push(pagePath.signInUrl);
  }
};
function convertToBoolean(input) {
  try {
    return JSON.parse(input.toLowerCase());
  }
  catch (e) {
    return undefined;
  }
}


const Utils = {
  getValues,
  truncateString,
  signatureEnCode,
  onBackProductDiscovery,
  getFirstValueSplit,
  formatDateYDM,
  formatFullName,
  getLastSplit,
  checkCurrentSignIn,
  handlePopup,
  handleConfirmDelete,
  getAccessToken,
  processFile,
  protectLine,
  convertToBoolean
};

const VerifiedCode = "VerifiedCode";
const CreateNewPw = "CreateNewPw";
const CreateNewPwSuccessfully = "CreateNewPwSuccessfully";
export const ForgetPwProcess = {
  VerifiedCode,
  CreateNewPw,
  CreateNewPwSuccessfully,
};

export default Utils;
