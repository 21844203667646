import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import propsProvider from './TablePropsProvider';
import TableMainView from './template/TableMainView';

function TableContainer(props) {
    const { data, columns } = props;
    const intl = useIntl();
    const pageSizeConfig = [10, 20, 30, 40, 50];

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const containerProps = {
        ...props,
        intl,
        table,
        pageSizeConfig,
    };
    return <TableMainView {...propsProvider(containerProps)} />;
}
export default TableContainer;
