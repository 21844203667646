export default function propsProvider(props) {
  /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
  const {
    intl,
    rowCount,
    handleEditClick,
    handleDeleteClick,
    columns,
    data,
    keyword,
    handleSearch,
    openDialog,
    handleCloseDialog,
    isEdit,
    handleSubmit,
    initialValues,
    ginsengData,
    filesImage,
    handleAddClick,
    handleRemoveImage,
    handleOnchangeImage,
    onChangeFileCertificate,
    pageSize,
    setPageSize,
    tableData,
    currentPage,
    setCurrentPage,
    handleChangeCurrentPage,
    ginsengAdminList,
    handleImage,
    onClickTopBarStatus,
    onHandleToggleSignalModal,
    onHandleToggleDescriptionModal
  } = props;
  return {
    intl,
    handleEditClick,
    handleDeleteClick,
    columns,
    data,
    rowCount,
    keyword,
    handleSearch,
    openDialog,
    handleCloseDialog,
    isEdit,
    handleSubmit,
    initialValues,
    ginsengData,
    filesImage,
    handleAddClick,
    handleRemoveImage,
    handleOnchangeImage,
    onChangeFileCertificate,
    pageSize,
    setPageSize,
    tableData,
    currentPage,
    setCurrentPage,
    handleChangeCurrentPage,
    ginsengAdminList,
    handleImage,
    onClickTopBarStatus,
    onHandleToggleSignalModal,
    onHandleToggleDescriptionModal
  };
}
