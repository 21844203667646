import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AdminAuth } from 'api/AdminApi';
import Config from 'configuration';

const initialSignInAdmin = () => {
    if (sessionStorage.getItem(Config.storageKey.signInAdmin)) {
        return {
            ...JSON.parse(
                sessionStorage.getItem(Config.storageKey.signInAdmin),
            ),
        };
    }
    return {
        signInAdminToken: [],
    };
};

export const getAccessToken = createAsyncThunk(
    'signInAdmin/signInAdminToken',
    async (params) => {
        const res = await AdminAuth.login(params);
        return res;
    },
);

const signInAdmin = createSlice({
    name: 'signInAdmin',
    initialState: initialSignInAdmin(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAccessToken.fulfilled, (state, action) => {
                state.signInAdmin = action.payload.responseData;
            })
            .addCase(getAccessToken.rejected, (state, action) => {});
    },
});

const { reducer } = signInAdmin;
export default reducer;
