import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateNewPw } from 'api/AdminApi';

export const submitNewPw = createAsyncThunk('createNewPw/createNewPwToken', async (params) => {
    const res = await CreateNewPw.createNewPw(params);
    return res;
});

const createNewPw = createSlice({
    name: 'createNewPw',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitNewPw.fulfilled, (state, action) => {
                state.signInAdmin = action.payload.responseData;
            })
            .addCase(submitNewPw.rejected, (state, action) => {});
    },
});

const { reducer } = createNewPw;
export default reducer;
