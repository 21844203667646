import DecorateContainer from 'components/DecorateContainer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Utils, { ForgetPwProcess } from 'utils';
import i18nLabels from './VerifiedCode.i18n';
import VerifiedCoreContainer from './VerifiedCodeContainer';

export default function VerifiedCode(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    // const infoSignin = useSelector(signInSelector);
    const verifiedProps = {
        ...props,
        i18nLabels,
        history,
        dispatch,
    };
    if (Utils.protectLine(ForgetPwProcess.VerifiedCode)) {
        return <DecorateContainer {...verifiedProps} childComponent={VerifiedCoreContainer} />;
    }
}
