import { hideLoading, showLoading } from 'components/Loading/LoadingSlice';
import Config from 'configuration';
import { ArticleColumns } from 'pages/Admin/Component/Table/TableColumnsConfig';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Utils from 'utils';
import useTableProperties from '../Component/Table/useTableProperties';
import { deleteGinsengFile, uploadGinsengFile } from '../Ginseng/GinsengSlice';
import propsProvider from './ArticlePropsProvider';
import {
    deleteArticleAdmin,
    getListArticleAdmin,
    insertArticleAdmin,
    updateArticleAdmin,
} from './ArticleSlice';
import ArticlesMainView from './template/ArticleMainView';

function ArticleContainer(props) {
    const { dispatch, articleAdminList } = props;

    const [keyword, setKeyword] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [data, setData] = useState(articleAdminList);
    const intl = useIntl();
    const columns = ArticleColumns(intl);
    const [openDialog, setOpenDialog] = useState(false);
    const [articleData, setArticleData] = useState({});
    const [updateData, setUpdateData] = useState(false);
    const [isAdd, setIsAdd] = useState(true);
    const [filesImage, setFilesImage] = useState(null);
    const [imageRemove, setImageRemove] = useState(null);

    const payload = {
        currentPage: currentPage,
        pageSize: pageSize,
        sortField: 'created_at',
        sortOrder: 'desc',
        keyword: keyword,
    };

    const tableData = useTableProperties(data);

    useEffect(() => {
        setCurrentPage(1);
    }, [pageSize, keyword]);

    useEffect(() => {
        dispatch(showLoading());
        const delayDebounceFn = setTimeout(() => {
            dispatch(getListArticleAdmin(payload)).then((result) => {
                setData(Utils.getValues(result, 'payload.responseData', []));
                dispatch(hideLoading());
            });
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [pageSize, keyword, currentPage, dispatch, updateData]);

    const handleAddClick = () => {
        setStateHandle(true, {});
    };

    const handleEditClick = (e) => {
        setStateHandle(false, e);
        setImageRemove(null);
        setFilesImage(e.images);
    };

    const handleDeleteClick = async (e) => {
        const result = await Utils.handleConfirmDelete(intl);
        if (result.isConfirmed) {
            dispatch(showLoading());
            await dispatch(deleteArticleAdmin(e.data.id)).then((result) => {
                const status = Utils.getValues(result, 'payload.status', null);
                if (status === Config.SUCCESS) {
                    setUpdateData(!updateData);
                    Utils.handlePopup(intl.messages.deleteSuccess, 'success');
                } else {
                    Utils.handlePopup(intl.messages.deleteFail, 'error');
                }
            });
            dispatch(hideLoading());
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const setStateHandle = (isAdd = false, dataArticle = {}) => {
        setFilesImage(null);
        setIsAdd(isAdd);
        setArticleData(dataArticle);
        setOpenDialog(true);
    };
    const initialValues = {
        link: '',
        title: '',
        created_at: Utils.formatDateYDM(),
        avatar: intl.messages.chooseFile,
        display: 'true',
        description: '',
    };

    const handleValidation = (data, isAdd) => {
        if (!data.title.trim()) {
            Utils.handlePopup(intl.messages.requiredTitle, 'error');
            return true;
        }
        if (!data.link.trim()) {
            Utils.handlePopup(intl.messages.requiredLink, 'error');
            return true;
        }
        if (!data.created_at) {
            Utils.handlePopup(intl.messages.requiredDate, 'error');
            return true;
        }
        if (isAdd && !filesImage) {
            Utils.handlePopup(intl.messages.requiredAvatar, 'error');
            return true;
        }
        return false;
    };
    const handleSubmit = async (data) => {
        if (handleValidation(data, isAdd)) return;
        dispatch(showLoading());
        let status = Config.FAIL;
        const article = {
            newspaper_office_name: data.link,
            title: data.title,
            description: data.description,
            display: data.display === 'true',
        };
        const uploadPromises = [];

        if (imageRemove || isAdd) {
            imageRemove && dispatch(deleteGinsengFile(imageRemove.id));
            uploadPromises.push(Utils.processFile(dispatch, filesImage));
            const imageList = await Promise.all(uploadPromises);
            article['images'] = imageList[0];
        }
        if (isAdd) {
            const result = await dispatch(insertArticleAdmin(article));
            status = Utils.getValues(result, 'payload.status', Config.FAIL);
        } else {
            if (!imageRemove) {
                article['images'] = filesImage;
            }
            const updateArticle = {
                id: data.id,
                article: article,
            };
            const result = await dispatch(updateArticleAdmin(updateArticle));
            status = Utils.getValues(result, 'payload.status', Config.FAIL);
        }
        if (status === Config.SUCCESS) {
            setUpdateData(!updateData);
            Utils.handlePopup(
                isAdd ? intl.messages.addSuccess : intl.messages.updateSuccess,
                'success',
            );
        } else {
            Utils.handlePopup(isAdd ? intl.messages.addFail : intl.messages.updateFail, 'error');
        }
        dispatch(hideLoading());
        setOpenDialog(false);
    };

    const onChangeImage = (e) => {
        const text = document.getElementById('avatar-text');
        if (e.target.files.length > 0) {
            if (e.target.files[0].type.split('/')[0] === 'image') {
                text.innerHTML = e.target.files[0].name;
                text.style.color = '#0288d1';
                setImageRemove(filesImage);
                setFilesImage(e.target.files[0]);
            } else {
                Utils.handlePopup(
                    intl.messages.requiredImageType,
                    'error',
                );
            }
            
        } else {
            text.innerHTML = intl.messages.chooseFile;
            text.style.color = '#9ea4aa';
        }
    };

    const articleContainerProps = {
        ...props,
        intl,
        columns,
        keyword,
        setKeyword,
        handleEditClick,
        handleDeleteClick,
        handleAddClick,
        openDialog,
        handleCloseDialog,
        handleSubmit,
        initialValues,
        onChangeImage,
        articleData,
        articleAdminList,
        isAdd,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        tableData,
    };
    return <ArticlesMainView {...propsProvider(articleContainerProps)} />;
}

export default ArticleContainer;
