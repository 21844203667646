import { productAdminSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './Product.i18n';
import ProductContainer from './ProductContainer';

export default function Products(props) {
    const history = useHistory();
    const product = useSelector(productAdminSelector);
    const { productAdminList = {} } = product;
    const dispatch = useDispatch();
    const productProps = {
        ...props,
        dispatch,
        i18nLabels,
        history,
        productAdminList,
    };
    return <DecorateContainer {...productProps} childComponent={ProductContainer} />;
}
