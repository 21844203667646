import React from "react";
import Header from "components/Header";
import Config from "configuration";
export default function CertificatesDetailMainView(props) {
  const { intl, history, certificateDetail, handleDownload, isDownloading } =
    props;
  return (
    <div className="certificatesDetail-container">
      <Header
        headerTitle={intl.messages.Certificates}
        isWhite={false}
        onPress={() => history.push("/certificates")}
      />
      <div className="certificatesDetail-content">
        <div className="certificatesDetail-text">
          <p className="certificatesDetail-txtTitle">
            {certificateDetail.name}
          </p>
          <span className="certificatesDetail-txtYear">
            {certificateDetail.certificate_year}{" "}
          </span>
        </div>

        <div className="certificatesDetail-paper">
          <img
            className="certificatesDetail-img"
            src={Config.Image_Url + certificateDetail.images_path}
            alt={certificateDetail.images_path}
          />
        </div>
        <div className="certificatesDetail-Download">
          <button
            className="certificatesDetail-btnDownload"
            onClick={handleDownload}
            disabled={isDownloading}
          >
            {isDownloading ? intl.messages.Downloading : intl.messages.Download}
          </button>
        </div>
      </div>
    </div>
  );
}
