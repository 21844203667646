import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FileUpload, GinsengAdmin } from 'api/AdminApi';
import { qrCodeApi } from 'api/qrCodeApi';
import Config from 'configuration';

const initialGinsengAdminList = () => {
    if (sessionStorage.getItem(Config.storageKey.ginsengAdmin)) {
        return {
            ...JSON.parse(sessionStorage.getItem(Config.storageKey.certificateAdmin)),
        };
    }
    return {
        ginsengAdminList: [],
    };
};

export const getListGinsengAdmin = createAsyncThunk(
    'ginsengAdmin/listGinsengAdmin',
    async (params) => {
        const res = await GinsengAdmin.getListGinseng(params);
        console.log(res.responseData.rows)
        return res;
    },
);

export const insertGinsengAdmin = createAsyncThunk(
    'ginsengAdmin/insertGinsengAdmin',
    async (params) => {
        const res = await GinsengAdmin.insertGinseng(params);
        return res;
    },
);

export const getGinsengByCode = createAsyncThunk(
    'gingsenAdmin/getByCode',
    async (params) => {
        const res = await GinsengAdmin.getGinsengByCode(params);
        return res
    }
);

export const updateGinsengAdmin = createAsyncThunk(
    'ginsengAdmin/updateGinsengAdmin',
    async (params) => {
        const res = await GinsengAdmin.updateGinseng(params);
        return res;
    },
);

export const approveQR = createAsyncThunk(
    "/qrcode/approve_qr",
    async (payload) => {
        const res = await qrCodeApi.approveQR(payload);
        return res;
    }
)

export const deleteGinsengAdmin = createAsyncThunk(
    'ginsengAdmin/deleteGinsengAdmin',
    async (params) => {
        const res = await GinsengAdmin.deleteGinseng(params);
        return res;
    },
);

export const uploadGinsengFile = createAsyncThunk(
    'ginsengAdmin/uploadGinsengFile',
    async (params) => {
        const res = await FileUpload.uploadFile(params);
        return res;
    },
);

export const deleteGinsengFile = createAsyncThunk(
    'ginsengAdmin/deleteGinsengFile',
    async (params) => {
        const res = await FileUpload.deleteFile(params);
        return res;
    },
);

const ginsengAdmin = createSlice({
    name: 'ginsengAdmin',
    initialState: initialGinsengAdminList(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListGinsengAdmin.fulfilled, (state, action) => {
                state.ginsengAdminList = action.payload.responseData;
            })
            .addCase(getGinsengByCode.fulfilled, (state, action) => {
                state.ginsengInfoByCode = action?.payload?.responseData;
            });
    },
});

const { reducer } = ginsengAdmin;
export default reducer;
