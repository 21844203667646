export default function Badge(props) {
    const { trueIntl, falseIntl, color, isAvailable } = props;

    if (isAvailable !== null) {
        return (
            <>
                {isAvailable ? (
                    <div className={`chip-atom ${color ?? color}`}>{trueIntl}</div>
                ) : (
                    <div className='chip-atom hidden'>{falseIntl}</div>
                )}
            </>
        );
    }
}
