export const storageKey = {
    home: 'HOME',
    product: 'PRODUCT',
    common: 'COMMON',
    article: 'ARTICLE',
    address: 'ADDRESS',
    certificate: 'CERTIFICATE',
    certificateAdmin: 'CERTIFICATE_ADMIN',
    articleAdmin: 'ARTICLE_ADMIN',
    ginsengAdmin: 'GINSENG_ADMIN',
    productAdmin: 'PRODUCT_ADMIN',
    locationAdmin: 'LOCATION_ADMIN',
    signInAdmin: 'SIGNIN_ADMIN',
};
