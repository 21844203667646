import { ReactComponent as AddIcon } from "./add.svg";
import { ReactComponent as ShoppingCart } from "./add_shopping_cart.svg";
import { ReactComponent as AddWhiteIcon } from "./add_white.svg";
import { ReactComponent as ArrowBefore } from "./arrow_before.svg";
import { ReactComponent as ArrowForward } from "./arrow_forward.svg";
import { ReactComponent as ArrowNext } from "./arrow_next.svg";
import { ReactComponent as ArticleGreenIcon } from "./article_green.svg";
import { ReactComponent as ArticleWhiteIcon } from "./article_white.svg";
import {
  ReactComponent as Attachment,
  ReactComponent as AttachmentIcon,
} from "./attachment.svg";
import { ReactComponent as ChevronLeft } from "./chevron_left.svg";
import { ReactComponent as ChevronRight } from "./chevron_right.svg";
import { ReactComponent as ClearIcon } from "./clear.svg";
import {
  ReactComponent as Close,
  ReactComponent as CloseIcon,
} from "./close.svg";
import {
  ReactComponent as Date,
  ReactComponent as DateIcon,
} from "./date_range.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as EmptyScanIcon } from "./empty_scan.svg";
import { ReactComponent as FilterIcon } from "./filter_list.svg";
import { ReactComponent as GrayQRCode } from "./gray_qr_code.svg";
import { ReactComponent as BackGreenIcon } from "./ic_back_green.svg";
import { ReactComponent as BackWhiteIcon } from "./ic_back_white.svg";
import { ReactComponent as CalendarIcon } from "./ic_calendar.svg";
import { ReactComponent as ForwardIcon } from "./ic_forward_green.svg";
import { ReactComponent as LocationIcon } from "./ic_location.svg";
import { ReactComponent as VerifyIcon } from "./ic_verified.svg";
import { ReactComponent as KeyboardArrowGrayDown } from "./keyboard_arrow_down_gray_24x24.svg";
import { ReactComponent as KeyboardArrowDown } from "./keyboard_arrow_down_green_20x20.svg";
import { ReactComponent as ArrowDownGreenIcon } from "./keyboard_arrow_down_green_24x24.svg";
import { ReactComponent as ArrowRightWhiteIcon } from "./keyboard_arrow_right_white.svg";
import { ReactComponent as ListGreenIcon } from "./list_green.svg";
import { ReactComponent as ListWhiteIcon } from "./list_white.svg";
import { ReactComponent as LocalPolice } from "./local_police.svg";
import { ReactComponent as LocationGreenIcon } from "./location_green_24x24.svg";
import { ReactComponent as LocationWhiteIcon } from "./location_white_24x24.svg";
import { ReactComponent as QrCodeScanner } from "./qr_code_scanner.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as StarOutline } from "./star_outline.svg";
import { ReactComponent as VerifyGreenIcon } from "./verified_green_24x24.svg";
import { ReactComponent as VerifyWhiteIcon } from "./verified_white_24x24.svg";
import { ReactComponent as VisibilityOff } from "./visibility_off.svg";
import { ReactComponent as VisibilityOff2 } from "./visibility_off2.svg";
import { ReactComponent as VisibilityOn } from "./visibility_on.svg";
import { ReactComponent as VisibilityOn2 } from "./visibility_on2.svg";
import { ReactComponent as MoreVert } from "./more_vert.svg";
import { ReactComponent as IconDraw } from "./icon_draw.svg";
import { ReactComponent as VisibilityOnBlack } from "./visibility_on_black.svg";
import { ReactComponent as IconHistory } from "./icon_history.svg";
import {ReactComponent as MonitorWeight} from "./monitor-weight.svg";
export {
  AddIcon,
  AddWhiteIcon,
  ArrowBefore,
  ArrowDownGreenIcon,
  ArrowForward,
  ArrowNext,
  ArrowRightWhiteIcon,
  ArticleGreenIcon,
  ArticleWhiteIcon,
  Attachment,
  AttachmentIcon,
  BackGreenIcon,
  BackWhiteIcon,
  CalendarIcon,
  ChevronLeft,
  ChevronRight,
  ClearIcon,
  Close,
  CloseIcon,
  Date,
  DateIcon,
  Delete,
  Edit,
  EmptyScanIcon,
  FilterIcon,
  ForwardIcon,
  GrayQRCode,
  KeyboardArrowDown,
  KeyboardArrowGrayDown,
  ListGreenIcon,
  ListWhiteIcon,
  LocalPolice,
  LocationGreenIcon,
  LocationIcon,
  LocationWhiteIcon,
  QrCodeScanner,
  SearchIcon,
  ShoppingCart,
  StarOutline,
  VerifyGreenIcon,
  VerifyIcon,
  VerifyWhiteIcon,
  VisibilityOff,
  VisibilityOff2,
  VisibilityOn,
  VisibilityOn2,
  MoreVert,
  IconDraw,
  VisibilityOnBlack,
  IconHistory,
  MonitorWeight,
};
