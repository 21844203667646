import store from 'app/store';
import { hideLoading, showLoading } from 'components/Loading/LoadingSlice';
import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Utils, { ForgetPwProcess } from 'utils';
import { changeNextProcess } from '../ForgetPw/forgetPwSlice';
import propsProvider from './ResetPwPropsProvider';
import { submitNewPw } from './resetPwSlice';
import ResetPwMainView from './template/ResetPwMainView';

export default function ResetPwContainer(props) {
    const { dispatch, history } = props;
    const [errorMessage, setErrorMessage] = useState({ password: '', re_password: '' });
    const intl = useIntl();
    const state = store.getState();

    const formik = useFormik({
        initialValues: {
            password: '',
            re_password: '',
            email: state.forgetPw.email,
            code: state.forgetPw.verifiedCode,
        },
    });

    const onSubmitClick = () => {
        if (formik.values.password.length < 6) {
            setErrorMessage({ password: intl.messages.PwMinReq });
            return;
        }
        if (!formik.values.password) {
            setErrorMessage({ password: intl.messages.PwNotBlank });
            return;
        }
        if (formik.values.password !== formik.values.re_password) {
            setErrorMessage({ re_password: intl.messages.NotSame });
            return;
        }

        const delayDebounceFn = setTimeout(async () => {
            dispatch(showLoading());
            dispatch(submitNewPw(formik.values)).then((result) => {
                const status = Utils.getValues(result, 'payload.status', null);
                if (status === Config.FAIL) {
                    history.push(pagePath.signInUrl);
                    dispatch(hideLoading());
                    return;
                }

                if (status === Config.SUCCESS) {
                    dispatch(changeNextProcess(ForgetPwProcess.CreateNewPwSuccessfully));
                    dispatch(hideLoading());
                    history.push(pagePath.successUrl);
                }
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    };

    const resetPwPropsProvider = {
        ...props,
        intl,
        formik,
        errorMessage,
        onSubmitClick,
    };
    return <ResetPwMainView {...propsProvider(resetPwPropsProvider)} />;
}
