import SignInButton from '../../Component/SignInButton/index';
import SignInForm from '../../Component/SignInForm/index';
import { SignInInput } from '../../Component/SignInput/index';

export default function ForgetPwMainView(props) {
    const { intl, onSubmitClick, formik, errorMessage } = props;

    return (
        <SignInForm formTitle={intl.messages.ForgetPwTitle}>
            <form
                className='form-body'
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <p className='place-center'>{intl.messages.Message}</p>
                <SignInInput
                    type='email'
                    name='email'
                    label={intl.messages.EmailLabel}
                    placeholder={intl.messages.EmailPlaceholder}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    message={errorMessage.email}
                />
                <SignInButton
                    variable='button'
                    label={intl.messages.SubmitLabel}
                    onClick={onSubmitClick}
                />
            </form>
        </SignInForm>
    );
}
