import React from "react";
import i18nLabels from "./Address.i18n";
import { useHistory } from "react-router-dom";
import DecorateContainer from "components/DecorateContainer";
import AddressContainer from "./AddressContainer";
import { useDispatch, useSelector } from "react-redux";
import { addressSelector, productSelector, ginsengAdminSelector } from "app/selectors";

export default function Address(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const address = useSelector(addressSelector);
  const product = useSelector(productSelector);
  const infoGinseng = useSelector(ginsengAdminSelector)

  const { ginsengInfoByCode = {} } = infoGinseng
  const { addressList = {} } = address;
  const { productInfo = {} } = product;
  const addressProps = {
    ...props,
    i18nLabels,
    history,
    dispatch,
    addressList,
    productInfo,
    ginsengInfoByCode
  };
  return (
    <DecorateContainer {...addressProps} childComponent={AddressContainer} />
  );
}
