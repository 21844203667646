export default function propsProvider(props) {
  /*
      - To only list utilized props
      - Wrapping utilized props on each view
  */
  const {
    intl,
    dataDropdown,
    location,
    children,
    header,
    subHeader,
    onChangeSearch,
    handleAddClick,
    displayPrinter,
    handlePrintClick,
    topBar,
    showAddNewButton
  } = props;
  return {
    intl,
    dataDropdown,
    location,
    children,
    header,
    subHeader,
    onChangeSearch,
    handleAddClick,
    displayPrinter,
    handlePrintClick,
    topBar,
    showAddNewButton
  };
}
