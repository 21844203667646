import { useState, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Attachment } from "assets/icon";
import { Date } from "assets/icon";
import { GrayQRCode } from "assets/icon";
import { ClearIcon } from "assets/icon";
import { useDropzone } from "react-dropzone";
export function ProductInput(props) {
  const [filePreviews, setFilePreviews] = useState([]);
  const onDrop = (acceptedFiles) => {
    const previews = acceptedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
      file,
    }));
    setFilePreviews(previews);
  };
  const removePreview = (previewToRemove) => {
    const updatedPreviews = filePreviews.filter(
      (preview) => preview.preview !== previewToRemove.preview
    );
    setFilePreviews(updatedPreviews);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { placeholder, label, titleClass, type, intl } = props;
  const ToggleSwitch = () => {
    const [isChecked, setIsChecked] = useState(false);
    const handleChange = () => {
      setIsChecked(!isChecked);
    };
    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={isChecked} onChange={handleChange} />
        <span className="switch"> {isChecked ? "ml" : ""} </span>
      </label>
    );
  };
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleButtonClick = () => {};
  const [selectedDate, setSelectedDate] = useState("");
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <div
      className={
        (["ProductName"].includes(titleClass) &&
          "product-input product-name") ||
        (["ProductSource"].includes(titleClass) &&
          "product-input product-source") ||
        (["ProductQR"].includes(titleClass) && "product-input product-QR") ||
        (["DetailInfo", "ProductEffect"].includes(titleClass) &&
          "product-input product-detail-info") ||
        (["img"].includes(titleClass) &&
          "product-input product-imgUploading") ||
        (["ProductLink"].includes(titleClass) &&
          "product-input product-link") ||
        "product-input"
      }
    >
      <label
        className={
          (["DetailInfo", "ProductEffect", "ProductLink"].includes(
            titleClass
          ) &&
            "lbl-darkGray") ||
          (["ProductQR"].includes(titleClass) && "lbl-darkGray0") ||
          (["date"].includes(type) && "lbl-darkGray")
        }
      >
        {label}
      </label>
      {[
        "ProductCode",
        "ProductDate",
        "ProductName",
        "ProductEffect",
        "ProductSource",
        "img",
      ].includes(titleClass) && <label className="label">*</label>}
      {(["date"].includes(type) && (
        <div className="attachment">
          <label className="custom-datePicker">
            <ReactDatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              placeholderText="12/04/2021"
            />
          </label>
          <Date className="upload-icon" />
        </div>
      )) ||
        (["ProductSource"].includes(titleClass) && (
          <div className="change-units">
            <input
              className="input-units"
              placeholder={intl.messages.changeUnitPH}
            />
            <ToggleSwitch />
          </div>
        )) ||
        (["ProductQR"].includes(titleClass) && (
          <div className="qr">
            <button className="btn-qr" onClick={() => handleButtonClick()}>
              {intl.messages.viewQr}
              <GrayQRCode />
            </button>
          </div>
        )) ||
        (["ProductLink"].includes(titleClass) && (
          <div className="link">
            <input placeholder="Chọn hoặc tìm kiếm theo mã sâm" />
            <select className="link-options">
              <option value=""></option>
              <option value="option1">1</option>
              <option value="option2">2</option>
              <option value="option3">3</option>
            </select>
          </div>
        )) ||
        (["img"].includes(titleClass) && (
          <div className="pick-img">
            {filePreviews.length > 0 && (
              <div className="preview-container">
                {filePreviews.map((preview) => (
                  <div key={preview.preview} className="preview-item">
                    <img
                      src={preview.preview}
                      alt="Preview"
                      className="preview-image"
                    />
                    <button
                      className="close-btn"
                      onClick={() => removePreview(preview)}
                    >
                      <ClearIcon className="clear-icon" />
                    </button>
                  </div>
                ))}
              </div>
            )}

            <div className="dropzone-container">
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? "active" : ""}`}
              >
                <input {...getInputProps()} />
                <p className="add-icon">+</p>
              </div>
            </div>
          </div>
        ))}
      <input
        type={["date"].includes(type) && "date"}
        onChange={handlePasswordChange("password")}
        className={
          (["date"].includes(type) && "custom-date") ||
          (["ProductSource", "ProductQR", "ProductLink"].includes(titleClass) &&
            "units")
        }
        placeholder={placeholder}
      />
    </div>
  );
}
