import DialogModal from "components/DialogModal";
import { Field } from "formik";
import { AddIcon, ClearIcon } from "assets/icon";
import Select from "react-select";
import Utils from "utils";
function ProductModal({
  openDialog,
  handleCloseDialog,
  intl,
  handleSubmit,
  initialValues,
  isAdd,
  filesImage,
  productData,
  filesExcel,
  handleOnChangeExcel,
  handleExcel,
  handleRemoveExcel,
}) {
  return (
    <DialogModal
      openDialog={openDialog}
      handleCloseDialog={handleCloseDialog}
      handleSubmit={handleSubmit}
      headerTitle={
        isAdd ? intl.messages.createProduct : intl.messages.updateProduct
      }
      submitName={isAdd ? intl.messages.add : intl.messages.update}
      cancelName={intl.messages.cancel}
      initialValues={
        isAdd
          ? initialValues
          : {
              id: productData.id,
              barcode: productData.barcode,
              product_link: productData.product_link,
              created_at: Utils.formatDateYDM(productData.created_at),
            }
      }
    >
      <div className="sub-header">{intl.messages.generalInformation}</div>
      {isAdd &&    
        <div className="card-container resize-height" style={filesExcel.name && {paddingBottom: 0}}>
          <div style={{ display: "relative" }}>
            {filesExcel.name &&
      
                <div style={{ position: "relative" }}>
                  <div
                    className="remove-image-btn"
                    onClick={() => handleRemoveExcel(filesExcel)}
                  >
                    <ClearIcon />
                  </div>
                  <p>{handleExcel(filesExcel)}</p>
                </div>
              
            }
            {!filesExcel.name && (
              <div
                className="upload-box"
                onClick={() => document.getElementById("getFiles").click()}
              >
                <AddIcon />
              </div>
            )}
          </div>
          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            id="getFiles"
            type="file"
            style={{ display: "none" }}
            onChange={handleOnChangeExcel}
          />
          <p
            className={`card-container__title ${
              !filesExcel.name && `required`
            }`}
            style={{ marginBottom: "0px", color: "#3b927d" }}
          >
            {intl.messages.inputData}
          </p>
        </div>
      }
      {!isAdd &&
        <div style={{ display: "flex" }}>
          <div
              className="input-container"
              style={{ marginRight: "16px", width: "100%" }}
            >
            <Field
              className="input-container__input"
              id="barcode"
              name="barcode"
              type="text"
              placeholder={intl.messages.barcode}
            />
            <label htmlFor="qr_code" className="input-container__title required">
              {intl.messages.barcode}
            </label>
          </div>
          <div
            className="input-container"
            style={{ marginRight: "16px", width: "100%" }}
          >
            <Field
              className="input-container__input"
              id="product_link"
              name="product_link"
              type="text"
              placeholder={intl.messages.productLink}
            />
            <label htmlFor="qr_code" className="input-container__title required">
              {intl.messages.productLink}
            </label>
          </div>
        </div>
      }
    </DialogModal>
  );
}

export default ProductModal;
