import Utils from "utils";
import axiosClient from "./axiosClient";

const productScan = {
  getProductByQrCode: ({ id, qrCode }) => {
    const url = `/product/getByQrCode/${qrCode}`;
    return axiosClient.get(url);
  },
  getInfoOfProductByProductId: ({ id }) => {
    const url = `/product/getInformation/${id}`;
    return axiosClient.get(url);
  },
};
const productArticle = {
  getArticleOfProductByProductId: ({ id, qrCode, securityCode }) => {
    const url = `/product-article/getArticleOfProductByProductId/${id}`;
    return axiosClient.get(url, {
      headers: {
        Signature: Utils.signatureEnCode(id, qrCode, securityCode),
      },
    });
  },
};

const productCertificates = {
  getCertificates: ({ id }) => {
    const url = `/product-certificate/getCertificateOfProductByProductId/${id}`;
    return axiosClient.get(url);
  },
};

const productListAddress = {
  getListAddress: ({ id, qrCode, securityCode }) => {
    const url = `/product/getListAddress/${id}`;
    return axiosClient.get(url, {
      headers: {
        Signature: Utils.signatureEnCode(id, qrCode, securityCode),
      },
    });
  },
};

export { productScan, productArticle, productListAddress, productCertificates };
