import propsProvider from "./EmptyPropsProvider";
import EmptyMainView from "./template/EmptyMainView";
import { useIntl } from "react-intl";
function EmptyContainer(props) {
  const intl = useIntl();
  const onScanOtherCode = () => {
    props.history.push("/scan");
  };
  const EmptyContainerProps = {
    ...props,
    intl,
    onScanOtherCode,
  };

  return <EmptyMainView {...propsProvider(EmptyContainerProps)} />;
}

export default EmptyContainer;
