import React from "react";
import i18nLabels from "./Empty.i18n";
import { useHistory } from "react-router-dom";
import DecorateContainer from "components/DecorateContainer";
import EmptyContainer from "./EmptyContainer";
export default function Empty(props) {
  const history = useHistory();

  const emptyProps = {
    ...props,
    i18nLabels,
    history,
  };
  return <DecorateContainer {...emptyProps} childComponent={EmptyContainer} />;
}
