import QrReader from "react-web-qr-reader";

function ScanMainView(props) {
  const { handleScan, handScanError, intl } = props;

  return (
    <div className="scan-container">
      <div className="scan-header">
        <h2 className="scan-header__title">{intl.messages.ScanQR}</h2>
        <h6 className="scan-header__sub">{intl.messages.CameraFrame}</h6>
      </div>

      <div className="scan-holder">

        <div className="scan-holder__frame">
          <hr className="scan-holder__anim" ></hr>
          <QrReader
            className="scan-holder__reader"
            delay={5000}
            showViewFinder={false}
            onScan={handleScan}
            onError={handScanError}
          />
        </div>
      </div>

    </div>
  );
}

export default ScanMainView;
