import {
  ArrowDownGreenIcon,
  ArrowRightWhiteIcon,
  ListGreenIcon,
  ListWhiteIcon,
} from "assets/icon";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
function DashboardItem({
  text,
  icon,
  iconActive,
  iconRight,
  iconRightActive,
  url,
  isActive,
  isDropdown = false,
  dataDropDown = [],
}) {
  const [listIcon, setListIcon] = useState(<ListWhiteIcon />);
  const [arrowIcon, setArrowIcon] = useState(<ArrowRightWhiteIcon />);
  const [childArrowIcon, setChildArrowIcon] = useState(<ArrowDownGreenIcon />);

  const onClickDropDown = () => {
    var dropdown = document.getElementsByClassName("drop");

    dropdown[0].classList.toggle("active");
    var dropdownContent = dropdown[0].nextElementSibling;
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
      setListIcon(<ListWhiteIcon />);
      setArrowIcon(<ArrowRightWhiteIcon />);
    } else {
      dropdownContent.style.display = "block";
      // dropdownContent[0].style.display = "block";
      setListIcon(<ListGreenIcon />);
      setArrowIcon(<ArrowDownGreenIcon />);
    }
  };

  const onClickChildDropDown = () => {
    var dropdown = document.getElementsByClassName("content-drop");

    var dropdownContent = dropdown[0];

    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
      setChildArrowIcon(<ArrowRightWhiteIcon />);
    } else {
      dropdownContent.style.display = "block";
      setChildArrowIcon(<ArrowDownGreenIcon />);
    }
  };
  useEffect(() => {
    if (isDropdown && isActive) {
      setListIcon(<ListGreenIcon />);
      setArrowIcon(<ArrowDownGreenIcon />);
    }
  }, []);

  return (
    <>
      {isDropdown ? (
        <>
          <button
            onClick={onClickDropDown}
            className={`dashboard-item drop ${isActive ? "active" : ""}`}
            style={{ marginBottom: "0px" }}
          >
            <div className="dashboard-item__header">
              <span className="icon-left">{listIcon}</span>
              <span className="dashboard-item__text">{text}</span>
              <span className="icon-right">{arrowIcon}</span>
            </div>
          </button>
          <div
            className="dropdown-container"
            style={{ display: `${isActive ? "block" : "none"}` }}
          >
            {dataDropDown &&
              dataDropDown.map((item, index) => {
                return (<div>
                  {
                    item.children.length > 0 ?
                      (
                        <div
                          key={`dashboard-${index}`}
                          to={item.url}
                          className={`dashboard-item ${item.isActive && "active"}`}
                        >
                          <div className="spacing-icon" />
                          <div onClick={onClickChildDropDown}>
                            <span className="dashboard-item__content-text">{item.name}</span>
                            <span className="icon-right">{childArrowIcon}</span>
                          </div>
                          <div className="dashboard-item__content content-drop" style={{ display: 'none' }}>
                            {item.children.map((child) => {
                              return (
                                <Link
                                  key={`dashboard-${child}`}
                                  style={{ display: "flex", justifyC: 'space-between' }}
                                  to={child.url}
                                  className={`dashboard-item ${child.isActive && "active"}`}
                                >
                                  {child.name}
                                  {child.isActive && <span className="dot-active" />}
                                </Link>
                              )
                            })}
                          </div>
                        </div>

                      ) : (
                        <Link
                          key={`dashboard-${index}`}
                          to={item.url}
                          className={`dashboard-item ${item.isActive && "active"}`}
                        >
                          <div className="spacing-icon" />
                          {item.name}
                          {item.isActive && <span className="dot-active" />}
                        </Link>
                      )
                  }
                </div>)
              })}
          </div>
        </>
      ) : (
        <Link to={url} className={`dashboard-item ${isActive ? "active" : ""}`}>
          <span className="icon-left"> {isActive ? iconActive : icon}</span>
          <span className="dashboard-item__text">{text}</span>
          <span className="icon-right">
            {isActive ? iconRightActive : iconRight}
          </span>
        </Link>
      )}
    </>
  );
}

export default DashboardItem;
