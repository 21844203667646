export default function propsProvider(props) {
  /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
  const { handleButtonClick, intl, certificateList, productInfo, ginsengInfoByCode } = props;
  return {
    handleButtonClick,
    intl,
    certificateList,
    productInfo,
    ginsengInfoByCode
  };
}
