import {
  ArrowBefore,
  ArrowForward,
  ArrowNext,
  ArticleGreenIcon,
  CalendarIcon,
  LocalPolice,
  LocationIcon,
  ShoppingCart,
  StarOutline,
  VerifyIcon,
  MonitorWeight,
} from "assets/icon";
import { ImageGinsengBG } from "assets/images";
import Footer from "components/Footer";
import Config from "configuration";
import { Slide } from "react-slideshow-image";
import Utils from "utils";
export default function ProductInfoComponent(props) {
  const {
    intl,
    productData,
    productInfo,
    onPressDispatch,
    onSlideChange,
    progressPercent,
    windowHeight,
    ginsengInfoByCode
  } = props;
  const infoTxt = [
    {
      name: intl.messages.HarvestDate,
      icon: <CalendarIcon />,
      value: Utils.formatDateYDM(ginsengInfoByCode?.harvest_date) ?? "",
    },
    {
      name: intl.messages.code,
      icon: <VerifyIcon />,
      value: ginsengInfoByCode?.code,
    },
    {
      name: intl.messages.Age,
      icon: <CalendarIcon />,
      value: ginsengInfoByCode?.years_of_ginseng,
    },
    {
      name: intl.messages.Weight,
      icon: <MonitorWeight />,
      value: ginsengInfoByCode?.weight,
    },
    {
      name: intl.messages.Origin,
      icon: <LocationIcon />,
      value: ginsengInfoByCode?.source,
    },
    {
      name: intl.messages.Owner,
      icon: <VerifyIcon />,
      value: Utils.formatFullName(ginsengInfoByCode?.qr_code.own_by_user),//ginsengInfoByCode?.certificate,
    },
  ];
  const anotherInfo = [
    {
      title: intl.messages.EffectOfGinseng,
      icon: <StarOutline />,
      onPress: () => {
        onPressDispatch("moreInfo");
      },
    },
    {
      title: intl.messages.RelevantCertificate,
      icon: <LocalPolice />,
      onPress: () => {
        onPressDispatch("certificates");
      },
    },
    {
      title: intl.messages.Posts,
      icon: <ArticleGreenIcon />,
      onPress: () => {
        onPressDispatch("news");
      },
    },
    {
      title: intl.messages.PurchaseAddress,
      icon: <ShoppingCart />,
      onPress: () => {
        onPressDispatch("address");
      },
    },
  ];

  const prevArrow = (
    <button className="dot">
      <div className="dot-icon">
        <ArrowBefore />
      </div>
    </button>
  );
  const nextArrow = (
    <button className="dot">
      <div className="dot-icon">
        <ArrowNext />
      </div>
    </button>
  );

  const properties = {
    autoplay: false,
    infinite: true,
    indicators: false,
    arrows: true,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    onChange: onSlideChange,
  };

  const ProgressBar = () => (
    <div className="progress-container">
      <div className="progress-bar">
        <div
          style={{
            height: "100%",
            width: `${progressPercent}%`,
            backgroundColor: "#fff",
            transition: "width 0.5s",
          }}
        ></div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="product-info-container">
        <h4 className="product-info-title">{ginsengInfoByCode?.name}</h4>
        <div className="slide-container">
          {ginsengInfoByCode?.images && <Slide {...properties}>
            {ginsengInfoByCode?.images?.length > 0 &&
              ginsengInfoByCode?.images.map((item, index) => {
                return (
                  <div className="slide-div" key={`image-${index}`}>
                    <img
                      className="slide-image"
                      alt="slideImage"
                      src={Config.Image_Url + item.file_path}
                    />
                  </div>
                );
              })}
          </Slide>}
          <ProgressBar />
          {ginsengInfoByCode && (
            <div className="infoTxt">
              <div
                style={{
                  paddingTop: "20px",
                }}
              >
                {infoTxt.map((item, index) => (
                  <div
                    key={`infoTxt-${index}`}
                    className="product-info-content"
                  >
                    <span className="product-info-icon" style={{ paddingRight: 5 }}>
                      {item.icon}
                    </span>
                    <span className="product-info-name" style={{ paddingRight: 15 }}>
                      {item.name}
                    </span>
                    <span className="product-info-value">
                      {item.value}
                    </span>
                  </div>
                ))}
                <div
                  style={{
                    position: "absolute",
                    top: 250,
                    right: 0,
                  }}
                >
                  <img
                    src={ImageGinsengBG}
                    alt="ImageGinsengBG"
                    height={"250px"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer
          headerTitle={intl.messages.AnotherInfo}
          isBold
          style={{position: "relative"}}
        >
          {anotherInfo.map((item, index) => (
            <div style={{ width: "100%" }} key={`anotherInfo-${index}`}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {item.icon}
                <span className="footer-title">{item.title}</span>
                <div
                  style={{
                    flex: 1,
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <ArrowForward onClick={item.onPress} />
                </div>
              </div>
              <hr />
            </div>
          ))}
        </Footer>
      </div>
    </div>
  );
}
