import { Dialog } from "@mui/material";
import { CloseIcon } from "assets/icon";
import { Form, Formik } from "formik";

function DialogModal({
  openDialog,
  handleCloseDialog,
  handleSubmit,
  initialValues,
  children,
  headerTitle,
  submitName,
  cancelName,
  isShowButton = true
}) {
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth={"727px"}
      PaperProps={{ sx: { borderRadius: "12px" } }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form encType="multipart/form-data">
          <div className="modal-wrapper">
            <div className="modal-header">
              <span className="header-text">{headerTitle}</span>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={handleCloseDialog}
              />
            </div>
            <div className="modal-children">{children}</div>
            {isShowButton && <div className="modal-footer">
              <div className="modal-button">
                <button
                  className="modal-button__cancel"
                  type="button"
                  onClick={handleCloseDialog}
                >
                  {cancelName}
                </button>
                <div style={{ marginRight: "8px" }} />
                <button className="modal-button__submit" type="submit">
                  {submitName}
                </button>
              </div>
            </div>}
          </div>
        </Form>
      </Formik>
    </Dialog>
  );
}

export default DialogModal;
