import { VisibilityOff2, VisibilityOn2 } from 'assets/icon';
import { useState } from 'react';

export default function HidenBox(props) {
    const { content } = props;
    const [isHidden, setHidden] = useState(true);
    if (content) {
        return (
            <div className='hidden_box'>
                {isHidden ? '•••••' : content}
                <button onClick={() => setHidden(!isHidden)}>
                    {isHidden ? <VisibilityOn2 /> : <VisibilityOff2 />}
                </button>
            </div>
        );
    }
}
