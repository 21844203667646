import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocationAdmin } from 'api/AdminApi';
import Config from 'configuration';

const initialLocationAdminList = () => {
    if (sessionStorage.getItem(Config.storageKey.locationAdmin)) {
        return {
            ...JSON.parse(sessionStorage.getItem(Config.storageKey.locationAdmin)),
        };
    }
    return {
        locationAdminList: [],
    };
};

export const getListLocationAdmin = createAsyncThunk(
    'locationAdmin/listLocationAdmin',
    async (params) => {
        const res = await LocationAdmin.getListLocation(params);
        return res;
    },
);

export const insertLocationAdmin = createAsyncThunk(
    'locationAdmin/insertLocationAdmin',
    async (params) => {
        const res = await LocationAdmin.insertLocation(params);
        return res;
    },
);

export const updateLocationAdmin = createAsyncThunk(
    'locationAdmin/updateLocationAdmin',
    async (params) => {
        const res = await LocationAdmin.updateLocation(params);
        return res;
    },
);

export const deleteLocationAdmin = createAsyncThunk(
    'locationAdmin/deleteLocationAdmin',
    async (params) => {
        const res = await LocationAdmin.deleteLocation(params);
        return res;
    },
);

const locationAdmin = createSlice({
    name: 'locationAdmin',
    initialState: initialLocationAdminList(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListLocationAdmin.fulfilled, (state, action) => {
                state.locationAdminList = action.payload.responseData;
            })
            .addCase(getListLocationAdmin.rejected, (state, action) => {});
    },
});

const { reducer } = locationAdmin;
export default reducer;
