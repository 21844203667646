import { useIntl } from "react-intl";
import NewsMainView from "./template/NewsMainView";
import propsProvider from "./NewsPropsProvider";
function NewsContainer(props) {
  const { articleList, history, productInfo } = props;
  const intl = useIntl();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const newsContainerProps = {
    ...props,
    intl,
    articleList,
    openInNewTab,
    history,
    productInfo,
  };
  return <NewsMainView {...propsProvider(newsContainerProps)} />;
}

export default NewsContainer;
