export default function propsProvider(props) {
    /*
          - To only list utilized props
          - Wrapping utilized props on each view
      */
    const { intl, onChange, code, setCode, numInputs, onSubmitClick, errorMessage } = props;
    return {
        intl,
        onChange,
        code,
        setCode,
        numInputs,
        onSubmitClick,
        errorMessage,
    };
}
