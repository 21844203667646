import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CertificatesDetailContainer from "./CertificatesDetailContainer";
import i18nLabels from "./CertificatesDetail.i18n"
import DecorateContainer from "components/DecorateContainer";
import { certificateSelector, homeSelector } from "app/selectors";
export default function CertificatesDetail(props) {
  const dispatch = useDispatch();
  const certificate = useSelector(certificateSelector);
  const { certificateList = {} } = certificate;
  
  const certificatesDetailProps = {
    ...props,
    dispatch,
    i18nLabels,
    certificateList
  };
  return <DecorateContainer {...certificatesDetailProps} childComponent={CertificatesDetailContainer} />
}
