const labels = {
  vi: {
      titleAddress: "Địa chỉ mua hàng",
  },
  en: {
    titleAddress: "Shopping location",
  },
};

export default labels;
