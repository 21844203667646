export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
    const { handleButtonClick, intl, productInfo, history, ginsengInfoByCode } = props;
    return {
        handleButtonClick,
        intl,
        productInfo,
        history,
        ginsengInfoByCode
    };
}
