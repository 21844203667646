import { articleAdminSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './Article.i18n';
import ArticleContainer from './ArticleContainer';

export default function Articles(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const article = useSelector(articleAdminSelector);
    const { articleAdminList = {} } = article;
    const articlesProps = {
        ...props,
        dispatch,
        i18nLabels,
        history,
        articleAdminList,
    };
    return <DecorateContainer {...articlesProps} childComponent={ArticleContainer} />;
}
