import React from "react";
import Footer from "components/Footer";
import OtpInput from "react-otp-input";
import { IconInfo } from "assets/images";
export default function ProductCodeComponent(props) {
  const { intl, otp, setOtp } = props;
  return (
    <Footer headerTitle={intl.messages.Protect} isBold>
      <div className="product-line" />
      <span style={{ marginTop: "20px", marginBottom: "5px" }}>
        {intl.messages.EnterCodeToProtect}
      </span>
      <OtpInput
        containerStyle={{ marginBottom: 10 }}
        value={otp}
        onChange={setOtp}
        numInputs={5}
        renderInput={(props) => (
          <input {...props} placeholder="-" className="product-otp" />
        )}
        inputStyle={{ width: 50, maxHeight  : 20, marginRight: 5, marginLeft: 5 }}
      />
      <div className="product-line" />
      <span className="product-container__info" style={{ marginTop: "5px" }}>
        <span>{intl.messages.Instruct}</span>
        <img
          style={{ marginLeft: "5px" }}
          src={IconInfo}
          alt={IconInfo}
          width="16px"
          height="16px"
        />
      </span>
    </Footer>
  );
}
