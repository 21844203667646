import DecorateContainer from 'components/DecorateContainer';
import { useDispatch } from 'react-redux';
import QrShowBoxContainer from './QrShowBoxContainer';

export default function QrShowBox(props) {
    const dispatch = useDispatch();
    const showBoxProps = {
        ...props,
        dispatch,
    };
    return <DecorateContainer {...showBoxProps} childComponent={QrShowBoxContainer} />;
}
