import { createColumnHelper } from "@tanstack/react-table";
import { QrCodeScanner } from "assets/icon";
import Config from "configuration";
import moment from "moment";
import Utils from "utils";
import Badge from "../Badge";
import HidenBox from "../HidenBox";
import QrShowBox from "../QrShowBox";

const columnHelper = createColumnHelper();

function formatDate(value) {
  return moment(new Date(value.getValue())).format("DD/MM/YYYY");
}

function formatName(value) {
  const last_name = value?.last_name ? (value.last_name + " ") : "";
  const middle_name = value?.middle_name ? (value.middle_name + " ") : "";
  const first_name = value?.first_name ? value.first_name : "";
  return last_name + middle_name + first_name;
}

export const ProductColumns = (intl) => [
  columnHelper.accessor("barcode", {
    header: "Barcode",
  }),
  columnHelper.accessor("product_link", {
    header: intl.messages.information,
  }),
];

export const GinColumns = (intl) => [
  columnHelper.accessor("code", {
    header: intl.messages.ginId,
  }),
  columnHelper.accessor("name", {
    header: intl.messages.ginName,
  }),
  columnHelper.accessor("source", {
    header: intl.messages.source,
  }),
  columnHelper.accessor("harvest_date", {
    header: intl.messages.harvestDate,
    cell: (e) => {
      return formatDate(e);
    },
  }),
  columnHelper.accessor("created_at", {
    header: intl.messages.dateCreated,
    cell: (e) => {
      return formatDate(e);
    },
  }),
  columnHelper.accessor("qr_code.own_by_user", {
    header: intl.messages.enterersName,
    cell: (e) => {
      const user_name_object = e.getValue();
      let name = user_name_object ? 
        formatName(user_name_object) :
        "Không có thông tin"
      return name;
    },
  }),
  columnHelper.accessor("qr_code.status.code", {
    header: intl.messages.statusGin,
    cell: (e) => {
      let statusCode = e.getValue();
      let status = statusCode === "INAPPROVED" ?
        "Chờ Duyệt" :
        statusCode === "APPROVED" ?
        "Đã Duyệt" :
        statusCode === "SOLD" ?
        "Đã Bán" : "Không có thông tin"
      return status;
    },
  }),
];

export const QrCodeColumns = (intl) => [
  columnHelper.accessor("value", {
    header: intl.messages.productId,

  }),
  columnHelper.accessor("value", {
    header: intl.messages.qrCode,
    cell: (e) => (
      <QrShowBox data={e.row.original.value} content={<QrCodeScanner />} />
    ),
    meta: {
      align: "center",
      class: "fit-content",
    },
  }),
  columnHelper.accessor("created_at", {
    header: intl.messages.dateCreated,
    cell: (e) => {
      return formatDate(e);
    },
  }),
];
export const ArticleColumns = (intl) => [
  columnHelper.accessor("title", {
    header: intl.messages.title,
    cell: (e) => Utils.truncateString(e.getValue(), 40),
  }),
  columnHelper.accessor("newspaper_office_name", {
    header: intl.messages.link,
    cell: (e) => (
      <a className="btn-link" href={e.getValue()}>
        {Utils.truncateString(e.getValue(), 40)}
      </a>
    ),
  }),
  columnHelper.accessor("display", {
    header: intl.messages.status,
    meta: {
      align: "center",
      class: "fit-content",
    },
    cell: (e) => (
      <Badge
        trueIntl={intl.messages.showing}
        falseIntl={intl.messages.hidden}
        color={"blue"}
        isAvailable={e.getValue()}
      />
    ),
  }),
  columnHelper.accessor("created_at", {
    header: intl.messages.dateCreated,
    cell: (e) => {
      return formatDate(e);
    },
  }),
];

export const CertificateColumns = (intl) => [
  columnHelper.accessor("name", {
    header: intl.messages.name,
  }),
  columnHelper.accessor("file_path", {
    header: intl.messages.fileName,
    cell: (e) => (
      <a
        className="btn-link"
        href={Config.Image_Url + '/' + e.getValue().substring(1)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {Utils.getLastSplit(e.getValue(), "/")}
      </a>
    ),
  }),
  columnHelper.accessor("description", {
    header: intl.messages.desc,
  }),
  columnHelper.accessor("created_at", {
    header: intl.messages.dateCreated,
    cell: (e) => {
      return formatDate(e);
    },
  }),
];

export const LocationColumns = (intl) => [
  columnHelper.accessor("address", {
    header: intl.messages.address,
  }),
  columnHelper.accessor("description", {
    header: intl.messages.desc,
  }),
  columnHelper.accessor("created_at", {
    header: intl.messages.dateCreated,
    cell: (e) => {
      return formatDate(e);
    },
  }),
];
