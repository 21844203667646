import AdminPage from "pages/Admin/Component/HeaderSidebar";
import Table from "pages/Admin/Component/Table";
import AddMoreQRCodeModal from "./subViews/AddMoreQRModal";
import QrCodeModal from "./subViews/QrCodeModal";

function convertToBoolean(input) {
  try {
    return JSON.parse(input.toLowerCase());
  }
  catch (e) {
    return undefined;
  }
}
function QrCodeMainView(props) {
  const {
    intl,
    keyword,
    setKeyword,
    handleEditClick,
    handleDeleteClick,
    openDialog,
    handleAddClick,
    handleCloseDialog,
    isAdd,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    handleSubmit,
    initialValues,
    columns,
    openAddMoreQrCode,
    handleCloseAddMoreDialogQR,
    params,
    handlePrintClick,
    printRef,
    onSubmitAddMoreQRCode,

  } = props;
  return (
    <AdminPage
      header={convertToBoolean(params) ? intl.messages.listQRPrinted : intl.messages.listQRNotYetPrinted}
      subHeader={`${tableData.count} ${intl.messages.units}`}
      userName="Admin"
      handleAddClick={handleAddClick}
      onChangeSearch={(e) => setKeyword(e)}
      displayPrinter={!convertToBoolean(params)}
      showAddNewButton={!convertToBoolean(params)}
      handlePrintClick={handlePrintClick}
    >
      <Table
        columns={columns}
        // data={convertToBoolean(params) == true ? tableNotYetPrintedData.data : tablePrintedData.data}
        data={tableData.data}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        keyword={keyword}
        pageSize={pageSize}
        currentPage={currentPage}
        handleChangePageSize={(e) => setPageSize(e)}
        totalPage={tableData.total}
        handleChangeCurrentPage={(e) => setCurrentPage(e)}
        showActionColumn={!convertToBoolean(params) == true}
        showEditButton={false}
      />
      <QrCodeModal
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        intl={intl}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        isAdd={isAdd}
      />

      <AddMoreQRCodeModal
        openDialog={openAddMoreQrCode}
        handleCloseDialog={handleCloseAddMoreDialogQR}
        intl={intl}
        handleSubmit={onSubmitAddMoreQRCode}
        initialValues={initialValues}
      />

      <div ref={printRef} id="print-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >

      </div>

    </AdminPage >
  );
}

export default QrCodeMainView;
