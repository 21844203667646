import React, { useEffect, useState } from "react";
import propsProvider from "./GinsengPropsProvider";
import { useIntl } from "react-intl";
import GinsengFormMainView from "./template/GinsengFormMainView";
import Config from "configuration";
import {
  insertGinsengAdmin,
  uploadGinsengFile,
} from "pages/Admin/Ginseng/GinsengSlice";
import Utils from "utils";

export default function GinsengFormContainer(props) {
  const { dispatch, productScan, productInfo, history } = props;
  const intl = useIntl();
  const initForm = [
    {
      name: "Tên sâm",
      value: "",
      type: "text",
      isError: false,
    },

    {
      name: "Ngày thu hoạch",
      value: "",
      type: "date",
      isError: false,
    },
    {
      name: "Năm tuổi",
      value: "",
      type: "text",
      isError: false,
    },
    {
      name: "Ngày cập nhật cuối cùng",
      value: "",
      type: "date",
      isError: false,
    },
    {
      name: "Size",
      value: "",
      type: "text",
      isError: false,
    },
    {
      name: "Cân nặng",
      value: "",
      type: "text",
      isError: false,
    },
    {
      name: "Nguồn gốc",
      value: "",
      type: "text",
      isError: false,
    },
    {
      name: "Tác dụng của Sâm",
      value: "",
      type: "text",
      isError: false,
    },
    {
      name: "Thông tin khác",
      value: "",
      type: "text",
      isError: false,
    },
    {
      name: "Tên người nhập",
      value: "",
      type: "text",
      isError: false,
    },
  ];
  const [ginsengForm, setGinsengForm] = useState(initForm);
  const [filesImage, setFilesImage] = useState([]);

  const [resultAddGinseng, setResultAddGinseng] = useState(undefined);
  //handle on change
  const handleOnchangeImage = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].type.split('/')[0] === 'image') {
        setFilesImage((prev) => [...prev, e.target.files[0]]);
      } else {
        Utils.handlePopup(
          intl.messages.requiredImageType,
          'error',
        );
      }

    }
  };
  const handleRemoveImage = (e) => {
    const newFiles = filesImage.filter((item) => item !== e);
    setFilesImage(newFiles);
  };
  const handleImage = (item) => {
    if (item instanceof File) {
      return URL.createObjectURL(item);
    } else {
      return Config.Image_Url + item.file_path.substring(1);
    }
  };

  const onChangeValueForm = (index, value) => {
    const clone = JSON.parse(JSON.stringify([...ginsengForm]));
    clone[index].value = value;
    setGinsengForm([...clone]);
  };

  // handle submit form
  const handleSubmitForm = () => {
    const clone = JSON.parse(JSON.stringify([...ginsengForm]));
    let flag = false;
    ginsengForm.forEach((item, index) => {
      if (item.value.length === 0) {
        clone[index].isError = true;
        //alert(item.name + " has no value");
        flag = true;
        setGinsengForm([...clone]);
      }
    });
    return flag;
  };
  const onSubmitForm = async () => {

    if (handleSubmitForm()) {
      return;
    } else {
      let fileUpdated = [];
      if (filesImage.length > 0) {
        await filesImage.forEach(async (file, index) => {
          const formDataImage = new FormData();
          formDataImage.append("file", file);
          await dispatch(uploadGinsengFile(formDataImage)).then((response) => {
            const { status } = Utils.getValues(
              response,
              "payload",
              Config.FAIL
            );
            if (status === Config.SUCCESS) {
              const { responseData } = Utils.getValues(
                response,
                "payload",
                null
              );
              fileUpdated.push({ 
                id: responseData.id,
                file_path: responseData.file_path,
              });
              const queryPrams = Object.fromEntries(
                new URLSearchParams(window.location.search)
              );
              const { qr_id } = Utils.getValues(queryPrams, "", null);
              if (qr_id) {

                const payload = {
                  name: ginsengForm[0].value,
                  source: ginsengForm[6].value,
                  use_of_ginseng: ginsengForm[7].value,
                  other_information: ginsengForm[8].value,
                  years_of_ginseng: ginsengForm[2].value,
                  harvest_date: ginsengForm[1].value,
                  last_day_update: ginsengForm[3].value,
                  weight: ginsengForm[5].value,
                  size: ginsengForm[4].value,
                  images: fileUpdated,
                  qr_code_id: qr_id,
                };
                dispatch(insertGinsengAdmin(payload)).then((res) => {
                  const { status } = Utils.getValues(res, 'payload', Config.FAIL)
                  if (status === Config.SUCCESS) {
                    setResultAddGinseng(true);
                    return history.push('/scan')
                  } else {
                    setResultAddGinseng(false);
                  }
                });
              } else {
                setResultAddGinseng(false);
              }
            }
          });
        });
      }
    }
  };

  const onPressBack = () => {
    history.push("/scan")
  };

  useEffect(() => {
    if (resultAddGinseng !== undefined) {
      const animationRes = setTimeout(() => {
        setResultAddGinseng();
      }, 3000);
      return () => clearTimeout(animationRes);
    }
  }, [resultAddGinseng]);

  const fetchQrCodeInfo = async () => {
    const queryPrams = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    const qrID = queryPrams.qr_id;
    if (qrID) {
    } else {
      return history.push("/empty");
    }
  };
  useEffect(() => {
    fetchQrCodeInfo();
  }, []);

  const GinsengFormContainerProps = {
    intl,
    filesImage,
    ginsengForm,
    resultAddGinseng,
    onChangeValueForm,
    handleOnchangeImage,
    handleRemoveImage,
    handleImage,
    onSubmitForm,
    onPressBack,
  };
  return <GinsengFormMainView {...propsProvider(GinsengFormContainerProps)} />;
}
