import { productAdminSelector, qrCodeSelector } from "app/selectors";
import DecorateContainer from "components/DecorateContainer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import i18nLabels from "./QrCode.i18n";
import QrCodeContainer from "./QrCodeContainer";


export default function QrCode(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const product = useSelector(productAdminSelector);
  const qrCode = useSelector(qrCodeSelector);
  const { productAdminList = {} } = product;
  const { lstQrCode = [] } = qrCode
  const windowUrl = window.location.search;
  const params = windowUrl && windowUrl.split('?active=')[1]
  const qrCodeProps = {
    ...props,
    dispatch,
    i18nLabels,
    history,
    productAdminList,
    lstQrCode,
    params
  };
  return (
    <DecorateContainer {...qrCodeProps} childComponent={QrCodeContainer} />
  );
}
