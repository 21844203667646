import React from "react";
import i18nLabels from "./Scan.i18n";
import { useHistory } from "react-router-dom";
import DecorateContainer from "components/DecorateContainer";
import ScanContainer from "./ScanContainer";
import { useDispatch } from "react-redux";

export default function Scan(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const scanProps = {
    ...props,
    dispatch,
    i18nLabels,
    history,
  };
  return <DecorateContainer {...scanProps} childComponent={ScanContainer} />;
}
