import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productScan } from "api/productApi";
import { qrCodeApi } from "api/qrCodeApi";

export const getProductScan = createAsyncThunk(
  "product/ScanQrCode",
  async (params) => {
    const res = await productScan.getProductByQrCode(params);
    return res;
  }
);
export const getProductInfo = createAsyncThunk(
  "/product/getInformation",
  async (payload) => {
    const res = await productScan.getInfoOfProductByProductId(payload);
    return res;
  }
);

export const userScanQR = createAsyncThunk(
  "/qrcode/userScanQR",
  async (payload) => {
    const res = await qrCodeApi.userScanQR(payload);
    return res;
  }
)

export const guestScanQR = createAsyncThunk(
  "/qrcode/guestScanQR",
  async (payload) => {
    const res = await qrCodeApi.guestScanQR(payload);
    return res;
  }
)

const product = createSlice({
  name: "product",
  initialState: {
    productScan: {},
    scanError: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductScan.fulfilled, (state, action) => {
        const { status, responseData } = action.payload;
        if (status === "success") {
          state.productScan = responseData;
        }
      })
      .addCase(getProductScan.rejected, (state, action) => {
        const { status, responseData } = action.payload;
        if (status === "success") {
          state.scanError = responseData;
        }
      })
      .addCase(getProductInfo.fulfilled, (state, action) => {
        const { status, responseData } = action.payload;
        if (status === "success") {
          state.productInfo = responseData;
        }
      })
  },
});

const { reducer } = product;
export default reducer;
