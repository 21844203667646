import store from 'app/store';
import { redirectionRuleList, routeList } from 'configuration/routeConfig';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import './_styles.scss';
import Loading from 'components/Loading';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <IntlProvider locale='vi'>
            <Loading/>
            <Router>
                <Switch>
                    {redirectionRuleList.map((rule, idx) => (
                        <Redirect
                            key={`redirect-${idx}`}
                            exact
                            from={rule.from}
                            to={rule.to}
                        />
                    ))}
                    {routeList.map((route, idx) => (
                        <Route
                            key={`route-${idx}`}
                            exact
                            path={route.path}
                            component={route.component}
                        />
                    ))}
                </Switch>
            </Router>
        </IntlProvider>
    </Provider>,
);
