import { ImageBackgroundSignIn } from 'assets/images';

export default function SignInForm(props) {
    const { children, formTitle, smallPadding } = props;

    return (
        <div
            className='signin-container'
            style={{ backgroundImage: `url(${ImageBackgroundSignIn})` }}
        >
            <div
                className={`signin-content ${smallPadding && 'small-padding'}`}
            >
                <h2>{formTitle}</h2>
                {children}
            </div>
        </div>
    );
}
