import AdminPage from "pages/Admin/Component/HeaderSidebar";
import Table from "pages/Admin/Component/Table";
import ProductModal from "./subViews/ProductModal";
import { Dialog } from "@mui/material";

function ProductMainView(props) {
  const {
    intl,
    keyword,
    setKeyword,
    handleEditClick,
    handleDeleteClick,
    handleAddClick,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    columns,
    openDialogQR,
    handleCloseDialogQR,
    qrCodeBase64,
  } = props;

  return (
    <AdminPage
      header={intl.messages.tableTitle}
      subHeader={`${tableData.count} ${intl.messages.units}`}
      userName="Admin"
      handleAddClick={handleAddClick}
      onChangeSearch={(e) => setKeyword(e)}
    >
      <Table
        columns={columns}
        data={tableData.data}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        keyword={keyword}
        pageSize={pageSize}
        currentPage={currentPage}
        handleChangePageSize={(e) => setPageSize(e)}
        totalPage={tableData.total}
        handleChangeCurrentPage={(e) => setCurrentPage(e)}
      />
      <ProductModal {...props}/>
      <Dialog
        open={openDialogQR}
        PaperProps={{ sx: { borderRadius: "12px" } }}
        onClose={handleCloseDialogQR}
      >
        <img height={"400px"} src={qrCodeBase64} alt="qr" />
      </Dialog>
      <div id="renderQR" />
    </AdminPage>
  );
}

export default ProductMainView;
