import React from "react";
import Header from "components/Header";
import Utils from "utils";
export default function MoreInfoMainView(props) {
  const { intl, productInfo, history, ginsengInfoByCode } = props;
  return (
    <div className="moreInfo-container">
      <Header
        headerTitle={intl.messages.Effect}
        isWhite={false}
        onPress={() =>
          Utils.onBackProductDiscovery(history, ginsengInfoByCode?.qr_code?.value)
        }
      />
      <ul className="content">
        {ginsengInfoByCode && ginsengInfoByCode?.other_information?.split("\n").map((str) => (
          <li className="moreInfo-paragraph">{str}</li>
        ))}
      </ul>
    </div>
  );
}
