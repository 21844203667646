import { GifSuccess, GifFail } from "assets/gif";

function Success({ isSuccess, intl }) {
    return (<div className="bgSuccess">
        <img src={isSuccess ? GifSuccess : GifFail} alt={isSuccess ? 'success' : 'error'} />
        <span>{isSuccess ? intl.messages.addSuccess : intl.messages.addFail}</span>

    </div>);


}

export default Success;