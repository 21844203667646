import Config from "configuration";
import Utils from "../../../../utils/index";
function CardViewNews({ newImage, newOfficeName, newTitle, onClick }) {
  return (
    <div className="news-content__list" onClick={onClick}>
      <img
        src={Config.Image_Url + newImage}
        alt={newImage}
        className="news-image"
      />
      <hr className="news-line" />
      <span className="news-title">{newTitle}</span>
      <span className="news-name">{newOfficeName}</span>
    </div>
  );
}

export default CardViewNews;
