import { hideLoading, showLoading } from "components/Loading/LoadingSlice";
import { ProductColumns } from "pages/Admin/Component/Table/TableColumnsConfig";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Utils from "utils";
import useTableProperties from "../Component/Table/useTableProperties";
import propsProvider from "./ProductPropsProvider";
import {
  deleteProductAdmin,
  getListProductAdmin,
  getQrCode,
  getUnit,
  updateProductAdmin,
  insertProductAdminByFile,
} from "./ProductSlice";
import ProductMainView from "./template/ProductMainView";
import moment from "moment";
import Config from "configuration";
import {
  getListGinsengAdmin,
} from "../Ginseng/GinsengSlice";

function ProductContainer(props) {
  const { dispatch, productAdminList } = props;

  const [keyword, setKeyword] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const intl = useIntl();
  const columns = ProductColumns(intl);
  const [openDialog, setOpenDialog] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [filesExcel, setFilesExcel] = useState({});
  const [ginseng, setGinseng] = useState("");
  const [productData, setProductData] = useState({});
  const [openDialogQR, setOpenDialogQR] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [qrCodeBase64, setQrCodeBase64] = useState("");
  const [unit, setUnit] = useState([]);
  const payload = {
    currentPage: currentPage,
    pageSize: pageSize,
    sortField: "created_at",
    sortOrder: "desc",
    keyword: keyword,
  };
  const tableData = useTableProperties(productAdminList);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize, keyword]);

  useEffect(() => {
    dispatch(showLoading());
    const delayDebounceFn = setTimeout(async () => {
      const payloadGinseng = {
        currentPage: 1,
        pageSize: 10,
        sortField: "created_at",
        sortOrder: "desc",
      };
      const [productResult, ginsengResult] = await Promise.all([
        dispatch(getListProductAdmin(payload)),
        dispatch(getListGinsengAdmin(payloadGinseng)),
      ]);
      const selectOptions = [];
      const totalGinseng = Utils.getValues(
        ginsengResult,
        "payload.responseData.count",
        0
      );
      const ginsengRows = Utils.getValues(
        ginsengResult,
        "payload.responseData.rows",
        []
      );
      const unit = await dispatch(getUnit());
      setUnit(Utils.getValues(unit, "payload.responseData", []));
      if (totalGinseng > 10) {
        payloadGinseng.pageSize = totalGinseng;
        const newGinsengResult = await dispatch(
          getListGinsengAdmin(payloadGinseng)
        );
        const newGinsengRows = Utils.getValues(
          newGinsengResult,
          "payload.responseData.rows",
          []
        );
        newGinsengRows.forEach((item) => {
          selectOptions.push({ value: item.id, label: item.name });
        });
      } else {
        ginsengRows.forEach((item) => {
          selectOptions.push({ value: item.id, label: item.name });
        });
      }
      setSelectOptions(selectOptions);
      dispatch(hideLoading());
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [pageSize, keyword, currentPage, dispatch, updateData]);

  const handleAddClick = () => {
    setStateHandle(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogQR = () => {
    setOpenDialogQR(false);
  };
  const setStateHandle = (isAdd = false) => {
    setIsAdd(isAdd);
    setOpenDialog(true);
  };
  const handleSubmit = async (data) => {
    if (handleValidation(data)) return;
    dispatch(showLoading());

    let status = Config.FAIL;
    if (isAdd) {
      try {
        const upLoadFile = new FormData();
        upLoadFile.append("file", filesExcel);

        const uploadFileResult = await dispatch(insertProductAdminByFile(upLoadFile));
        status = Utils.getValues(uploadFileResult, "payload.status", Config.FAIL);

      } catch (error) {}
    } else {
      const product = {
        barcode: data.barcode,
        product_link: data.product_link,
      };

      try {
        const updateProduct = {
          id: data.id,
          product: product,
        };
        const result = await dispatch(updateProductAdmin(updateProduct));
        status = Utils.getValues(result, "payload.status", Config.FAIL);
      } catch (error) {
        Utils.handlePopup(
          isAdd ? intl.messages.addFail : intl.messages.updateFail,
          "error"
        );
      }
    }
    if (status === Config.SUCCESS) {
      setUpdateData(!updateData);
      Utils.handlePopup(
        isAdd ? intl.messages.addSuccess : intl.messages.updateSuccess,
        "success"
      );
    } else {
      Utils.handlePopup(
        isAdd ? intl.messages.addFail : intl.messages.updateFail,
        "error"
      );
    }
    dispatch(hideLoading());
    setGinseng("");
    setOpenDialog(false);
  };

  const handleEditClick = async (e) => {
    setProductData(e);
    setStateHandle(false);
  };

  const handleDeleteClick = async (e) => {
    const result = await Utils.handleConfirmDelete(intl);
    if (result.isConfirmed) {
      dispatch(showLoading());
      await dispatch(deleteProductAdmin(e.data.id)).then((result) => {
        const status = Utils.getValues(result, "payload.status", null);
        if (status === Config.SUCCESS) {
          setUpdateData(!updateData);
          Utils.handlePopup(intl.messages.deleteSuccess, "success");
        } else {
          Utils.handlePopup(intl.messages.deleteFail, "error");
        }
      });
      setUpdateData(!updateData);
      dispatch(hideLoading());
    }
  };

  const handleValidation = (data) => {
    if (isAdd) {
      if (!filesExcel.name) {
        Utils.handlePopup(intl.messages.requiredFile, "error");
        return true;
      }
    } else {
      if (!data.barcode) {
        Utils.handlePopup(intl.messages.requiredCode, "error");
        return true;
      }
      if(!data.product_link) {
        Utils.handlePopup(intl.messages.requiredGinseng, "error");
        return true;
      }
    }
    return false;
  };

  const initialValues = {
    name: "",
    qr_code: "",
    created_at: Utils.formatDateYDM(moment()),
    manufactureDate: "",
    unit_value: "",
    description: "",
    other_information: "",
    ginseng_id: "",
    checkbox: false,
    qr: "",
  };

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      height: "30px",
      minHeight: "30px",
      "&:hover": {
        border: "0 !important",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0",
    }),
    input: (base) => ({
      ...base,
      margin: "0",
    }),
  };

  const handleOnChangeExcel = (e) => {
    if (e.target.files[0]) {
      setFilesExcel(e.target.files[0]);
    }
  };

  const handleExcel = (item) => {
      return item.name;
  };

  const handleRemoveExcel = (e) => {
    setFilesExcel({});
  };

  const handleSelectGinseng = (data) => {
    setGinseng(data.value);
  };

  const showQrImage = async (qr, code) => {
    let base64 = qr;
    if (isAdd) {
      if (!code) {
        return Utils.handlePopup(intl.messages.enterCodeGetQR, "error");
      }
      dispatch(showLoading());
      const result = await dispatch(getQrCode(code));
      base64 = Utils.getValues(result, "payload.responseData", "");
    }
    setQrCodeBase64(base64);
    dispatch(hideLoading());
    setOpenDialogQR(true);
  };

  const productContainerProps = {
    ...props,
    intl,
    keyword,
    setKeyword,
    handleEditClick,
    handleDeleteClick,
    openDialog,
    handleAddClick,
    handleCloseDialog,
    isAdd,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    handleSubmit,
    initialValues,
    columns,
    selectStyle,
    selectOptions,
    handleSelectGinseng,
    productData,
    showQrImage,
    openDialogQR,
    handleCloseDialogQR,
    qrCodeBase64,
    unit,
    filesExcel,
    handleOnChangeExcel,
    handleExcel,
    handleRemoveExcel,
  };
  return <ProductMainView {...propsProvider(productContainerProps)} />;
}

export default ProductContainer;
