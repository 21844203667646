import React, { useState } from "react";
import ScanMainView from "./template/ScanMainView";
import propsProvider from "./ScanPropsProvider";
import { guestScanQR, userScanQR } from "./ScanSlice";
import { useIntl } from "react-intl";
import Utils from "utils";
import Config from "configuration";
function ScanContainer(props) {
  const { history, dispatch } = props;
  const intl = useIntl();

  const fetchQRScan = async (code) => {
    try {
      const res = await dispatch(userScanQR({
        code: code,
      }))
      return res.payload;
    } catch (err) {
      console.log(err)
    }
  }

  const handleScan = async (result) => {
    const url = result?.data || "";
    const urlArray = url.split("=");
    const code = urlArray[1]

    const res = await fetchQRScan(code);

    if (!res.responseData.status) {
      const ginsengCode = res.responseData.code;
      const { qr_code } = Utils.getValues(res, 'responseData', null)
      if (qr_code) {
        const { code } = Utils.getValues(res, 'responseData.qr_code.status', "NONE")
        code !== Config.ginsengStatus.NONE ? history.push(`/product?code=${ginsengCode}`) : history.push(`/ginseng/form?qr_id=${res.responseData.id}`)
      } else {
        handScanError()
      }
    } else {
      handScanError()
    }

  };
  const handScanError = () => {
    // history.push("/empty");
  };

  const scanContainerProps = {
    ...props,
    intl,
    handleScan,
    handScanError,
  };
  return <ScanMainView {...propsProvider(scanContainerProps)} />;
}

export default ScanContainer;
