import { GrayQRCode } from "assets/icon";
import DialogModal from "components/DialogModal";
import { Field } from "formik";
import Select from "react-select";
function QrCodeModal({
  openDialog,
  handleCloseDialog,
  intl,
  handleSubmit,
  initialValues,
  isAdd,
}) {
  return (
    <DialogModal
      openDialog={openDialog}
      handleCloseDialog={handleCloseDialog}
      handleSubmit={handleSubmit}
      headerTitle={
        isAdd ? intl.messages.createNewQRCode : intl.messages.printQRCode
      }
      submitName={isAdd ? intl.messages.add : intl.messages.print}
      cancelName={intl.messages.cancel}
      initialValues={initialValues}
    >
      <div className="sub-header">{intl.messages.generalInformation}</div>
      <div style={{ display: "flex" }}>
        <div
          className="input-container"
          style={{ marginRight: "16px", width: "47%" }}
        >
          <Field
            className="input-container__input"
            id="quantityQRCode"
            name="quantityQRCode"
            type="number"
            placeholder={"1"}
          />
          <label
            htmlFor="quantityQRCode"
            className="input-container__title required"
          >
            {intl.messages.quantityQRCode}
          </label>
        </div>
      </div>
    </DialogModal>
  );
}

export default QrCodeModal;
