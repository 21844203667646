import SignInButton from '../../Component/SignInButton/index';
import SignInForm from '../../Component/SignInForm/index';
import { SignInInput } from '../../Component/SignInput/index';

export default function ResetPwMainView(props) {
    const { intl, onSubmitClick, formik, errorMessage } = props;
    return (
        <SignInForm formTitle={intl.messages.ResetPwTitle}>
            <form className='form-body'>
                <SignInInput
                    type='password'
                    name='password'
                    label={intl.messages.NewPasswordLabel}
                    placeholder={intl.messages.NewPasswordPlaceholder}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    message={errorMessage.password}
                />
                <SignInInput
                    type='password'
                    name='re_password'
                    label={intl.messages.RePassword}
                    placeholder={intl.messages.RePasswordPlaceholder}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.re_password}
                    message={errorMessage.re_password}
                />
                <SignInButton
                    variable='button'
                    label={intl.messages.SubmitLabel}
                    onClick={onSubmitClick}
                />
            </form>
        </SignInForm>
    );
}
