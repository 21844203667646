import React from "react";
import { useDispatch, useSelector } from "react-redux";
import i18nLabels from "./GinsengForm.i18n";
import DecorateContainer from "components/DecorateContainer";
import { useHistory } from "react-router-dom";
import GinsengFormContainer from "./GinsengFormContainer";
import { ginsengAdminSelector } from "app/selectors";
export default function GinsengForm(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const infoGinseng = useSelector(ginsengAdminSelector)

  const { ginsengInfoByCode = {} } = infoGinseng
  const productProps = {
    ...props,
    i18nLabels,
    history,
    dispatch,

  };
  return (
    <DecorateContainer
      {...productProps}
      childComponent={GinsengFormContainer}
    />
  );
}
