const labels = {
  vi: {
      news: "Bài báo",
  },
  en: {
      news: "News",
  },
};

export default labels;
