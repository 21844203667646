import NotFound from "components/NotFound";
import Address from "pages/Address";
import AdminArticle from "pages/Admin/Article";
import AdminCertificate from "pages/Admin/Certificate";
import CreateProductComp from "pages/Admin/Component/CreateProductComp";
import ForgetPw from "pages/Admin/ForgetPw";
import AdminGinseng from "pages/Admin/Ginseng";
import AdminLocation from "pages/Admin/Location";
import AdminProduct from "pages/Admin/Product";
import AdminQrCode from "pages/Admin/QrCode";
import ResetPw from "pages/Admin/ResetPw";
import SignIn from "pages/Admin/SignIn";
import Success from "pages/Admin/Success";
import VerifiedCode from "pages/Admin/VerifiedCode";
import Certificates from "pages/Certificates";
import CertificatesDetail from "pages/CertificatesDetail";
import Empty from "pages/Empty";
import MoreInfo from "pages/MoreInfo";
import News from "pages/News";
import Product from "pages/Product";
import Scan from "pages/Scan";
import GinsengForm from "pages/GinsengForm";
const scanUrl = "/scan";
const emptyURL = "/empty";
const newsURL = "/news";
const productUrl = "/product";
const addressUrl = "/address";
const moreInfoUrl = "/moreInfo";
const certificatesUrl = "/certificates";
const certificatesDetailUrl = "/certificates/:id";
const ginsengFormUrl = "/ginseng/form";

const adminUrl = "/admin";
const signInUrl = `${adminUrl}/sign-in`;
const forgetPwUrl = `${adminUrl}/forget-pw`;
const veriviedCodePwUrl = `${adminUrl}/verified-code`;
const resetPwUrl = `${adminUrl}/reset-pw`;
const successUrl = `${adminUrl}/success`;
const createProductComp = `${adminUrl}/create-product`;

const ginsengAdminUrl = `${adminUrl}/ginseng`;
const productAdminUrl = `${adminUrl}/product`;
const qrCodeAdminUrl = `${adminUrl}/qrcode`;
const articleAdminUrl = `${adminUrl}/article`;
const certificateAdminUrl = `${adminUrl}/certificate`;
const locationAdminUrl = `${adminUrl}/address`;

const rootUrl = "/";
const notFoundUrl = "/*";
export const routeList = [
  { path: scanUrl, component: Scan },
  { path: emptyURL, component: Empty },
  { path: newsURL, component: News },
  { path: productUrl, component: Product },
  { path: addressUrl, component: Address },
  { path: moreInfoUrl, component: MoreInfo },
  { path: certificatesUrl, component: Certificates },
  { path: certificatesDetailUrl, component: CertificatesDetail },
  { path: signInUrl, component: SignIn },
  { path: adminUrl, component: SignIn },
  { path: forgetPwUrl, component: ForgetPw },
  { path: veriviedCodePwUrl, component: VerifiedCode },
  { path: resetPwUrl, component: ResetPw },
  { path: successUrl, component: Success },
  { path: createProductComp, component: CreateProductComp },
  { path: ginsengAdminUrl, component: AdminGinseng },
  { path: productAdminUrl, component: AdminProduct },
  { path: qrCodeAdminUrl, component: AdminQrCode },
  { path: articleAdminUrl, component: AdminArticle },
  { path: certificateAdminUrl, component: AdminCertificate },
  { path: locationAdminUrl, component: AdminLocation },
  { path: ginsengFormUrl, component: GinsengForm },
  // Keep "Not found page" the last one
  { path: notFoundUrl, component: NotFound },
];

export const redirectionRuleList = [{ from: rootUrl, to: scanUrl }];

export const pagePath = {
  rootUrl,
  moreInfoUrl,
  certificatesUrl,
  certificatesDetailUrl,
  signInUrl,
  ginsengAdminUrl,
  forgetPwUrl,
  veriviedCodePwUrl,
  resetPwUrl,
  successUrl,
};
