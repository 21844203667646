import { hideLoading, showLoading } from 'components/Loading/LoadingSlice';
import Config from 'configuration';
import moment from 'moment';
import { LocationColumns } from 'pages/Admin/Component/Table/TableColumnsConfig';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Utils from 'utils';
import useTableProperties from '../Component/Table/useTableProperties';
import propsProvider from './LocationPropsProvider';
import {
    deleteLocationAdmin,
    getListLocationAdmin,
    insertLocationAdmin,
    updateLocationAdmin,
} from './LocationSlice';
import LocationMainView from './template/LocationMainView';

function LocationContainer(props) {
    const { dispatch, locationAdminList } = props;

    const [keyword, setKeyword] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [data, setData] = useState(locationAdminList);
    const [openDialog, setOpenDialog] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [addressData, setAddressData] = useState({});
    const [isAdd, setIsAdd] = useState(true);
    const intl = useIntl();
    const columns = LocationColumns(intl);

    const payload = {
        currentPage: currentPage,
        pageSize: pageSize,
        sortField: 'created_at',
        sortOrder: 'desc',
        keyword: keyword,
    };

    const tableData = useTableProperties(data);

    useEffect(() => {
        setCurrentPage(1);
    }, [pageSize, keyword]);

    useEffect(() => {
        dispatch(showLoading());
        const delayDebounceFn = setTimeout(() => {
            dispatch(getListLocationAdmin(payload)).then((result) => {
                setData(Utils.getValues(result, 'payload.responseData', []));
                dispatch(hideLoading());
            });
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [pageSize, keyword, currentPage, dispatch, updateData]);

    const handleAddClick = () => {
        setStateHandle(true, {});
    };

    const handleEditClick = (e) => {
        setStateHandle(false, e);
    };
    const handleDeleteClick = async (e) => {
        const result = await Utils.handleConfirmDelete(intl);
        if (result.isConfirmed) {
            dispatch(showLoading());
            await dispatch(deleteLocationAdmin(e.data.id)).then((result) => {
                const status = Utils.getValues(result, 'payload.status', Config.FAIL);
                if (status === Config.SUCCESS) {
                    setUpdateData(!updateData);
                    Utils.handlePopup(intl.messages.deleteSuccess, 'success');
                } else {
                    Utils.handlePopup(intl.messages.deleteFail, 'error');
                }
            });
            dispatch(hideLoading());
        }
    };

    const setStateHandle = (isAdd = false, dataAddress = {}) => {
        setIsAdd(isAdd);
        setAddressData(dataAddress);
        setOpenDialog(true);
    };

    const handleSubmit = async (data) => {
        if (!data.address.trim()) {
            Utils.handlePopup(intl.messages.requiredAddress, 'error');
            return;
        }
        dispatch(showLoading());
        let status = Config.FAIL;
        const payload = {
            address: data.address,
            description: data.description,
            created_at: moment(data.created_at, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss.SSSZ"),
        };
        if (isAdd) {
            await dispatch(insertLocationAdmin(payload)).then((result) => {
                status = Utils.getValues(result, 'payload.status', Config.FAIL);
            });
        } else {
            await dispatch(updateLocationAdmin({ id: data.id, payload })).then((result) => {
                status = Utils.getValues(result, 'payload.status', Config.FAIL);
            });
        }
        if (status === Config.SUCCESS) {
            setUpdateData(!updateData);
            Utils.handlePopup(
                isAdd ? intl.messages.addSuccess : intl.messages.updateSuccess,
                'success',
            );
        } else {
            Utils.handlePopup(isAdd ? intl.messages.addFail : intl.messages.updateFail, 'error');
        }
        dispatch(hideLoading());
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const initialValues = {
        address: '',
        created_at: Utils.formatDateYDM(moment()),
        description: '',
    };

    const locationContainerProps = {
        ...props,
        intl,
        data,
        columns,
        openDialog,
        initialValues,
        keyword,
        addressData,
        setKeyword,
        handleEditClick,
        handleDeleteClick,
        handleAddClick,
        handleCloseDialog,
        handleSubmit,
        isAdd,
        tableData,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
    };
    return <LocationMainView {...propsProvider(locationContainerProps)} />;
}

export default LocationContainer;
