import DecorateContainer from 'components/DecorateContainer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Utils, { ForgetPwProcess } from 'utils';
import i18nLabels from './ResetPw.i18n';
import ResetPwContainer from './ResetPwContainer';

export default function ResetPw(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    // const infoSignin = useSelector(signInSelector);
    const resetPwProps = {
        ...props,
        i18nLabels,
        history,
        dispatch,
    };
    if (Utils.protectLine(ForgetPwProcess.CreateNewPw)) {
        return <DecorateContainer {...resetPwProps} childComponent={ResetPwContainer} />;
    }
}
