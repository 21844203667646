const labels = {
    vi: {
        title: 'Tên báo',
        link: 'Link bài báo',
        status: 'Trạng thái hiển thị',
        dateCreated: 'Ngày tạo',
        tableTitle: 'Danh sách bài báo',
        unit: 'bài báo',
        showing: 'Đang hiển thị',
        hidden: 'Đã ẩn',
        avatar: 'Hình đại diện',
        add: 'Thêm',
        cancel: 'Hủy',
        update: 'Cập nhật',
        createArticle: 'Tạo mới bài báo',
        requiredTitle: 'Tên không được để trống',
        requiredLink: 'Link không được để trống',
        requiredDate: 'Ngày không được để trống',
        requiredAvatar: 'Hình không được để trống',
        requiredImageType: 'File phải là dạng hình ảnh',
        updateArticle: 'Chỉnh sửa bài báo',
        chooseFile: 'Chọn tệp',
        nameArticle: 'Tên bài báo',
        inputNameArticle: 'Nhập tên bài báo',
        desc: 'Mô tả',
        inputDesc: 'Nhập mô tả',
        inputLink: 'Nhập đường dẫn',
        addSuccess: 'Thêm bài báo thành công',
        addFail: 'Thêm bài báo thất bại',
        updateSuccess: 'Chỉnh sửa thành công',
        updateFail: 'Chỉnh sửa thất bại',
        deleteSuccess: 'Xoá bài báo thành công!',
        deleteFail: 'Xoá bài báo thất bại!',
        ConfirmDeleteTitle: "Bạn có muốn xoá dữ liệu",
        ConfirmDeleteText: "Vui lòng xác nhận",
        ConfirmDeleteYes: "Xoá",
        ConfirmDeleteNo: "Huỷ",
    },
    en: {
        title: 'Article name',
        link: 'Link of articles',
        dateCreated: 'Date created',
        status: 'Display status',
        tableTitle: 'Article list',
        unit: 'articles',
        showing: 'Showing',
        hidden: 'Hidden',
        avatar: 'Avatar',
        add: 'Add',
        cancel: 'Cancel',
        update: 'Update',
        createNew: 'Create new Article',
        createArticle: 'Create new Article',
        updateArticle: 'Update Article',
        chooseFile: 'Choose file',
        requiredTitle: 'Title is required',
        requiredLink: 'Link is required',
        requiredDate: 'Date is required',
        requiredAvatar: 'Avatar is required',
        requiredImageType: 'The file must be an image file',
        nameArticle: 'Name article',
        inputNameArticle: 'input article',
        desc: 'Description',
        inputDesc: 'Input description',
        inputLink: 'Input link',
        addSuccess: 'Add article success',
        addFail: 'Add article fail',
        updateSuccess: 'Update article success',
        updateFail: 'Update article fail',
        deleteSuccess: 'Delete article success!',
        deleteFail: 'Delete article Fail!',
        ConfirmDeleteTitle: "You want delete data",
        ConfirmDeleteText: "Please confirm",
        ConfirmDeleteYes: "Delete",
        ConfirmDeleteNo: "Cancel",
    },
};

export default labels;
