const enviromentConfig = {
  development: {
    endPoint:
      "https://gateway.dev.meu-solutions.com/ngoclinhscanging/api/v1.0/",
      // "http://27.74.255.96:5004/ngoclinhscanging/api/v1.0/"
  },
  production: {
    endPoint: "https://sapatco.com/api",
  },
};

export default enviromentConfig;
