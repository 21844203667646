const labels = {
  vi: {
    Ginseng: "Sâm",
    Product: "Sản phẩm",
    ListInfo: "Danh sách thông tin",
    Certificate: "Giấy chứng nhận",
    Article: "Bài báo",
    Address: "Địa chỉ",
    AddNew: "Thêm mới",
    Search: "Tìm kiếm",
    QrCode: "Mã QR",
    ActiveQrCode: "QR đã in",
    InActiveQrCode: "QR chưa in",
    PrintQrCode: "In mã QR",
  },
  en: {
    Ginseng: "Sâm",
    Product: "Product",
    ListInfo: "List Information",
    Certificate: "Certificate",
    Article: "Article",
    Address: "Address",
    AddNew: "Add new",
    Search: "Search",
    QrCode: "QR Code",
    ActiveQrCode: "QR active",
    InActiveQrCode: "QR inactive",
    PrintQrCode: "Print QR Code",
  },
};

export default labels;
