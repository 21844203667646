const labels = {
    vi: {
        ForgetPwTitle: 'Quên mật khẩu',
        Message: 'Chúng tôi sẽ gửi một mã xác thực vào email của bạn để thiết lập lại mật khẩu!',
        EmailLabel: 'Email',
        EmailPlaceholder: 'Nhập email của bạn tại đây',
        SubmitLabel: 'Gửi',
        EmailNotBlank: 'Email không được bỏ trống',
        InvalidEmail: 'Tài khoản không tồn tại',
    },
    en: {
        ForgetPwTitle: 'Forget password',
        Message: 'We will give you a verification code through email to reset your password!',
        EmailLabel: 'Email',
        EmailPlaceholder: 'Enter your password here',
        SubmitLabel: 'Submit',
        EmailNotBlank: 'Email cannot be blank',
        InvalidEmail: 'Account does not exist',
    },
};

export default labels;
