import Utils from "utils";
import axiosClientAdmin from "./axiosClientAdmin";
import axiosClient from "./axiosClient";

const qrCodeApi = {
    getAllQrCode: (params) => {
        const url = `qrcode/getAll`;
        return axiosClientAdmin.get(url, { params });
    },
    addNewQRCode: ({ quantity }) => {
        return axiosClientAdmin.post(`qrcode/post/${quantity}`)
    },
    delete: ({ id }) => {
        return axiosClientAdmin.delete(`qrcode/delete/${id}`)
    },
    updatePrintStatus: ({ id }) => {
        return axiosClientAdmin.put(`qrcode/updatePrint/${id}`)
    },
    grantQR: ({code}) => {
        const url = `qrcode/grantQR/${code}`;
        return axiosClientAdmin.put(url);
      },
    getUserQR: () => {
        const url = `qrcode/getAllMyQR`;
        return axiosClientAdmin.get(url);
    },
    userScanQR: ({code}) => {
        const url = `qrcode/userScanQR/${code}`;
        return axiosClientAdmin.get(url);
    },
    approveQR: ({code}) => {
        const url = `qrcode/approve_qr/${code}`;
        return axiosClientAdmin.put(url);
    },
    guestScanQR: ({code}) => {
        const url = `qrcode/guestScanQR/${code}`;
        return axiosClient.get(url);
    }
};



export { qrCodeApi };
