import React, { useEffect, useState } from "react";
import { hideLoading, showLoading } from "components/Loading/LoadingSlice";
import propsProvider from "./CertificatesPropsProvider";
import CertificatesMainView from "./template/CertificatesMainView";
import { useIntl } from "react-intl";
import { getListCertificateAdmin } from "pages/Admin/Certificate/CertificateSlice";
import { getListCertificateByProductId } from "./CertificatesSlice";

export default function CertificatesContainer(props) {
  const { dispatch, home, ginsengInfoByCode } = props;
  const handleButtonClick = async () => { };
  const intl = useIntl();

  const [certificateList, setCertificateList] = useState([])

  const getAllCertificate = async () => {
    const res = await dispatch(getListCertificateAdmin());

    setCertificateList(res.payload.responseData.rows)
  }

  useEffect(() => {
    dispatch(showLoading());
    const delayDebounceFn = setTimeout(() => {
      getAllCertificate()
      dispatch(hideLoading());
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  const certificatesContainerProps = {
    ...props,
    certificateList,
    handleButtonClick,
    intl,
    ginsengInfoByCode
  };
  return (
    <CertificatesMainView {...propsProvider(certificatesContainerProps)} />
  );
}
