import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CertificateAdmin } from 'api/AdminApi';
import Config from 'configuration';
const initialCertificateAdminList = () => {
    if (sessionStorage.getItem(Config.storageKey.certificateAdmin)) {
        return {
            ...JSON.parse(sessionStorage.getItem(Config.storageKey.certificateAdmin)),
        };
    }
    return {
        certificateAdminList: [],
    };
};

export const getListCertificateAdmin = createAsyncThunk(
    'certificateAdmin/listCertificateAdmin',
    async (params) => {
        const res = await CertificateAdmin.getListCertificate(params);
        return res;
    },
);

export const insertCertificateAdmin = createAsyncThunk(
    'certificateAdmin/insertCertificateAdmin',
    async (params) => {
        const res = await CertificateAdmin.insertCertificate(params);
        return res;
    },
);

export const updateCertificateAdmin = createAsyncThunk(
    'certificateAdmin/updateCertificateAdmin',
    async (params) => {
        const res = await CertificateAdmin.updateCertificate(params);
        return res;
    },
);

export const deleteCertificateAdmin = createAsyncThunk(
    'certificateAdmin/deleteCertificateAdmin',
    async (params) => {
        const res = await CertificateAdmin.deleteCertificate(params);
        return res;
    },
);

const certificateAdmin = createSlice({
    name: 'certificateAdmin',
    initialState: initialCertificateAdminList(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListCertificateAdmin.fulfilled, (state, action) => {
                state.certificateAdminList = action.payload.responseData;
            })
            .addCase(getListCertificateAdmin.rejected, (state, action) => {});
    },
});

const { reducer } = certificateAdmin;
export default reducer;
