import { hideLoading, showLoading } from "components/Loading/LoadingSlice";
import Config from "configuration";
import moment from "moment";
import { GinColumns } from "pages/Admin/Component/Table/TableColumnsConfig";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Utils from "utils";
import useTableProperties from "../Component/Table/useTableProperties";
import propsProvider from "./GinsengPropsProvider";
import {
  deleteGinsengAdmin,
  deleteGinsengFile,
  getListGinsengAdmin,
  insertGinsengAdmin,
  updateGinsengAdmin,
  uploadGinsengFile,
  approveQR,
} from "./GinsengSlice";
import GinsengMainView from "./template/GinsengMainView";

function GinsengContainer(props) {
  const { dispatch, ginsengAdminList } = props;

  const [filterCodeStatus, setFilterCodeStatus] = useState("INAPPROVED")
  const [keyword, setKeyword] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(ginsengAdminList);
  const [openDialog, setOpenDialog] = useState(false);

  const [ginsengData, setGinsengData] = useState({});
  const [updateData, setUpdateData] = useState(false);
  const [filesImage, setFilesImage] = useState([]);
  const [filesCertificate, setFilesCertificate] = useState([]);
  const [imageRemove, setImageRemove] = useState([]);

  const [isEdit, setIsEdit] = useState(true);

  const intl = useIntl();
  const columns = GinColumns(intl);

  const payload = {
    currentPage: currentPage,
    pageSize: pageSize,
    sortField: "created_at",
    sortOrder: "desc",
    keyword: keyword,
    code: filterCodeStatus
  };

  const tableData = useTableProperties(data);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize, keyword]);

  useEffect(() => {
    dispatch(showLoading());
    const delayDebounceFn = setTimeout(() => {
      dispatch(getListGinsengAdmin(payload)).then((result) => {
        setData(Utils.getValues(result, "payload.responseData", []));
        dispatch(hideLoading());
      });
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [pageSize, keyword, currentPage, dispatch, updateData, filterCodeStatus]);

  const setStateHandle = (isEdit, dataGinseng = {}) => {
    setFilesImage([]);
    setIsEdit(isEdit);
    setGinsengData(dataGinseng);
    setOpenDialog(true);
  };

  const handleAddClick = (e) => {
    setStateHandle(true, {});
  };

  const handleEditClick = (e) => {
    setStateHandle(true, e);
    setImageRemove([]);
    e?.images && e.images.forEach((value) => {
      setFilesImage((prev) => [...prev, value]);
    });
  };

  const handleImage = (item) => {
    if (item instanceof File) {
      return URL.createObjectURL(item);
    } else {
      return item.file_path ? Config.Image_Url + '/' + item.file_path.substring(1) : '';
    }
  };

  const handleDeleteClick = async (e) => {
    const result = await Utils.handleConfirmDelete(intl);
    if (result.isConfirmed) {
      dispatch(showLoading());
      await dispatch(deleteGinsengAdmin(e.data.id)).then((result) => {
        const status = Utils.getValues(result, "payload.status", null);
        if (status === Config.SUCCESS) {
          setUpdateData(!updateData);
          Utils.handlePopup(intl.messages.deleteSuccess, "success");
        } else {
          Utils.handlePopup(intl.messages.deleteFail, "error");
        }
      });
      dispatch(hideLoading());
    }
  };

  const initialValues = {
    name: "",
    code: "",
    created_at: Utils.formatDateYDM(moment()),
    source: "",
    use_of_ginseng: "",
    other_information: "",
    // ginseng_certificates: intl.messages.chooseFile,
  };

  const handleValidation = (data, isEdit) => {
    if (!data.code.trim()) {
      Utils.handlePopup(intl.messages.requiredCode, "error");
      return true;
    }
    if (!data.name.trim()) {
      Utils.handlePopup(intl.messages.requiredName, "error");
      return true;
    }
    if (!data.created_at) {
      Utils.handlePopup(intl.messages.requiredDate, "error");
      return true;
    }
    if (!data.source.trim()) {
      Utils.handlePopup(intl.messages.requiredSource, "error");
      return true;
    }
    if (!data.use_of_ginseng.trim()) {
      Utils.handlePopup(intl.messages.requiredUseOf, "error");
      return true;
    }
    if (isEdit) {
      if (!filesCertificate) {
        Utils.handlePopup(intl.messages.requiredFile, "error");
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async (e, { resetForm }) => {
    if (handleValidation(e, isEdit)) return;

    dispatch(showLoading());

    let status = Config.FAIL;
    const formData = new FormData();
    const ginseng = {
      name: e.name,
      code: e.code,
      created_at: e.created_at,
      harvest_date: e.harvest_date,
      size: e.size,
      weight: e.weight,
      source: e.source,
      years_of_ginseng: e.years_of_ginseng,
      use_of_ginseng: e.use_of_ginseng,
      ginseng_type: e.ginseng_type,
      other_information: e.other_information,
      qr_code_id: e.qr_code_id,
      images: e.images,
    };

    const payloadAvt = {
      type: "ginseng",
    };

    const uploadPromises = [];

    const processFile = async (file) => {
      const formDataImage = new FormData();
      formDataImage.append("file", file);
      formDataImage.append("meta", JSON.stringify(payloadAvt));
      const result = await dispatch(uploadGinsengFile(formDataImage));
      const responseData = Utils.getValues(result, "payload.responseData", {});
      return { id: responseData.id, file_path: responseData.file_path };
    };
    if (isEdit === false) {
      for (const file of filesImage) {
        uploadPromises.push(Utils.processFile(dispatch, file));
      }
      try {
        const imageList = await Promise.all(uploadPromises);
        ginseng["images"] = imageList;
        formData.append("file", filesCertificate);
        formData.append("ginseng", JSON.stringify(ginseng));
        const result = await dispatch(insertGinsengAdmin(formData));
        status = Utils.getValues(result, "payload.status", Config.FAIL);
      } catch (error) { }
    } else if(isEdit === true) {
      const newFiles = filesImage.filter((item) => item instanceof File);
      const oldFiles = filesImage.filter(
        (item) => item instanceof Object && !(item instanceof File)
      );

      if (imageRemove.length > 0) {
        const deletePromises = imageRemove.map((value) =>
          dispatch(deleteGinsengFile(value.id))
        );
        await Promise.all(deletePromises);
      }
      for (const file of newFiles) {
        uploadPromises.push(processFile(file));
      }
      try {
        const newImageList = await Promise.all(uploadPromises);
        ginseng["images"] = [...oldFiles, ...newImageList];
        formData.append("file", filesCertificate);
        const updateGinseng = {
          id: e.id,
          body: ginseng,
        };
        const result = await dispatch(updateGinsengAdmin(updateGinseng));
        status = Utils.getValues(result, "payload.status", Config.FAIL);
      } catch (error) {
        Utils.handlePopup(
          isEdit ? intl.messages.addFail : intl.messages.updateFail,
          "error"
        );
      }
    } else {
      const qrCode = e.qr_code.value;

      try {
        const params = {
          code: qrCode,
        }
        const result = await dispatch(approveQR(params));
        status = Utils.getValues(result, "payload.status", Config.FAIL);
      } catch (error) {
        Utils.handlePopup(
          isEdit === true ? intl.messages.addFail : 
          isEdit === false ? intl.messages.updateFail :
          intl.messages.signFail,
          "error"
        );
      }
    }
    if (status === Config.SUCCESS) {
      setUpdateData(!updateData);
      Utils.handlePopup(
        isEdit === true ? intl.messages.addSuccess : 
        isEdit === false ? intl.messages.updateSuccess :
        intl.messages.signSuccess,
        "success"
      );
    } else {
      Utils.handlePopup(
        isEdit === true ? intl.messages.addFail : 
        isEdit === false ? intl.messages.updateFail :
        intl.messages.signFail,
        "error"
      );
    }
    dispatch(hideLoading());
    resetForm({ values: "" });
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOnchangeImage = (e) => {

    if (e.target.files[0]) {
      if (e.target.files[0].type.split('/')[0] === 'image') {
        setFilesImage((prev) => [...prev, e.target.files[0]]);
      } else {
        Utils.handlePopup(
          intl.messages.requiredImageType,
          'error',
      );
      }
      
    }
  };
  const handleRemoveImage = (e) => {
    setImageRemove((prev) => [...prev, e]);
    const newFiles = filesImage.filter((item) => item !== e);
    setFilesImage(newFiles);
  };

  const onChangeFileCertificate = (e) => {
    const text = document.getElementById("file-text");
    if (e.target.files.length > 0) {
      text.innerHTML = e.target.files[0].name;
      text.style.color = "#0288d1";
      setFilesCertificate(e.target.files[0]);
    } else {
      text.innerHTML = intl.messages.chooseFile;
      text.style.color = "#9ea4aa";
    }
  };

  const onClickTopBarStatus = (status) => {
    const active = document.getElementsByClassName("topbar-option active");
    const noneActive = document.getElementsByClassName("topbar-option");
    if (active.length > 0) active[0].className = "topbar-option";

    const arrBtn = [...noneActive];

    if (status === intl.messages.pending) {
      onSetTopBarActive(0, arrBtn);
      setFilterCodeStatus(Config.ginsengStatus.INAPPROVED)
    }
    else if (status === intl.messages.accepted) {
      onSetTopBarActive(1, arrBtn);
      setFilterCodeStatus(Config.ginsengStatus.APPROVED)
    }
    else if (status === intl.messages.sold) {
      onSetTopBarActive(2, arrBtn);
      setFilterCodeStatus(Config.ginsengStatus.SOLD)
    }
    else onSetTopBarActive(3, arrBtn);
  };
  const onSetTopBarActive = (index, arr) => {
    arr.map(
      (item, pos) =>
      (item.className =
        pos === index ? "topbar-option active" : "topbar-option")
    );
  };

  // sign modal
  const onHandleToggleSignalModal = (e) => {
    setStateHandle(undefined, e)
    setImageRemove([]);
    e?.images && e.images.forEach((value) => {
      setFilesImage((prev) => [...prev, value]);
    });
  };

  //Description modal
  const onHandleToggleDescriptionModal = (e) => {
    setStateHandle(false, e);
    setImageRemove([]);
    e?.images && e.images.forEach((value) => {
      setFilesImage((prev) => [...prev, value]);
    });
  };

  //handle search
  const handleSearch = (e) => {
    setKeyword(e);
  }

  const ginsengContainerProps = {
    ...props,
    intl,
    columns,
    keyword,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleEditClick,
    handleDeleteClick,
    openDialog,
    handleCloseDialog,
    isEdit,
    handleSubmit,
    initialValues,
    ginsengData,
    filesImage,
    handleAddClick,
    handleRemoveImage,
    handleOnchangeImage,
    onChangeFileCertificate,
    tableData,
    ginsengAdminList,
    handleImage,
    onClickTopBarStatus,
    onHandleToggleSignalModal,
    onHandleToggleDescriptionModal
  };
  return <GinsengMainView {...propsProvider(ginsengContainerProps)} />;
}

export default GinsengContainer;
