const labels = {
  vi: {
    ProductInfo: "Thông tin sản phẩm",
    code: 'Mã nhân sâm',
    YourProduct: "Sản phẩm của bạn",
    Protect: "Lớp bảo vệ",
    ProductNotMath: "Nếu sản phẩm của bạn không khớp như mô tả:",
    InCorrect: "Không đúng",
    MadeFrom: "Được làm từ",
    EnterCodeToProtect: "Nhập thêm mã bảo vệ để truy xuất thông tin khác",
    Instruct: "Hướng dẫn",
    AuthenticationSuccessful: "Xác thực thành công!",
    GenuineProduct: "Sản phẩm của bạn là sản phẩm chính hãng.",
    Discover: "Khám phá",
    YOUR_PRODUCT: "Sản phẩm của bạn là:",
    Wrong: "Sai",
    Correct: "Đúng",
    ProtectScreen: "Màn hình khóa",
    AnotherInfo: "Thông tin khác",
    EffectOfGinseng: "Tác dụng của Sâm Ngọc Linh",
    RelevantCertificate: "Giấy chứng nhận liên quan",
    Posts: "Bài viết",
    PurchaseAddress: "Địa chỉ mua hàng",
    Back: "Trở về",
    SendReport: "Gửi báo cáo",
    MoreInformation:
      "Vui lòng cho chúng tôi biết thêm thông tin để bảo vệ bạn tốt nhất có thể!",
    PlaceBuy: "Nơi mua",
    InputPlaceBuy: "Nhập nơi mua tại đây",
    AddressBuy: "Địa chỉ mua",
    InputAddressBuy: "Nhập nơi mua tại đây",
    Image: "Hình ảnh sâm (tối đa 5 ảnh)",
    YourEmail: "Email của bạn",
    InputYourEmail: "Nhập email của bạn",
    NoteIfHave: "Ghi chú (nếu có)",
    InputNoteIfHave: "Nhập ghi chú của bạn nếu có",
    HarvestDate: "Ngày thu hoạch",
    Age: "Năm tuổi",
    Weight: "Cân nặng",
    Origin: "Nguồn gốc",
    Owner: "Chủ sở hữu",
    Certificate: "Giấy chứng nhận",
    otpInCorrect: "Mã otp không đúng !!!",
  },
  en: {
    ProductInfo: "Product information",
    code: 'Ginseng code',
    YourProduct: "Your product ",
    Protect: "Protect",
    ProductNotMath: "If your product does not match the description",
    InCorrect: "InCorrect",
    MadeFrom: "Made from",
    EnterCodeToProtect:
      "Enter additional security code to retrieve other information",
    Instruct: "Instructions",
    AuthenticationSuccessful: "Authentication successful!",
    GenuineProduct: "Your product is a genuine product.",
    Discover: "Discover",
    YOUR_PRODUCT: "Your product is:",
    Wrong: "Wrong",
    Correct: "Correct",
    ProtectScreen: "Protect Screen",
    AnotherInfo: "Another info",
    EffectOfGinseng: "The effect of Ngoc Linh Ginseng",
    RelevantCertificate: "Relevant certificate",
    Posts: "Posts",
    PurchaseAddress: "Purchase address",
    Back: "Back",
    SendReport: "Send report",
    MoreInformation:
      "Please tell us more information to protect you as best we can!",
    PlaceBuy: "Place buy",
    InputPlaceBuy: "Input your place buy",
    AddressBuy: "Address buy",
    InputAddressBuy: "Input your address buy",
    Image: "Image ginseng (limited 5 pictures)",
    YourEmail: "Your email",
    InputYourEmail: "Input your email",
    NoteIfHave: "Note (if have)",
    InputNoteIfHave: "Input your note if you have",
    DateOfManufacture: "Date Of Manufacture",
    Origin: "Origin",
    Certificate: "Certificate",
    otpInCorrect: "Otp InCorrect",
  },
};

export default labels;
