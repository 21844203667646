import {
    AddWhiteIcon,
    ArticleGreenIcon,
    ArticleWhiteIcon,
    FilterIcon,
    LocationGreenIcon,
    LocationWhiteIcon,
    SearchIcon,
    VerifyGreenIcon,
    VerifyWhiteIcon,
} from 'assets/icon';
import DashboardItem from 'components/DashboardItem';

function AdminPageMainView(props) {
    const {
        intl,
        children,
        dataDropdown,
        location,
        userName = 'Admin',
        header = 'Header',
        subHeader = 'Sub-Header',
        onChangeSearch,
        handleAddClick,
        displayPrinter,
        topBar,
        showAddNewButton = true,
        handlePrintClick
    } = props;
    return (
        <div className='admin-header'>
            <div className='sidebar'>
                <div className='sidebar__header'>
                    <div className='sidebar__avt' />
                    <span className='sidebar__name'>{userName}</span>
                </div>
                <div className='sidebar__list'>
                    <DashboardItem
                        isActive={
                            location.pathname.split('/')[2] === 'ginseng'
                                ? true
                                : false ||
                                    location.pathname.split('/')[2] === 'product'
                                    ? true
                                    : false ||
                                        location.pathname.split('/')[2] === 'qrcode'
                                        ? true
                                        : false
                        }
                        dataDropDown={dataDropdown}
                        isDropdown
                        text={intl.messages.ListInfo}
                    />
                    <DashboardItem
                        isActive={
                            location.pathname.split('/')[2] === 'certificate'
                                ? true
                                : false
                        }
                        url={'/admin/certificate'}
                        text={intl.messages.Certificate}
                        icon={<VerifyWhiteIcon />}
                        iconActive={<VerifyGreenIcon />}
                    />
                    <DashboardItem
                        isActive={
                            location.pathname.split('/')[2] === 'article'
                                ? true
                                : false
                        }
                        url={'/admin/article'}
                        text={intl.messages.Article}
                        icon={<ArticleWhiteIcon />}
                        iconActive={<ArticleGreenIcon />}
                    />
                    <DashboardItem
                        isActive={
                            location.pathname.split('/')[2] === 'address'
                                ? true
                                : false
                        }
                        url={'/admin/address'}
                        text={intl.messages.Address}
                        icon={<LocationWhiteIcon />}
                        iconActive={<LocationGreenIcon />}
                    />
                </div>
            </div>

            <div className='header'>
                <span className='header__title'>{header}</span>
                {topBar}
                <div className='header__sub'>
                    <span className='header__subTitle'>{subHeader}</span>
                    <div className='header__right'>
                        <div className='header__search'>
                            <SearchIcon className='header__search__icon' />
                            <input
                                className='header__search__input'
                                placeholder={intl.messages.Search}
                                onChange={(e) => onChangeSearch(e.target.value)}
                            />
                        </div>
                        <button className='header-btn filter'>
                            <FilterIcon />
                        </button>
                        {displayPrinter && <button
                            className='header-btn print'
                            onClick={handlePrintClick}
                        >
                            <AddWhiteIcon />
                            {intl.messages.PrintQrCode}
                        </button>}
                        {showAddNewButton && <button
                            className='header-btn addNew'
                            onClick={handleAddClick}
                        >
                            <AddWhiteIcon />
                            {intl.messages.AddNew}
                        </button>}
                    </div>
                </div>
            </div>

            <div className='children'>{children}</div>
        </div>
    );
}

export default AdminPageMainView;
