import DialogModal from "components/DialogModal";
import { Field } from "formik";
import Utils from "utils";
function CertificateModal({
  openDialog,
  handleCloseDialog,
  intl,
  handleSubmit,
  initialValues,
  data,
  isAdd,
  onChangeAvatar,
  onChangeFileCer,
}) {

  return (
    <DialogModal
      openDialog={openDialog}
      handleCloseDialog={handleCloseDialog}
      handleSubmit={handleSubmit}
      initialValues={
        isAdd
          ? initialValues
          : {
            avatar: Utils.getLastSplit(data.avatar, '/'),
            created_at: Utils.formatDateYDM(data.created_at),
            description: data.description,
            file_path: Utils.getLastSplit(data.file_path, '/'),
            id: data.id,
            name: data.name,
          }
      }
      headerTitle={
        isAdd
          ? intl.messages.createCertificate
          : intl.messages.updateCertificate
      }
      submitName={isAdd ? intl.messages.add : intl.messages.update}
      cancelName={intl.messages.cancel}
    >
      <div className="sub-header">{intl.messages.generalInformation}</div>
      <div style={{ display: "flex" }}>
        <div className="input-container" style={{ marginRight: "16px" }}>
          <Field
            className="input-container__input"
            id="name"
            name="name"
            type="text"
            placeholder={intl.messages.enterCertificate}
          />
          <label htmlFor="name" className="input-container__title required">
            {intl.messages.certificate}
          </label>
        </div>
        <div className="input-container">
          <label className="link-label" style={{ cursor: 'pointer' }}>
            <Field name="file_path" htmlFor="file_path">
              {({ field }) => (
                <>
                  <span className="link-icon" />
                  <span id="file-text" className={`${isAdd ? "silver-text" : "blue-text"}`} >{field.value}</span>
                  <input className="input-container__input"
                    id="avatar"
                    type="file"
                    accept="application/pdf"
                    onChange={onChangeFileCer}
                    style={{ display: "none" }} />
                </>
              )}
            </Field>
          </label>
          <label htmlFor="file_path" className={`input-container__title ${isAdd && `required`}`}>
            {intl.messages.fileCertificate}
          </label>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="input-container" style={{ marginRight: "16px" }}>
          <label className="link-label">
            <Field name="created_at" htmlFor="avatar" >
              {({ field }) => (
                <>
                  <span className="date-icon" />
                  <span className="silver-text">{field.value}</span>
                  <input className="input-container__input"
                    id="created_at"
                    type="date"
                    style={{ display: "none" }} />
                </>
              )}
            </Field>
          </label>
          <label className="input-container__title">
            {intl.messages.dateCreated}
          </label>
        </div>
      </div>
      <div
        className="input-container"
        style={{ minHeight: "100px", justifyContent: "flex-end" }}
      >
        <Field
          className="input-container__textarea"
          id="description"
          name="description"
          type="text"
          placeholder={intl.messages.placeholderDesc}
        />
        <label
          className="input-container__title"
          style={{ marginBottom: "4px" }}
        >
          {intl.messages.desc}
        </label>
      </div>
    </DialogModal>
  );
}

export default CertificateModal;
