import { signInAdminSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Utils from 'utils';
import i18nLabels from './SignIn.i18n';
import SignInContainer from './SignInContainer';

export default function SignIn(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const signin = useSelector(signInAdminSelector);
    const { signInInfo = {} } = signin;
    const signinProps = {
        ...props,
        i18nLabels,
        history,
        signInInfo,
        dispatch,
    };
    const isValid = Utils.checkCurrentSignIn(history);
    if (!isValid) {
        return <DecorateContainer {...signinProps} childComponent={SignInContainer} />;
    }
}
