const labels = {
  vi: {
    productInfo: "Thông tin sản phẩm",
    notFound: "Không tìm được thông tin",
    tryAgain: "Vui lòng quét mã khác!",
    scanOther: "Quét mã khác",
  },
  en: {
    productInfo: "Product Information",
    notFound: "Not found information",
    tryAgain: "Try to scan other code!",
    scanOther: "Scan Other QR",
  },
};

export default labels;
