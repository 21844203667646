const labels = {
  vi: {
    Certificates: "Giấy chứng nhận liên quan",

  },
  en: {
    Certificates: "Certificates"
  },
};

export default labels;
