import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import propsProvider from "./AdminPagePropsProvider";
import AdminPageMainView from "./template/AdminPageMainView";
function AdminPageContainer(props) {
  const { dispatch, history } = props;
  const intl = useIntl();
  const location = useLocation();
  const dataDropdown = [
    {
      name: intl.messages.Ginseng,
      url: "/admin/ginseng",
      isActive: location.pathname.split("/")[2] === "ginseng" ? true : false,
      children: [],
    },
    {
      name: intl.messages.Product,
      url: "/admin/product",
      isActive: location.pathname.split("/")[2] === "product" ? true : false,
      children: [],
    },
    {
      name: intl.messages.QrCode,
      url: "/admin/qrcode",
      isActive: location.pathname.split("/")[2] === "qrcode" ? true : false,
      children: [
        {
          name: intl.messages.ActiveQrCode,
          url: "/admin/qrcode?active=true",
          isActive: location.search == "?active=true" ? true : false,
        },
        {
          name: intl.messages.InActiveQrCode,
          url: "/admin/qrcode?active=false",
          isActive: location.search == "?active=false" ? true : false,
        },
      ],
    },
  ];

  const adminPageContainerProps = {
    ...props,
    intl,
    dataDropdown,
    location,
  };
  return <AdminPageMainView {...propsProvider(adminPageContainerProps)} />;
}

export default AdminPageContainer;
