import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productArticle } from "api/productApi";
import Config from "configuration";

const initialArticleList = () => {
  if (sessionStorage.getItem(Config.storageKey.article)) {
    return {
      ...JSON.parse(sessionStorage.getItem(Config.storageKey.article)),
    };
  }
  return {
    articleList: [],
  };
};

export const getArticleOfProductByProductId = createAsyncThunk(
  "product-article/getArticleByProductId",
  async (payload) => {
    const res = await productArticle.getArticleOfProductByProductId(payload);
    return res;
  }
);

const article = createSlice({
  name: "article",
  initialState: initialArticleList(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticleOfProductByProductId.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getArticleOfProductByProductId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.articleList = action.payload.responseData;
        sessionStorage.setItem(
          Config.storageKey.article,
          JSON.stringify(state)
        );
      })
      .addCase(getArticleOfProductByProductId.rejected, (state, action) => {
        state.isLoading = false;
      });
  },  
});

const { reducer } = article;
export default reducer;
