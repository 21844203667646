const labels = {
    vi: {
        ResetPwTitle: 'Thiết lập lại mật khẩu',
        NewPasswordLabel: 'Mật khẩu mới',
        NewPasswordPlaceholder: 'Nhập mật khẩu mới tại đây',
        RePassword: 'Xác thực lại mật khẩu',
        RePasswordPlaceholder: 'Nhập lại mật khẩu mới tại đây',
        SubmitLabel: 'Hoàn thành',
        PwMinReq: 'Mật khẩu ít nhất 6 ký tự',
        PwNotBlank: 'Mật khẩu không được bỏ trống',
        NotSame: 'Hai mật khẩu đã nhập không giống nhau',
    },
    en: {
        ResetPwTitle: 'Reset password',
        NewPasswordLabel: 'New password',
        NewPasswordPlaceholder: 'Enter new password',
        RePassword: 'Re-enter new password',
        RePasswordPlaceholder: 'Re-enter new password here',
        SubmitLabel: 'Complete',
        PwMinReq: 'Password must be at least 6 characters',
        PwNotBlank: 'Password cannot be blank',
        NotSame: 'Two passwords entered are not same',
    },
};

export default labels;
