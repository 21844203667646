export default function SignInButton(props) {
    const { label, variable, disabled, onClick } = props;
    let classes = 'btn ';

    switch (variable) {
        case 'text':
            classes += 'btn-text';
            break;
        default:
            classes += 'btn-primary';
            break;
    }

    return (
        <button
            type='button'
            disabled={disabled}
            className={classes}
            onClick={onClick}
        >
            {label}
        </button>
    );
}
