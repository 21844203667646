import React, { useEffect } from "react";
import propsProvider from "./MoreInfoPropsProvider";
import MoreInfoMainView from "./template/MoreInfoMainView";
import { useIntl } from "react-intl";
import { getProductInfo } from "pages/Scan/ScanSlice";

export default function MoreInfoContainer(props) {

  const { dispatch, productScan, ginsengInfoByCode } = props;

  const handleButtonClick = async () => { };
  const intl = useIntl();

  const moreInfoContainerProps = {
    ...props,
    handleButtonClick,
    intl,
    ginsengInfoByCode
  };
  return <MoreInfoMainView {...propsProvider(moreInfoContainerProps)} />;
}
