
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductContainer from "./ProductContainer";
import i18nLabels from './Product.i18n'
import DecorateContainer from "components/DecorateContainer";
import { productSelector, ginsengAdminSelector } from "app/selectors";
import { useHistory } from "react-router-dom";

export default function Product(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const infoProduct = useSelector(productSelector)
    const infoGinseng = useSelector(ginsengAdminSelector)
   
    const { ginsengInfoByCode = {} } = infoGinseng
    const { productScan, productInfo } = infoProduct
    const productProps = {
        ...props,
        i18nLabels,
        productScan,
        productInfo,
        history,
        dispatch,
        ginsengInfoByCode
    };
    return <DecorateContainer {...productProps} childComponent={ProductContainer} />
}
